import React, { useRef } from "react";
import { NavigationButtonsBooks } from "./navigation-buttons";
import { Icon } from "@iconify/react";

/**
 * Componente de fila de la página de inicio.
 * @param {Object} props - Props del componente.
 * @param {React.Element} props.titleElement - Elemento JSX que representa el título de la fila.
 * @param {string} props.descriptionElement - String que representa la descripción de la fila.
 * @param {React.Element[]} props.children - Elementos JSX hijos de la fila.
 * @param {Object} props.containerProps - Props adicionales para el contenedor de la fila.
 * @returns {JSX.Element} Elemento JSX que representa la fila de la página de inicio.
 */
export const CollectionRow = ({ titleElement, descriptionElement, children, containerProps }) => {
    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                navigationButtons: {
                    prevButtonRef,
                    nextButtonRef,
                }
            });
        }
    })

    return (
        <div
            {...containerProps}
            className={`home-row w-full px-2 py-4 ${containerProps?.className && containerProps?.className}`}
        >
                <div className="d-flex flex-column flex-md-row flex-wrap justify-content-center w-100 justify-content-md-between px-4 my-3">
                    <div className="col-lg-6">
                        {titleElement}
                        {descriptionElement &&
                            <p className="secondary-font font-weight-bold onprimary--color text-18 mt-3">{descriptionElement}</p>
                        }
                    </div>

                    <NavigationButtonsBooks nextButtonRef={nextButtonRef} prevButtonRef={prevButtonRef} />
                </div>

                <div>
                    {childrenWithProps}
                </div>

        </div>
    )
}

/**
 * Componente de título de fila genérico de la página de inicio.
 * @param {Object} props - Props del componente.
 * @param {string} props.text - Texto del título.
 * @returns {JSX.Element} Elemento JSX que representa el título de fila genérico de la página de inicio.
 */
export const GenericCollectionRowTitle = ({ text }) => {
    return <h3 className="onprimary--color d-flex  align-items-md-center secondary-font text-26" style={{ fontWeight: 'bold' }}>
        <Icon icon="solar:star-bold" className="mr-2" width={30} />
        {text}
    </h3>

}