import React from "react";
import { Img } from "../../components/shared/img";

/**
 * Retorna el texto introductorio al home sin login
 * @param {object} texts contiene los textos de interfaz
 * @returns {JSX.Element} IntroHome
 */
export const IntroHome = ({texts}) => {
    return (
        <section className="container">
            <div className="row px-0 ">
                <div className="col-lg-5 secondary-font onbackground-primary--color font-weight-bold py-4 d-flex align-items-center">
                    <div>
                        <p className="text-24">
                            {/* <span className="secondary-inverse--color">{texts?.descripcion?.linea1 || '¡Libros de verdad!'}</span> {' '} */}
                            {texts?.descripcion?.linea || 'Lee los mejores libros para chicos y jóvenes en tu biblioteca pública y colegio.'}
                        </p>
                        <p className="mt-3">{texts?.parrafo2 || 'MakeMake te ofrece la mejor combinación: plataforma + libros + acompañamiento lector '}</p>
                    </div>

                </div>
                <div className="col-lg-7">
                    <div className="img-home-default">
                        <Img src={"/img/home/blop-matilda.png"} className="w-100" />
                    </div>
                </div>
            </div>
        </section>
    )
}