export const DATA_AWARDS = [
  {
    alt: 'Sena innova 2023',
    img: '/img/innova.png',
    year: {
      default:'Apoyo para la productividad 2023 – 2021',
      translationPath:'reconocimiento.sena'
    },
  },
  {
    alt: 'Lapíz de acero',
    img: '/img/lapiz-acero2.png',
    year: {
      default:'Mejor Página Web 2024',
      translationPath:'reconocimiento.lapiz'
    },
    link: 'https://www.lapizdeacero.org/winners/biblioteca-digital-makemake/'
  },
  {
    alt: 'Bologna ragazzi crossmedia award 2024',
    img: '/img/bologna.png',
    year: {
      default:'Best CrossMedia Reading Experience 2024',
      translationPath:'reconocimiento.bologna'
    },
    link: 'https://www.bolognachildrensbookfair.com/en/awards/bolognaragazzi-crossmedia-award/bolognarag[…]4-winners/digital-reading-experience-2024/13263.html'
  },
  {
    alt: 'Best of 2024 Primary Education Category',
    img: '/img/edtech.png',
    year: {
      default:'Best of 2024 Primary Education Category',
      translationPath:'reconocimiento.edtech'
    },
  },
]