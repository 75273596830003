import React, { useContext, useRef, useEffect } from "react";
import { Icon } from "@iconify/react";

import { useLanguageVisibility } from "./hooks/use-language-visibility";
import { store } from "../Store";
import { AccesibilidadContext, TemasDisponibles } from "../../contexts/AccesibilidadContext";
import { DropdownLang } from "./dropdown-lang";

/**
 * Rederiza el boton de lenguaje en el Header
 * @returns {React.Component}
 */
export const LangButton = () => {
    const languageVisibility = useLanguageVisibility();
    const globalState = useContext(store);
    const isAuth = globalState?.state?.sesionActiva === "SI";
    const accesibility = useContext(AccesibilidadContext);
    const isClassicTheme = accesibility.temaActual === TemasDisponibles.clasico.id;
    const isIndividualUser = globalState?.state?.cantidadLibros <= 40 && globalState?.state?.cantidadLibros >= 0;
    const color = isAuth & !isIndividualUser
        ? isClassicTheme
            ? globalState?.state.datosHeader?.colorLetra !== null
                ? globalState?.state.datosHeader?.colorLetra
                : "var(--onsecondary-color)"
            : "var(--primary-inverse)"
        : "var(--onsecondary-color)";

    const langRef = useRef(null);

    const handleVisibility = () => {
        if (languageVisibility.isVisible) {
            languageVisibility.hide();
        }
        else {
            languageVisibility.show();
        }
    }

    /**Función que permite cerrar el dropdown dando click fuera de él*/
      useEffect(() => {
        let handler = (e) => {
          if ( !langRef.current.contains(e.target)) {
            languageVisibility.hide();
          }
        }
        document.addEventListener("mousedown", handler);
    
        return () => {
          document.removeEventListener("mousedown", handler)
        }
      }, [])

    return (
        <div ref={langRef}>
            <button
                className={`${!isAuth && languageVisibility.isVisible ? "position-relative activeMode mx-1" : "position-relative mx-1"} focusable-red`}
                onClick={() => { handleVisibility(); }}
                style={{ color: color }}


            >
                <Icon
                    icon="clarity:language-solid"
                    width={28}
                />
            </button>

            <DropdownLang/>
        </div>

    )
}