import React from "react";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { Helmet } from "react-helmet";
import { Appointment } from "../../components/appointment";

/**
 * Componente que devuelve el agendar una cita  para la pagina de contacto
 * @returns {JSX.Element} ContactCita
 */
export const ContactCita = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
    const textos = useTranslations({ section: "tienda_makemake", lang: lang });

    return (
        <>
            <Helmet>
                <title>{textos?.metadata?.cita?.title || 'MakeMake - Agenda una cita'}</title>
                <meta name="description" content={textos?.metadata?.cita?.description || 'Agenda una cita para conocer planes de suscripcion de la Biblioteca MakeMake'} />
            </Helmet>

            <Appointment />
        </>
    )
}