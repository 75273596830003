import React, { useEffect, useContext } from "react"
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { BannerSection } from "../components/banner";
import { GenericHomeRowTitle, HomeRow } from "../components/row-section";
import { BooksSection } from "../components/books-section";
import { CollectionSection } from "../components/collection";
import { AuthorSection } from "../components/authors";
import SkeletonHome from '../../skeletons/SkeletonHome';
import { store } from "../../components/Store";
import SkeletonHomeGeneral from "../../skeletons/SkeletonHome/SkeletonHomeGeneral";

/**
 * Componente que muestra la página de inicio del usuario que no es individual y está autenticado.
 * @param {Object} props - Props del componente.
 * @param {Object} props.data - Datos de la página de inicio del usuario.
 * @returns {JSX.Element} Elemento JSX que representa la página de inicio del usuario.
 */
export const UserHome = ({ data }) => {
    const { textosSubSecciones: textosHome } = useObtenerSubsecciones('home');
    const textos = textosHome?.login || {};
    const homeData = data?.data;

    const globalState = useContext(store);
    const loading = globalState?.state?.userBooksData?.loading && globalState?.state?.userBooksData?.loadCompleted === false && globalState?.state?.cantidadLibros === -1;


    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return (<section>
        {
            loading && data?.fetching ?
                <SkeletonHomeGeneral />
                :

                <LazyLoadComponent threshold={.5} >
                    <BannerSection banners={homeData?.banners} />
                    <HomeRow
                        containerProps={{
                            className: '',
                            link: {
                                url:"/curadurias/series",
                                txtButton: textos?.ver || 'Ver todos'
                            }
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.autores || 'Autores y series'} />
                        }>
                        <AuthorSection collections={homeData?.autores} autoPlayDelay={5000} />

                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: 'home-row-purple'
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.libros_recomendados || 'Nuestros libros recomendados'} />
                        }>
                        <BooksSection books={homeData?.librosRecomendados} />
                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: '',
                            link: {
                                url:"/curadurias/colecciones",
                                txtButton: textos?.ver || 'Ver todos'
                            }
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.nuestra_coleccion || 'Nuestras colecciones'} />
                        }>
                        <CollectionSection collections={homeData?.colecciones} autoPlayDelay={5000} />
                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: 'home-row-yellow'
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.libros_ilustrados || 'Libros ilustrados'} />
                        }>
                        <BooksSection books={homeData?.LibrosIlustrados} autoPlayDelay={5500} />
                    </HomeRow>
                    <HomeRow
                        containerProps={{
                            className: 'home-row-purple'
                        }}
                        titleElement={
                            <GenericHomeRowTitle text={textos?.lecturas_interactivas || 'Lecturas interactivas'} />
                        }>
                        <BooksSection books={homeData?.LibrosInteractivos} autoPlayDelay={5000} />
                    </HomeRow>
                </LazyLoadComponent>
        }

    </section>)
}