import React, { useContext, useEffect, useState } from "react";
import { store } from "./Store";
import { SesionAPI } from "../services/api/Sesion";
import { useDevice } from "../hooks/use-device";

/**
 * Elemento que aparece cuando el usuario está logueado y permite al usuario escoger si quiere o no abrir la aplicación con la sesión actual,  genera un hash de acceso para abrir sesión en la aplicación
 * @returns {JSX.Element} - retorna el popup
 */
export const MobileAppPopup = () => {
    const device = useDevice();
    const isMobile = device.isMobile();
	const [visible, setVisible] = useState(true);
	const globalStateContext = useContext(store);
	const state = globalStateContext?.state;
	const isAuth = state?.sesionActiva !== null && state?.sesionActiva === "SI";
	const navLang = globalStateContext?.state?.idioma;
	const [authHash, setAuthHash] = useState(null);
	const link = `https://makemake.app/open-app/auth?hash=${authHash}`;
	const [storageShouldShow, setStorageShouldShow] = useState(null);

	const texts = {
		title:{
			es:'Disfruta de tus libros favoritos en MakeMake App',
			en:'Enjoy your favorite books in MakeMake App'
		},
		buttons:{
			open_app:{
				es:'Abrir App',
				en:'Open App'
			},
			not_now:{
				es:'Ahora no',
				en:'Not now'
			},
			dont_show_again:{
				es:'No volver a mostrar',
				en:"Don't show again"
			},
		}
	}

    const hide = ()=>{
        setVisible(false);
    }

		const dontShowAgain = (show) => {
			localStorage.setItem("showMobileAppPopup", !show);
		}

	const generateAuthHash = async () => {
        if(isAuth !== true) return;
		await SesionAPI.generateAuthHash({ abortSignal: null })
			.then((result) => {
				if (result) {
					setAuthHash(result);
				}
			})
			.catch((error) => console.log(error));
	};

	useEffect(()=>{
		const show = localStorage.getItem("showMobileAppPopup");
		setStorageShouldShow(show);
	},[])

	useEffect(() => {
		generateAuthHash();
	}, [isAuth]);

	return (
		<>
			{isAuth && visible && authHash && isMobile  &&(storageShouldShow === null || storageShouldShow === true  || storageShouldShow === "true" )  ? (
				<div className="mobile-app-popup p-2 col-12 col-lg-6 col-xl-4">
					<div
						className="d-flex flex-column justify-content-center align-items-center w-100"
						style={{ position: "relative" }}
					>
						<img
							className="img-fluid app-logo"
							src="/img/logos/logo512.png"
							alt="app logo"
						/>
						<p
							className="col-10 col-md-7 col-lg font-weight-bold text-center mb-2 secondary-font"
							style={{ marginTop: "55px" }}
						>
							{texts?.title?.[navLang || 'es']}
						</p>
						<div className="row col-9 col-md-6 col-lg-9">
							<a
								className="go-app p-2 d-flex text-center align-items-center justify-content-center my-2 col-12 col-md-6"
                                href={link}
                                onClick={()=>hide()}
							>
								{texts?.buttons?.open_app?.[navLang || 'es']}
							</a>
							<button
								className="not-now mb-2 col-12 col-md-6"
								onClick={() => hide()}
							>
								{texts?.buttons?.not_now?.[navLang || 'es']}
							</button>
						</div>
							<label className="d-flex justify-content-center align-items-center" style={{cursor:'pointer',userSelect:'none', msUserSelect:'none', webkitUserSelect:'none'}}>
								<span className="font-weight-bold">{texts?.buttons?.dont_show_again?.[navLang || 'es'] || 'No volver a mostrar'}</span>
								<input type="checkbox" className="modal-checkbox mx-1" onChange={(e)=> dontShowAgain(e.target.checked)}/>
								<i className="check"></i>
							</label>
					</div>
				</div>
			) : null}
		</>
	);
};
