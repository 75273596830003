import React from "react";
import { SPONSORS } from "../data/sponsors";
import { AWARDS } from "../data/awards";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente de premios y reconocimientos.
 * Se utiliza en el pie de página.
 * @returns {JSX.Element} Componente Awards.
 */
export const Awards = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "footer", lang: lang });

	const awards = [...AWARDS];
	return (
	
			<div className="d-flex flex-wrap justify-content-center align-items-center">
				{awards?.map((award, i) => {
					return (
						<a
							key={i}
							className="m-2 d-flex justify-content-center align-items-center"
							aria-label="sponsor"
							href={award.url}
							rel="noopener noreferrer"
							target="_blank"
						>
							<img
								key={i}
								className="img-fluid award-img"
								src={award?.imgUrl}
								alt={`sponsor-${i}`}
							/>
						</a>
					);
				})}
			</div>
	
	);
};
