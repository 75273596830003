import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Icon } from "@iconify/react";

import { AccesibilidadContext } from "../contexts/AccesibilidadContext";

import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import { useQueryParams } from "../hooks/useQueryParams";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";

/**
 * Componente que devuelve el contenido de agendar una cita
 * @returns {JSX.Element} Appointment
 */
export const Appointment = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
    const textos = useTranslations({ section: "cita", lang: lang });

    const accebilidadState = useContext(AccesibilidadContext);
    const history = useHistory();
    const params = useQueryParams();

    const [cita, setCita] = useState("");
    const [pasoActivo, setPasoActivo] = useState("idioma_cita");

    const [calendlyRef, setCalendlyRef] = useState(null);
    const [loading, setLoading] = useState(true);

    const [alturaCalendario, setAlturaCalendario] = useState("1000px");
    const [backgroundCalendar, setBackgroundCalendar] = useState("#FFFFFF");
    const [primaryColor, setPrimaryColor] = useState("#82559E");
    const [textColor, setTextColor] = useState("#4d5055");

    useEffect(() => {
        const citaIdioma = params.get("idioma");
        if (!!citaIdioma) {
            setCita(citaIdioma);
        } else {
            setCita("");
            setPasoActivo("idioma_cita");
        }
    }, [params]);

    useCalendlyEventListener({
        onEventTypeViewed: () => {
            setPasoActivo("escoge_cita");
            setAlturaCalendario("700px");
        },
        onDateAndTimeSelected: () => {
            setAlturaCalendario("1500px");
            setPasoActivo("informacion");
        },

        onEventScheduled: (e) => {
            setAlturaCalendario("700px");
            setPasoActivo("confirmacion");
        },

        onProfilePageViewed: () => console.log("onProfilePageViewed----------------------"),
    });

    useEffect(() => {
        const isLoadingCalendly = calendlyRef?.state?.isLoading;
        setLoading(isLoadingCalendly);
    }, [calendlyRef?.state?.isLoading]);

    useEffect(() => {
        if (accebilidadState?.temaActual !== "clasico") {
            setBackgroundCalendar("#000000");
            setPrimaryColor("#ffff00");
            setTextColor("#ffff00");
        } else {
            setBackgroundCalendar("#FFFFFF");
            setPrimaryColor("#82559E");
            setTextColor("#4d5055");
        }
    }, [accebilidadState]);
    return (
        <div className="subsection cita px-xl-5">

            {cita === "" &&
                <div className="row align-items-stretch">
                    <div className="col-md-6 mb-3 mb-md-0">

                        <button
                            className={`d-flex justify-content-center align-items-center btn-cita `}
                            onClick={() => {
                                setPasoActivo("escoge_cita");
                                history.push(`/contacto/cita?idioma=es`);
                            }}
                        >
                            <div className="icon-cita i-cita-es"> </div>
                            {textos?.cita?.btn_espanol || "Cita con Andrea en español"}
                        </button>
                    </div>
                    <div className="col-md-6">

                        <button
                            className={`d-flex justify-content-center align-items-center btn-cita `}
                            onClick={() => {
                                setPasoActivo("escoge_cita");
                                history.push(`/contacto/cita?idioma=en`);
                            }}
                        >
                            <div className="icon-cita i-cita-en"> </div>
                            {textos?.cita?.btn_ingles || "Cita con Catalina en inglés"}
                        </button>
                    </div>
                </div>
            }



            {(cita === "es" || cita === "en") && (
                <button
                    className={`d-flex justify-content-center align-items-center secondary-font onsecondary--color text-bold mb-4`}
                    onClick={() => {
                        setPasoActivo("idioma_cita");
                        history.push(`/contacto/cita`);
                    }}
                >
                    <Icon icon="solar:alt-arrow-left-outline" width="24" height="24" />
                    {textos?.cita?.idioma_cita || "Elige un agente y el idioma"}
                </button>
            )}

            {cita === "es" && (

                <div className="col-lg-10 mx-auto content-cita">

                    <div className="title-cita d-flex justify-content-center align-items-center">
                        <div className="icon-cita i-cita-es"> </div>
                        {textos?.cita?.btn_espanol || "Cita con Andrea en español"}
                    </div>

                    <p className="d-none d-md-block secondary-font secondary-inverse--color text-center"> Elige el día y la hora que prefieras para tu cita con Andrea</p>

                    {loading && (
                        <div
                            style={{ minHeight: '300px' }}
                            className=" d-flex  justify-content-center align-items-center flex-column">
                            <p className="mb-3 secondary-inverse--color">{textos?.cita?.cargando || 'Cargando agenda disponible'}</p>
                            <Icon
                                className={"spinner-rotation secondary-inverse--color"}
                                icon="icomoon-free:spinner9"
                                width={60}
                            />

                        </div>
                    )}
                    <div className="col-lg-10 mx-auto">
                        <InlineWidget
                            url="https://calendly.com/comunicacion-62/30min"
                            styles={{
                                height: loading ? 0 : alturaCalendario,
                                width: '100%'
                            }}
                            ref={(el) => setCalendlyRef(el)}
                            pageSettings={{
                                backgroundColor: backgroundCalendar,
                                hideEventTypeDetails: true,
                                hideLandingPageDetails: true,
                                primaryColor: primaryColor,
                                textColor: textColor,
                            }}
                        />
                    </div>
                </div>
            )}

            {cita === "en" && (

                <div className="col-lg-10 mx-auto content-cita">
                    <div className="title-cita d-flex justify-content-center align-items-center">
                        <div className="icon-cita i-cita-en"> </div>
                        {textos?.cita?.btn_ingles || "Cita con Catalina en inglés"}
                    </div>
                    <p className="d-none d-md-block secondary-font secondary-inverse--color text-center ">Choose the date and time for your appointment with Catalina</p>
                    {loading && (
                        <div
                            style={{ minHeight: '300px' }}
                            className=" d-flex  justify-content-center align-items-center flex-column">
                            <p className="mb-3 secondary-inverse--color">{textos?.cita?.cargando || 'Cargando agenda disponible'}</p>
                            <Icon
                                className={"spinner-rotation secondary-inverse--color"}
                                icon="icomoon-free:spinner9"
                                width={60}
                            />

                        </div>
                    )}

                    <InlineWidget
                        ref={(el) => setCalendlyRef(el)}
                        url="https://calendly.com/libros_makemake/30min"
                        styles={{
                            height: loading ? 0 : alturaCalendario,
                        }}
                        pageSettings={{
                            backgroundColor: backgroundCalendar,
                            hideEventTypeDetails: true,
                            hideLandingPageDetails: true,
                            primaryColor: primaryColor,
                            textColor: textColor,
                        }}
                    />
                </div>
            )}



        </div >
    )
}