import React, { useEffect, useRef, useState } from "react";
import { usePopper } from 'react-popper';

export const BasicTooltip = ({ targetRef, textContent }) => {
    const tooltipRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const { styles, attributes, update } = usePopper(targetRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    });

    useEffect(() => {
        if (!targetRef.current) return;

        const showTooltip = () => {
            setIsVisible(true);
            update(); 
        };

        const hideTooltip = () => setIsVisible(false);

        // Agregar eventos de ratón
        targetRef.current.addEventListener('mouseenter', showTooltip);
        targetRef.current.addEventListener('mouseleave', hideTooltip);

        // Agregar eventos de teclado para accesibilidad
        targetRef.current.addEventListener('focus', showTooltip);
        targetRef.current.addEventListener('blur', hideTooltip); 

        return () => {
            if(targetRef.current){
                targetRef.current.removeEventListener('mouseenter', showTooltip);
                targetRef.current.removeEventListener('mouseleave', hideTooltip);
                targetRef.current.removeEventListener('focus', showTooltip);
                targetRef.current.removeEventListener('blur', hideTooltip);
            }
          
        };
    }, [targetRef, update]);

    return (
        <div
            ref={tooltipRef}
            className={`basic-tooltip ${isVisible ? 'visible' : ''}`}
            style={styles.popper}
            {...attributes.popper}
            role="tooltip" 
            id="tooltip" 
            aria-hidden={!isVisible} 
        >
            <div className="tooltip-inner">{textContent}</div>
        </div>
    );
};