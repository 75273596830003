import { useState } from "react";
import { useEffect } from "react";
import normalize from "../../js/utilities/normalizeString";
import { useInstitutionData } from "./use-institution-data";
import Fuse from "fuse.js";

/**
 * Hook que devuelve un array con las coincidencias de búsqueda en el listado de instituciones.
 * La búsqueda se realiza en el nombre del instituto, dirección, país, nombre de la sede, ciudad y dirección de la sede.
 * La búsqueda es flexible, con un umbral de similitud del 0.4 (0 es exacta, 1 es muy amplia).
 * La lista de resultados se ordena por nombre de instituto y luego por nombre de la sede (A-Z).
 * @param {{text:string}} props - objeto con el parámetro 'text' que se quiere buscar
 * @returns {{matches:Array}} - objeto con el parámetro 'matches' que contiene el listado de coincidencias
 */
export const useInstitutionSearch = ({ text }) => {
	const [matches, setMatches] = useState([{}, {}, {}]);
	const institutionsData = useInstitutionData();
	const institutions = institutionsData?.data;
	text = typeof text === "string" ? text.trim() : "";

	const fuse = new Fuse(
		institutions.filter((i) => i?.sedes?.length > 0),
		{
			keys: [
				{ name: "nombre", weight: 1.5 }, // Aumentar la prioridad de coincidencia en el nombre de la institución
				{ name: "direccion", weight: 0.2 },
				{ name: "pais", weight: 0.3 },
				{ name: "sedes.nombreSede", weight: 0.7 }, // Se mantiene importante, pero menor que "nombre"
				{ name: "sedes.ciudad", weight: 0.5 },
				{ name: "sedes.direccion", weight: 0.3 },
			],
			threshold: 0.3,
			distance: 10,
			ignoreLocation: true,
			includeMatches: true,
			shouldSort: true,
			ignoreDiacritics: true,
			includeScores: true,
			minMatchCharLength: 1,
		}
	);

	/**
	 * Organiza los resultados primero por instituto y por cada instituto organiza las sedes (A-Z).
	 * @param {Array} _matches
	 * @returns {Array} arreglo ordenado.
	 */
	const getSortedMatches = (_matches) => {
		_matches = Array.isArray(_matches) ? _matches : [];
		const sorted = _matches.sort((a, b) => {
			const nameA = a?.nombre?.toUpperCase();
			const nameB = b.nombre?.toUpperCase();

			// Manejo de objetos con nombres vacíos
			if (nameA === "" && nameB !== "") {
				return 1; // Mover objetos vacíos hacia el final
			}
			if (nameA !== "" && nameB === "") {
				return -1; // Mover objetos vacíos hacia el final
			}

			// Comparación por nombre de instituto
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			return 0;
		});
		return sorted;
	};


	const loadMatches = () => {
		const _text = normalize(text);
		const fuseMatches = fuse.search(_text);
		// console.log(fuseMatches);
		const filteredMatches = fuseMatches.map(({ item, matches }) => {
			// Verificar si la coincidencia es en la institución o en alguna sede
			const institutionMatch = matches.some((m) => m.key === "nombre" || m.key === "direccion" || m.key === "pais"); 
			const matchingSedes = item.sedes.filter((sede) =>
				matches.some(
					(m) =>
						(m.key === "sedes.nombreSede" && m.value === sede.nombreSede) ||
						(m.key === "sedes.ciudad" && m.value === sede.ciudad) ||
						(m.key === "sedes.direccion" && m.value === sede.direccion)
				)
			);
			return {
				...item,
				sedes:
					institutionMatch ? item.sedes : matchingSedes, // Si el instituto coincide, mostramos todas las sedes
			};
		});

		const sorted = _text?.length > 0 ? filteredMatches : getSortedMatches(institutions);
		setMatches(sorted);
	};

	useEffect(() => {
		loadMatches();
	}, [text, institutions]);

	return {
		matches,
	};
};
