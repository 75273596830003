import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard } from "swiper";
import { Img } from "../../../components/shared/img";

/**
 * Componente swiper de fotos en experiencia
 * @returns {JSX.Element} Componente SwiperPhotos
 */
export const SwiperPhotos = () => {
    const crearSlidePhotos = () => {
        let items = [];
        const photos = ["biblio1.jpeg", "biblio2.jpg", "biblio_3.jpeg"]

        photos.map((item, i) => {
            console.log(item)
            items.push(
                <SwiperSlide
                    className={`swiper-slide focusable-secondary comments`}
                    key={`slide-photos${i}`}
                    modules={[Autoplay, Keyboard]}
                    style={{ fontSize: "0.9em" }}
                >
                    <div
                        tabIndex={0}
                        style={{ width: '100%' }}
                        className={
                            "focusable-secondary "
                        }
                    >
                        <Img src={`/img/bibliotecas-que-es/experiencia/${item}`} className="img-fluid" style={{ borderRadius: 20 }} />
                    </div>
                </SwiperSlide>
            )
        })

        return items;
    };


    return (
        <Swiper
            breakpoints={{
                526: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                992: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                1400: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
            }}
            className="py-lg-3 mb-4 swiper-fotos"
            loop={false}
            modules={[Autoplay, Keyboard]}
            pagination={{
                clickable: true,
                type: "bullets",
            }}
            preloadImages={true}
            slidesPerView={1}
        >
            {crearSlidePhotos()}
        </Swiper>
    )
}