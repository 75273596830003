import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Icon } from "@iconify/react";

import { Img } from "../../../../components/shared/img";
import { AccesibilidadContext } from "../../../../contexts/AccesibilidadContext";

import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../../hooks/use-translations";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { Helmet } from "react-helmet";

/**
 * Componente que devuelve el contenido de agendar una cita, de la tienda para instituciones
 * @returns {JSX.Element} Cita
 */
export const Cita = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
    const textos = useTranslations({ section: "tienda_makemake", lang: lang });

    const accebilidadState = useContext(AccesibilidadContext);

    const history = useHistory();
    const params = useQueryParams();

    const [cita, setCita] = useState("");
    const [pasoActivo, setPasoActivo] = useState("idioma_cita");

    const [calendlyRef, setCalendlyRef] = useState(null);
    const [loading, setLoading] = useState(true);

    const [alturaCalendario, setAlturaCalendario] = useState("1000px");
    const [backgroundCalendar, setBackgroundCalendar] = useState("#FFFFFF");
    const [primaryColor, setPrimaryColor] = useState("#82559E");
    const [textColor, setTextColor] = useState("#4d5055");
    
    useEffect(() => {
        const citaIdioma = params.get("idioma");
        if (!!citaIdioma) {
            setCita(citaIdioma);
        } else {
            setCita("");
            setPasoActivo("idioma_cita");
        }
    }, [params]);

    useCalendlyEventListener({
        onEventTypeViewed: () => {
            setPasoActivo("escoge_cita");
            setAlturaCalendario("900px");
        },
        onDateAndTimeSelected: () => {
            setAlturaCalendario("1500px");
            setPasoActivo("informacion");
        },

        onEventScheduled: (e) => {
            /* console.log(e.data.payload) */
            setAlturaCalendario("700px");
            setPasoActivo("confirmacion");
        },

        onProfilePageViewed: () => console.log("onProfilePageViewed----------------------"),
    });


    useEffect(() => {
        const isLoadingCalendly = calendlyRef?.state?.isLoading;
        setLoading(isLoadingCalendly);
    }, [calendlyRef?.state?.isLoading]);

    useEffect(() => {
        if (accebilidadState?.temaActual !== "clasico") {
            setBackgroundCalendar("#000000");
            setPrimaryColor("#ffff00");
            setTextColor("#ffff00");
        } else {
            setBackgroundCalendar("#FFFFFF");
            setPrimaryColor("#82559E");
            setTextColor("#4d5055");
        }
    }, [accebilidadState]);

    return (
        <>
            <Helmet>
                <title>{textos?.metadata?.cita?.title || 'MakeMake - Agenda una cita'}</title>
                <meta name="description" content={textos?.metadata?.cita?.description || 'Agenda una cita para conocer planes de suscripcion de la Biblioteca MakeMake'}/>
            </Helmet>

            <div className="subsection instituciones-cita">
                <div className={`d-flex pt-4 pb-2 px-3 ${cita !== "" ? 'justify-content-between' : 'justify-content-end'}`}>
                    {cita !== "" &&
                        <button
                            className="d-flex align-items-center onsecondary--color"
                            onClick={() => {
                                history.push(`/contacto/cita`);
                                setLoading(false);
                            }}
                        >
                            <Icon icon="gravity-ui:arrow-left" />
                            <p className="ml-3 ">{textos?.institucional?.pasos?.idioma_cita || 'Elige un agente y el idioma'}</p>
                        </button>
                    }
                </div>

                {loading && pasoActivo !== "idioma_cita" && (
                    <div
                        style={{ minHeight: '450px' }}
                        className=" d-flex justify-content-center align-items-center">
                        <Icon
                            className={"spinner-rotation onsecondary--color"}
                            icon="icomoon-free:spinner9"
                            width={60}
                        />
                    </div>
                )}

                {cita === "" && (
                    <div className="col-lg-10 col-xl-9 mt-5 mx-auto">
                        {/* <p className="text-center onsecondary--color font-weight-normal">
                            {textos?.institucional?.descripcion || "Puedes agendar una cita con una de nuestras agentes para conocer más sobre MakeMake."}
                        </p>
 */}
                        <div className="col-10 mx-auto mt-5">
                            <button
                                className="btn-cita"
                                onClick={() => {
                                    setPasoActivo("escoge_cita");
                                    history.push(`/contacto/cita?idioma=es`);
                                }}
                            >
                                {textos?.institucional?.btn_espanol || "Cita con Andrea en español"}
                            </button>

                            <button
                                className="btn-cita"
                                onClick={() => {
                                    setPasoActivo("escoge_cita");
                                    history.push(`/contacto/cita?idioma=en`);
                                }}
                            >
                                {textos?.institucional?.btn_ingles || "Cita con Catalina en inglés"}
                            </button>
                        </div>
                    </div>
                )}

                {cita === "es" && (
                    <InlineWidget
                        url="https://calendly.com/comunicacion-62/30min"
                        styles={{
                            height: loading ? 0 : alturaCalendario,
                        }}
                        ref={(el) => setCalendlyRef(el)}
                        pageSettings={{
                            backgroundColor: backgroundCalendar,
                            hideEventTypeDetails: true,
                            hideLandingPageDetails: true,
                            primaryColor: primaryColor,
                            textColor: textColor,
                        }}
                    />
                )}


                {cita === "en" && (
                    <InlineWidget
                        ref={(el) => setCalendlyRef(el)}
                        url="https://calendly.com/libros_makemake/30min"
                        styles={{
                            height: loading ? 0 : alturaCalendario,
                        }}
                        pageSettings={{
                            backgroundColor: backgroundCalendar,
                            hideEventTypeDetails: true,
                            hideLandingPageDetails: true,
                            primaryColor: primaryColor,
                            textColor: textColor,
                        }}
                    />
                )}
            </div>
        </>
    )
}