import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Img } from "../../../components/shared/img";
import { ALL_LIBRARYS_IMAGES } from "../../../shared/data/libraries-data";

/**
 * Componente swiper de librerias
 * @returns {JSX.Element} Componente SwiperLibries
 */
export const SwiperLibries = () => {
    /**
         * Esta función crea un SwiperSlide con un conjunto de Bibliotecas
         * @returns {Array<HTMLElement>} arreglo de un conjunto de Bibliotecas
         */
    const librariesSlides = () => {
        const institutionSections = [];
        const sectionLength = Math.ceil(ALL_LIBRARYS_IMAGES.length / 12);
        for (let i = 0; i < sectionLength; i++) {
            const items = ALL_LIBRARYS_IMAGES.slice(i * 12, (i + 1) * 12);
            institutionSections.push(items);
        }
        return institutionSections.map((section, i) => {
            return (
                <SwiperSlide key={`slide-${i}`}>
                    <div className="row mx-0 mb-4">
                        {section.map((institution, j) => {
                            return (
                                <div
                                    className="col-4 col-lg-2 p-1 p-lg-2 focusable-by-children-secondary"
                                    key={`slide-${j}`}
                                >
                                    <button aria-label={`colegio ${institution.src}`}>
                                        <div className="cover">
                                            <Img
                                                src={institution?.src}
                                                alt={`institution-${j}`}
                                                className="img-fluid"
                                            />
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </SwiperSlide>
            );
        });
    };

    return (
        <Swiper
            className="pt-lg-3  pb-lg-4 swiper-editoriales"
            autoplay={{
                delay: 5000,
            }}
            breakpoints={{
                526: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                992: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                1400: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
            }}
            loop={true}
            pagination={{
                clickable: true,
                type: "bullets",
            }}
            preloadImages={true}
            slidesPerView={1}
        >
            {librariesSlides()}
        </Swiper>
    )
}