import React, { useContext, useEffect, useState } from "react";
import { FocusScope } from "react-aria";
import { useQueryParams } from "../hooks/useQueryParams";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { store } from "../components/Store";

/**
 * Este componente no recibe Props. Este se encarga de renderizar el mensaje popUp o ventana modal de invitación de lectura. Este aparece cuando se comparte un libro desde una cuenta. 
 * @returns InvitacionLectura
 */
export const InvitacionLectura = () => {
    const { isReady, textosSubSecciones: traducciones } = useObtenerSubsecciones('ficha_libro');
    const queryParams = useQueryParams();
    const [esInvitacion, setEsInvitacion] = useState(false);
    const [datosInvitacion, setDatosInvitacion] = useState({
        libro: '',
        institucion: '',
        linkPlataforma: '',
    })
    const {state} = useContext(store);
    const currentInstitution = state?.datosHeader?.institucion;
    const isAuth = state?.sesionActiva === "SI";
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const invitacionLectura = queryParams.get('invitacionLectura');
        const libro = queryParams.get('nombreLibro');
        const institucion = queryParams.get('institucion');
        const linkPlataforma = queryParams.get('linkPlataforma');

        if ((invitacionLectura === true || invitacionLectura === "true") && currentInstitution !== institucion && !isAuth) {
            setEsInvitacion(true);
        }

        setDatosInvitacion({
            institucion: institucion || 'N/A',
            libro: libro || 'N/A',
            linkPlataforma: linkPlataforma || 'N/A'
        })

    }, [queryParams, currentInstitution, isAuth]);


    return (
        <>
            {esInvitacion &&
                <FocusScope contain autoFocus>
                    <div 
                        className={`invitacion-lectura ${visible ? 'visible' : 'hidden'}`} 
                        role="alertdialog"
                        aria-modal="true"
                        aria-labelledby={'titulo-invitacion-lectura'}
                        aria-describedby={'dialogo-invitacion-lectura'}
                    >
                        <button className="close-button focusable-secondary" onClick={() => setVisible(false)}>
                            X
                        </button>
                        <h3 className="title" id="titulo-invitacion-lectura">
                            {`${traducciones?.invitacion?.lectura?.parte_1 || 'Quiero invitarte a Leer'}`}
                            <span className="font-weight-bold font-italic"> {datosInvitacion.libro} </span>
                            {`${traducciones?.invitacion?.lectura?.parte_2 || 'en MakeMake'}`}
                        </h3>
                        <p className="description" id="dialogo-invitacion-lectura">
                            {`${traducciones?.invitacion?.lectura?.parte_3 || 'Yo leí este libro gracias a'}`}
                            <span className="font-weight-bold"> {datosInvitacion.institucion}</span>.
                            {`${traducciones?.invitacion?.lectura?.parte_4 || ' Únete a la comunidad de'}`}
                            <span className="font-weight-bold"> {datosInvitacion.institucion} </span>
                            {`${traducciones?.invitacion?.lectura?.parte_5 || 'y lee'}`}
                            <span className="font-weight-bold font-italic"> {datosInvitacion.libro} </span>
                            {`${traducciones?.invitacion?.lectura?.parte_6 || 'haciendo click en este enlace'}`}:
                            <a className="secondary-inverse--color font-weight-bold focusable-red px-2" target="_blank" href={datosInvitacion?.linkPlataforma}>Link</a></p>
                    </div>
                </FocusScope>

            }
        </>

    )
}