import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente que renderiza el formulario de "¿Ninguno de nuestros planes se ajusta..." en plan casa
 * @returns {JSX.Element} CustomPlan
 */
export const CustomPlan = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textos = useTranslations({ section: "tienda_makemake", lang: lang });

    const history = useHistory();
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        history.push({
            pathname: "/contacto/demo",
            search: `?email=${encodeURIComponent(email)}`,
        });
    };

    return (
        <div className="custom-plan my-5">
            <div className="container px-0">
                <div className="d-md-flex">
                    <div className="col-md-3 px-0  custom-eyex-img"></div>
                    <div className="col-md-9 py-4 py-lg-5">
                        <div className="d-lg-flex">
                            <div className="col-lg-5 d-flex align-items-center mb-3 mb-lg-0">
                                <div>
                                    <h3 className="secondary-font text-18">{textos?.custom_plan?.titulo || '¿Ninguno de nuestros planes se ajusta a lo que buscas?'}</h3>
                                    <p className="text-16">{textos?.custom_plan?.descripcion || 'Agenda una cita y cuéntanos qué necesitas.'}</p>
                                </div>

                            </div>
                            <div className="col-lg-7">
                                <form
                                    className="m-0 p-0 my-2 row align-items-center"
                                    onSubmit={(e) => handleSubmit(e)}
                                >
                                    <input
                                        className="form-control-md p-1 col-12 col-md focusable-secondary"
                                        onChange={(e) => setEmail(e?.target?.value)}
                                        placeholder={textos?.custom_plan?.email || "Correo electrónico"}
                                        type="email"
                                        required
                                    />
                                    <div className="d-flex d-md-block col m-0 p-0 justify-content-end mt-3 mt-md-0">
                                        <button className="btn btn-primary p-2 px-3 mx-0 ml-md-3 focusable-primary">
                                            {textos?.custom_plan?.boton || "Agendar"}
                                        </button>
                                    </div>
                                    <p
                                        className="py-3 text-left font-weight-light text-12"
                                    >
                                        {textos?.condiciones?.al_ingresar ||
                                            "Al ingresar tus datos estás aceptando"}{" "}
                                        <Link
                                            className="focusable-secondary onred--color font-weight-bold"
                                            to="/terminos-y-condiciones"
                                        >
                                            {textos?.condiciones?.terminos ||
                                                "Términos y condiciones"}
                                        </Link>{" "}
                                        {textos?.condiciones?.y || "y"}{" "}
                                        <Link
                                            className="focusable-secondary onred--color font-weight-bold"
                                            to="/politica-de-privacidad"
                                        >
                                            {textos?.condiciones?.politica ||
                                                "Política de privacidad"}
                                        </Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}