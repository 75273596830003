import React, { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

import { FormularioDemo } from "./components/FormularioDemo";
import { ProminentClients } from "../prominent-clients";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

import { useIntersectionScroll } from "../../hooks/use-intersection-scroll";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { AppointmentDemo } from "./components/AppointmentDemo";

/**
 * Componente con el formulario de solicitud de contacto
 * Este se utiliza en la sección de búsqueda, la ficha del libro y la entrada del blog
 * @param {string} className - clases aplicadas al container del formulario
 * @param {function keepReading()} - funcion que oculta el contenido 
 * @param {string} - texto del boton
 }}
 * @returns {JSX.Element} SolicitudDemo
 */
export const SolicitudDemo = ({ className, keepReading, txtButton }) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textosDemo = useTranslations({ section: "demo", lang: lang });
    const [request, setRequest] = useState("");

    const [isIntersecting, ref] = useIntersectionScroll({
        threshold: [0.2, 1],
    });

    const returnRequest = () => setRequest("");

    return (
        <div className="solicitud-demo">

            <div className={`container ${className && className}`}>
                <div className={`form-demo ${isIntersecting ? 'anim-demo' : ''}`} ref={ref}>
                    {
                        keepReading &&
                        <div className="d-lg-flex  align-items-center justify-content-center py-lg-5">
                            <hr className="d-none d-lg-block" />
                            <button
                                onClick={() => keepReading()}
                                className="d-block d-lg-inline-block mb-3 mb-lg-0 btn-solicitud-demo-leer"
                            >
                                {txtButton? txtButton : textosDemo?.solicitud?.seguir_leyendo || 'Seguir leyendo'}
                            </button>
                            <hr className="d-none d-lg-block" />
                        </div>
                    }

                    <div className="col-lg-11 mx-auto d-lg-flex px-0 px-sm-3">
                        <div className="text-contacto col-lg-6 onprimary--color py-lg-5">
                            <h3>{textosDemo?.solicitud_2?.titulo || '¡Hablemos!'}</h3>
                            <p
                                className="font-secondary text-line-h-22 my-3"
                                style={{ fontWeight: 600 }}
                            >
                                {textosDemo?.solicitud_2?.descripcion || 'MakeMake ofrece a las instituciones acceso a la mejor colección de libros de América Latina para niños entre los 3 y 12 años.'}
                            </p>


                            <ul className="primary-font" style={{ fontWeight: 500 }}>
                                <li className="mb-3 d-flex align-items-center">
                                    <div width={30} className="mr-2">
                                        <Icon icon="fa:check" width={15} className=" onprimary--color" />
                                    </div>
                                    {textosDemo?.solicitud_2?.lista?.li1 || 'Servicio especializado para colegios y bibliotecas'}
                                </li>
                                <li className="mb-3 d-flex align-items-center">
                                    <div width={30} className="mr-2">
                                        <Icon icon="fa:check" width={15} className=" onprimary--color" />
                                    </div>
                                    {textosDemo?.solicitud_2?.lista?.li2 || 'Acceso fácil y seguro a libros de altísima calidad'}
                                </li>
                                <li className="mb-3 d-flex align-items-center">
                                    <div width={30} className="mr-2">
                                        <Icon icon="fa:check" width={15} className=" onprimary--color" />
                                    </div>
                                    {textosDemo?.solicitud_2?.lista?.li3 || 'Eventos con autores, recomendaciones de libros, talleres y guías de lectura'}
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-6">
                            {request === "" &&
                                <div className={`content-request`}>
                                    <div className="mt-lg-5 py-3 py-lg-0">
                                        <button
                                            onClick={() => setRequest("cita")}
                                            className="btn-request d-flex aling-items-center">
                                            {textosDemo?.solicitud_2?.agendar || 'Agenda tu cita ya'}
                                            <Icon icon="mi:arrow-right" width="25" height="25" className="icon" />
                                        </button>
                                        <button
                                            onClick={() => setRequest("info")}
                                            className="btn-request d-flex aling-items-center">
                                            {textosDemo?.solicitud_2?.info || 'Pide más información'}
                                            <Icon icon="mi:arrow-right" width="25" height="25" className="icon" />
                                        </button>
                                    </div>
                                </div>
                            }
                            {request === "info" &&
                                <div className={`content-form-demo py-3 `}>
                                    <button
                                        onClick={() => returnRequest()}
                                        className="d-flex aling-items-center justify-content-center onsecondary--color ml-3">
                                        <Icon icon="mi:arrow-left" width="25" height="25" className="icon mr-1" />
                                        {textosDemo?.solicitud_2?.volver || 'Volver'}
                                    </button>
                                    <FormularioDemo />
                                </div>
                            }

                            {request === "cita" &&
                                <div className="appointment py-3">
                                    <AppointmentDemo backButton={returnRequest} />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

            <ProminentClients />

        </div>
    )
}