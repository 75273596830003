import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { BOOKS_PREVIEW_SEARCH } from "./data/book_preview_search";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { Img } from "../shared/img";
import useIsMobile from "../../js/utilities/useIsMobile";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Mousewheel, Navigation } from "swiper";
import { NavigationButtonsBooks } from "../../libros/components/navigation-buttons";

/**
 * Retorna el texto introductorio en libros y los filtros de busqueda
 * @param {object} texts contiene los textos de interfaz
 * @returns {JSX.Element} IntroHome
 */
export const FilterBooks = () => {

    const options = [...BOOKS_PREVIEW_SEARCH];
    const [currentOption, setCurrentOption] = useState();

    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "home", lang: lang });
    const booksPreviewTexts = textos?.filtro;

    const isMobile = useIsMobile();

    const prevButtonBooksRef = useRef(null);
    const nextButtonBooksRef = useRef(null);

    const updateNavigation = (swiper) => {
        swiper.params.navigation.prevEl = prevButtonBooksRef?.current
        swiper.params.navigation.nextEl = nextButtonBooksRef?.current
        swiper.navigation.destroy()
        swiper.navigation.init()
        swiper.navigation.update()
    }


    return (
        <section className="container my-5">
            <div className="row">
                <div className="col-md-4 order-md-1">
                    <Img src="/img/matilda-libro.png" alt="blop sostiene una bandera" className="img-fluid" style={{ maxWidth: 250 }} />
                </div>
                <div className="col-md-8 order-0">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <p
                            className="text-intro secondary-font onbackground-primary--color font-weight-bold py-4 text-center text-md-left"
                            dangerouslySetInnerHTML={{
                                __html: `${textos?.sin_login?.coleccion ||
                                    "MakeMake ofrece a tu colegio y biblioteca <br class='d-none d-md-block'/> acceso a la mejor colección de libros de América Latina <br class='d-none d-md-block'/> para niños entre los 3 y 15 años"
                                    }`,
                            }}
                        ></p>
                    </div>
                </div>
            </div>
            <div className="row px-0 my-5 ">

                {
                    isMobile ?
                        <div className="container">
                            <Swiper
                                className="px-5"
                                style={{ overflowX: "hidden" }}
                                slidesPerView={"auto"}
                                freeMode={true}
                                direction="horizontal"
                                mousewheel={{
                                    forceToAxis: false,
                                }}
                                navigation={{
                                    nextEl: nextButtonBooksRef?.current,
                                    prevEl: prevButtonBooksRef?.current,
                                }}
                                onSwiper={(swiper) => {
                                    updateNavigation(swiper);
                                }}
                                modules={[Navigation, Mousewheel, FreeMode]}
                            >
                                {options.map((option, i) => {
                                    return (
                                        <SwiperSlide
                                            key={`other${i}`}
                                            className="focusable-by-children-secondary d-flex justify-content-center align-items-center mx-2 p-0"
                                            style={{
                                                minWidth: 200,
                                                maxWidth: 200,
                                                height: "auto",
                                            }}
                                        >
                                            <Link
                                                to={option?.type === "gratis" ? `/coleccion/libros-gratuitos/` : `/busqueda?${option?.type}=${option?.value}`}
                                                onClick={() => {setCurrentOption(option)}}
                                                style={{ fontWeight: 500 }}
                                                className={`p-2 px-3 preview-button-option focusable-red ${currentOption?.value === option?.value ? "selected" : ""
                                                    }`}

                                            >
                                                {_.get(booksPreviewTexts, option?.text?.translationPath) ||
                                                    option?.text?.default ||
                                                    "N/A"}
                                            </Link>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                            <div className="container my-4">
                                <NavigationButtonsBooks nextButtonRef={nextButtonBooksRef} prevButtonRef={prevButtonBooksRef} responsiveButtons={true} />
                            </div>
                        </div>
                        :
                        <div className="d-flex flex-wrap justify-content-center align-items-center focusable-primary">
                            {options?.map((option, i) => {
                                return (
                                    <Link
                                        key={i}
                                        onClick={() => setCurrentOption(option)}
                                        style={{ fontWeight: 500 }}
                                        className={`p-2 m-1 px-3 preview-button-option focusable-red ${currentOption?.value === option?.value ? "selected" : ""
                                            }`}
                                        to={option?.type === "gratis" ? `/coleccion/libros-gratuitos/` : `/busqueda?${option?.type}=${option?.value}`}
                                    >
                                        {_.get(booksPreviewTexts, option?.text?.translationPath) ||
                                            option?.text?.default ||
                                            "N/A"}
                                    </Link>
                                );
                            })}
                        </div>
                }


            </div>
        </section >
    )
}