import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

import { SwiperPhotosSchools } from "./swiper-photos";
import { SwiperCmmentSchools } from "./swiper-comments";
import { SwiperSchoolsResponsive } from "./swiper-schools-res";
import { SwiperSchools } from "./swiper-schools";

import useIsMobile from "../../../js/utilities/useIsMobile";

/**
 * Componente de "Experiencia comprobada" para que es colegios
 * @returns {JSX.Element} Componente Experience.
 */
export const Experience = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
	const textoColegios = useTranslations({ section: "que_es_colegios", lang: lang });

	const isMobile = useIsMobile();

	return (
		<>
			<div className="experience  pb-4">
				<div className="container px-lg-0">
					<div className="col-lg-10 mx-auto">
						<h1
							tabIndex={0}
							className="focusable-secondary secondary-font font-weight-bold py-4 onbackground-primary--color"
						>
							{textoColegios?.experiencia?.titulo || "Experiencia comprobada"}
						</h1>

						<div className="row">
							<div className="col-lg-6 order-lg-1">
								<SwiperPhotosSchools />
							</div>
							<div className="col-lg-6 order-lg-0">
								<SwiperCmmentSchools />
							</div>

						</div>
						{/* */}
					</div>
				</div>
			</div>

			<div className="container px-lg-0 py-5">
				<div className="col-lg-10 mx-auto">
					<h2 className="focusable-secondary secondary-font font-weight-bold pb-4 onbackground-primary--color text-center">
						{textoColegios?.experiencia?.subtitulo ||
							"¡Los mejores colegios ya están con makemake!"}
					</h2>

					{
						isMobile ?
							<SwiperSchoolsResponsive />
							:
							<SwiperSchools />
					}
				</div>
			</div>
		</>
	);
};
