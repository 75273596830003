import React, { useEffect, useRef, useContext } from "react";
import { Icon } from "@iconify/react";
import { ASSETS_URL, store } from "../../../Store";

import { DropdownCuentaIndividual } from "./DropdownCuentaIndividual";
import { useIndividualHeaderDropdown } from "../../../../hooks/header/useIndividualHeaderDropdown";
import { useAccessibility } from "../../../../hooks/useAccessibility";
import { NotificationButton } from "../../components/notification-button";
import { LangButton } from "../../../language/lang-button";

/**
 * Componente con las opciones del header individual
 * @param {object} textosInterfaz - contiene los textos de interfaz
 * @returns {JSX.Element} NavbarNav
 */
export const NavbarNav = ({ textosInterfaz }) => {

    const globalState = useContext(store);
    const { state } = globalState;
    const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";

    const accessibility = useAccessibility();
    const accessibilityButtonRef = useRef(null);

    const showADropdown = useIndividualHeaderDropdown();
    const visible = showADropdown?.state?.visible;
    const dropdownButtonRef = useRef(null);

    /*Asigna el botón que abre el dropdown del header individual */
    useEffect(() => {
        if (dropdownButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "individualHeaderDropdown.triggerButtonRef",
                value: dropdownButtonRef,
            });
        }
    }, [dropdownButtonRef.current]);

    /*Asigna el botón que abre las opciones de accesibilidad */
    useEffect(() => {
        if (accessibilityButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "accessibility.triggerButton",
                value: accessibilityButtonRef,
            });
        }
    }, [accessibilityButtonRef]);

    /*cierra el dropdown al hacer scroll al inicio de la página */
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 1 && window.scrollY < 10) {
                showADropdown.setVisible(false)
            }
        });
    }, [accessibility.state.visible])


    return (
        <ul className="option-nav d-flex justify-content-end aling-items-center">
            <li className="position-relative mx-3">
                <button
                    ref={dropdownButtonRef}
                    className={`btn-cuenta focusable-red ${visible && 'active-cuenta'}`}
                    onClick={() => {
                        showADropdown.setVisible(!showADropdown?.state?.visible);
                    }}
                >
                    <div
                        className="mr-1 mr-lg-3 btn-avatar"
                        style={
                            { backgroundImage: `url('${isLogged ? `${ASSETS_URL}${state.datosHeader?.avatar}` : '/img/profile.png'}')` }
                        }
                    ></div>
                    <span>{textosInterfaz?.mi_cuenta || 'Mi cuenta'}</span>
                    <i className="fas fa-chevron-down fa-sm ml-2 arrow"></i>
                </button>

                <DropdownCuentaIndividual textosInterfaz={textosInterfaz} />

            </li>
            <li className={`${accessibility.state.visible ? 'btn-setting activeMode mx-1' : 'btn-setting mx-1'}`}>
                <button
                    ref={accessibilityButtonRef}
                    className={`focusable-red w-100 h-100 px-2`}
                    onClick={() => { accessibility.setVisible(!accessibility?.state?.visible) }}
                >
                    <Icon
                        /* icon="material-symbols:settings" */
                        icon="fontisto:universal-acces"
                        width={20}
                    />
                </button>
            </li>

            <li>
                <LangButton />
            </li>

            <NotificationButton />
        </ul>
    )
}