import React from "react";
import { FormularioDemo } from "../../../../components/solicitud-demo/components/FormularioDemo";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { Helmet } from "react-helmet";

/**
 * Componente que devuelve el contenido del formulario Demo -"Solicitar información", de la tienda para instituciones
 * @returns {JSX.Element} Demo
 */
export const Demo = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
    const textosTienda = useTranslations({ section: "tienda_makemake", lang: lang });

    return (
        <>
            <Helmet>
                <title>{textosTienda?.metadata?.demo?.title || 'MakeMake - Agendar demo'}</title>
                <meta name="description" content={textosTienda?.metadata?.demo?.description || 'Un agente de MakeMake te contactará para explicarte más sobre nuestros servicios'} />
            </Helmet>
            <div className="subsection pt-3">
                <FormularioDemo
                    lang={lang}
                    formDescription={textosTienda?.institucional?.demo?.descripcion || "Déjanos tus datos y un agente de MakeMake te contactará para explicarte más sobre nuestros servicios"}
                />
            </div>
        </>
    )
}