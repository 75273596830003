import React, { useRef } from "react";
import { Img } from "../../../components/shared/img";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Mousewheel, Navigation } from "swiper";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { ALL_LIBRARYS_IMAGES } from "../../../shared/data/libraries-data";
import { ALL_INSTITUTIONS_IMAGES } from "../../../shared/data/institutions-data";
import { NavigationButtonsBooks } from "../../../libros/components/navigation-buttons";

export const Clients = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const textosClientes = textos?.clientes;

	const clients = [...ALL_LIBRARYS_IMAGES, ...ALL_INSTITUTIONS_IMAGES];

	const prevButtonRef = useRef(null);
	const nextButtonRef = useRef(null);

	const updateNavigation = (swiper) => {
		swiper.params.navigation.prevEl = prevButtonRef?.current
		swiper.params.navigation.nextEl = nextButtonRef?.current
		swiper.navigation.destroy()
		swiper.navigation.init()
		swiper.navigation.update()
	}

	return (
		<div className="surface--bg py-4 onbackground-primary--color">
			<div className="container p-3">
				<h2 
					className="secondary-font text-center onprimary--color title-publishers"
					dangerouslySetInnerHTML={{
						__html: textosClientes ? textosClientes?.titulo_2 : "En América Latina y Estados Unidos, <br className='d-none d-lg-block'/>  los expertos en lectura eligen MakeMake",
					}}
				></h2>
			</div>
			<Swiper
				className="px-5"
				style={{ overflowX: "hidden" }}
				slidesPerView={"auto"}
				freeMode={true}
				direction="horizontal"
				mousewheel={{
					forceToAxis: false,
				}}
				navigation={{
					nextEl: nextButtonRef?.current,
					prevEl: prevButtonRef?.current,
				}}
				onSwiper={(swiper) => {
					updateNavigation(swiper);
				}}
				modules={[Navigation, Mousewheel, FreeMode]}
			>
				{clients.map((client, i) => {
					return (
						<SwiperSlide
							key={i}
							className="d-flex justify-content-center align-items-center mx-2 p-0"
							style={{
								minWidth: 200,
								maxWidth: 200,
								height: "auto",
								pointerEvents: "none",
							}}
						>
							<Img
								key={i}
								style={{ minWidth: 200, maxWidth: 200, height: "auto" }}
								src={client?.src}
								alt="client-image"
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
			<div className="container my-4">
				<NavigationButtonsBooks nextButtonRef={nextButtonRef} prevButtonRef={prevButtonRef} responsiveButtons={true}/>
			</div>
		</div>
	);
};
