import React from "react";
import { useState } from "react";
import { COMMUNITY_SERVICES } from "../data/community-services";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper";
import useIsMobile from "../../../js/utilities/useIsMobile";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { Icon } from "@iconify/react/dist/iconify.js";

export const CommunityServiceCard = ({ title, shapeColor, img }) => {
	return (
		<div className="community-service-card m-2 d-flex flex-column justify-content-between secondary-inverse--bordered">
			<p className="title p-3 d-flex align-items-center justify-content-center">
				<span>{title || "N/A"}</span>
			</p>
			<div className="image-container">
				<div className="shape" style={{ backgroundColor: shapeColor }}></div>
				<img className="image" src={img} alt={title || "image"} />
			</div>
		</div>
	);
};

export const CommunityServicesMobile = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const communityText = textos?.comunidad_servicios;

	const [servicesData, setServicesData] = useState([...COMMUNITY_SERVICES]);

	return (
		<ul className="mx-sm-auto p-0" style={{maxWidth:337}}>
			{servicesData.map((service, i) => {
				return (
					<li key={i} className="d-flex mb-2"> 
						<div width={"10%"}>
							<Icon
								className="primary-inverse--color"
								icon="tabler:point-filled"
								width={"15px"}
							/>
						</div>
						{
							_.get(communityText, service?.title?.translationPath) ||
							service?.title?.default ||
							"N/A"
						}
					</li>
				)
			})
			}
		</ul>
	);
};

export const CommunityServices = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const communityText = textos?.comunidad_servicios;

	const [servicesData, setServicesData] = useState([...COMMUNITY_SERVICES]);
	return (
		<Swiper
			className="p-2 pb-5 sample-slider"
			style={{ overflowX: "hidden" }}
			slidesPerView={1}
			slidesPerGroup={1}
			breakpoints={{
				500: {
					slidesPerView: 2,
				},
				680: {
					slidesPerView: 3,
				},
				1000: {
					slidesPerView: 4,
				},
				1200: {
					slidesPerView: 5,
				},
			}}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
			mousewheel={{
				forceToAxis: true,
			}}
			pagination={{
				clickable: true,
				enabled: true,
				bulletActiveClass: "onsecondary--bg",
			}}
			modules={[Autoplay, Pagination, Navigation, Mousewheel]}
		>
			{servicesData.map((service, i) => {
				return (
					<SwiperSlide
						key={i}
						className="d-flex justify-content-center align-items-center m-0 p-0"
						style={{ width: 220, height: 220 }}
					>
						<CommunityServiceCard
							key={i}
							title={
								_.get(communityText, service?.title?.translationPath) ||
								service?.title?.default ||
								"N/A"
							}
							img={service?.img}
							shapeColor={service?.shapeColor}
						/>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export const Community = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const communityText = textos?.comunidad_servicios;
	const isMobile = useIsMobile();

	return (
		<div className="community secondary--bg onsecondary--color ">
			<div className="wrapper-community p-3">
				<div className="container">
					<div className="d-flex flex-column justify-content-center align-items-center text-center pt-lg-4 pb-3">
						{isMobile ?
							<div className="secondary-font title-community">
								<h2>{communityText?.titulo_responsive || 'MakeMake te ofrece los mejores libros y acompañamiento para lectores'}</h2>
							</div>
							:
							<div className="secondary-font title-community">
								<h2>{communityText?.titulo_1 || 'MakeMake es la plataforma con la mejor combinación:'}</h2>
								<h3>{communityText?.titulo_2 || 'libros de altísima calidad +  comunidad de lectura'}</h3>
							</div>
						}
					</div>
					{isMobile ? <CommunityServicesMobile /> : <CommunityServices />}
				</div>
			</div>
		</div>
	);
};
