import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { FreeMode, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export const CommunityMonthSelector = ({ months, onSelect, defaultMonthId }) => {
	const [currentMonth, setCurrentMonth] = useState(null);
	const swiperRef = useRef();
	const visibleMonths = Array.isArray(months)
		? [...months].filter((month) => (month?.visible === true && (month?.empty === false || month?.empty === undefined)))
		: [];
	const nextButtonRef = useRef();
	const prevButtonRef = useRef();

	const handleOnSelect = (month) => {
		if (month?.id !== currentMonth?.id) {
			setCurrentMonth(month);
			onSelect && onSelect(month);
			const monthIndex = visibleMonths.findIndex((m) => m.id === month?.id);
			focusMonth(monthIndex);
		}
	};

	const handleDefaultSelectedMonth = () => {
		const firstElementVisible = visibleMonths?.find((month) => month.visible === true);
		const firstElementVisibleIndex = visibleMonths?.findIndex(
			(month) => month.visible === true
		);
		if (firstElementVisible) {
			setCurrentMonth(firstElementVisible);
			focusMonth(firstElementVisibleIndex);
		}
	};

	const focusMonth = (monthIndex) => {
		if (swiperRef.current && swiperRef.current.slideTo) {
			swiperRef.current.slideTo(monthIndex);
		}
	};

	const updateNavigation = (swiper) => {
		swiper.params.navigation.prevEl = prevButtonRef.current;
		swiper.params.navigation.nextEl = nextButtonRef.current;
		swiper.navigation.destroy();
		swiper.navigation.init();
		swiper.navigation.update();
	};

	useEffect(() => {
		if (swiperRef.current) {
			updateNavigation(swiperRef.current);
		}
		const ids = visibleMonths.map((month) => month.id.toLowerCase());
		if (defaultMonthId && ids.includes(defaultMonthId.toLowerCase())) {
			const month = visibleMonths.find((m) => m.id === defaultMonthId && m.visible === true);
			const monthIndex = visibleMonths.findIndex(
				(m) => m.id === defaultMonthId && m.visible === true
			);
			if (month) {
				setCurrentMonth(month);
				focusMonth(monthIndex);
			} else {
				handleDefaultSelectedMonth();
			}
		} else {
			handleDefaultSelectedMonth();
		}
	}, [defaultMonthId, JSON.stringify(visibleMonths), swiperRef.current]);

	return (
		<div
			className="community-month-selector d-flex justify-content-center align-items-center m-auto"
			style={{ maxWidth: 650 }}
		>
			<button className="nav-button col-auto d-none d-sm-flex" ref={prevButtonRef}>
				<Icon icon="fa-solid:angle-left" width={24} />
			</button>
			<Swiper
				className="col overflow-hidden"
				slidesPerView={"auto"}
				slidesPerGroupAuto
				freeMode={{
					enabled: true,
				}}
				onSwiper={(s) => {
					swiperRef.current = s;
					updateNavigation(s);
				}}
				on
				navigation={{
					nextEl: nextButtonRef.current,
					prevEl: prevButtonRef.current,
					enabled: true,
				}}
				modules={[Navigation, FreeMode]}
			>
				<ul className="d-flex py-5">
					{visibleMonths.map((month, i) => {
						return (
							<>
								{month?.visible ? (
									<SwiperSlide key={month?.id} className="w-auto">
										<li
											key={month?.id}
											className={`p-2 m-3 item ${
												currentMonth?.id === month?.id ? "active" : ""
											}`}
											onClick={() => handleOnSelect(month)}
										>
											{month?.name} {`${month?.year ? month?.year:''}`}
										</li>
									</SwiperSlide>
								) : null}
							</>
						);
					})}
				</ul>
			</Swiper>
			<button className="col-auto nav-button d-none d-sm-flex" ref={nextButtonRef}>
				<Icon icon="fa-solid:angle-right" width={24} />
			</button>
		</div>
	);
};
