import { useContext } from "react";
import { store } from "../../Store";
/**
 * Este hook permite mostrar u ocultar el contenido de contacto sin login.
 * Este se usa en las secciones de busqueda, ficha del libro y entrada del blog sin login
 * @returns{{
    setVisible:(visible:boolean),
    state:object
 * }}
 */
export const useContactRequest = () => {
    const storeContext = useContext(store);
    const globalState = storeContext?.state;

    const setVisible = (visible) => {
        storeContext.dispatch({
            type: "CHANGE",
            path: "contactRequest.visible",
            value: visible ? true : false,
        });
    }

    return {
        setVisible,
        state: globalState?.contactRequest
    }
}