import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Img } from "../../../components/shared/img";

/**
 *  Primer componente de introducción para que es bibliotecas
 * @returns {JSX.Element} Componente Introduction.
 */
export const Introduction = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
	const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });
	const caracteristicas = textosBibliotecas?.caracteristicas_2;

	const firstFeatures = () => {
		const puntos = [];
		if (!caracteristicas) return;
		Object.keys(caracteristicas).map((item, index) => {
			if (item !== `titulo`) {
				puntos.push(
					<li
						key={`feature${index}`}
						tabIndex={0}
						className="focusable-secondary d-flex mt-1"
					>
						<div width={"10%"}>
							<Icon
								className="blue-inverse--color"
								icon="tabler:point-filled"
								width={"30px"}
							/>
						</div>

						<p
							width={"90%"}
							style={{ fontSize: "0.9em" }}
							className=" ml-2 onprimary--color"
							dangerouslySetInnerHTML={{ __html: caracteristicas[item] || "N/A" }}
						>
						</p>
					</li>
				);
			}

		});
		return puntos;
	};

	return (
		<div className="introduction py-4">
			<div className="container">
				<h1
					tabIndex={0}
					className="focusable-secondary onbackground-primary--color secondary-font text-center"
					style={{ lineHeight: "1.3em" }}
				>
					{caracteristicas?.titulo || 'Multiplica tu impacto y gana nuevos lectores con MakeMake'}
				</h1>
				<div className="row mx-0 align-items-stretch py-3">
					<div className="d-none d-lg-block col-lg-6 px-0 ">
						<Img src="/img/bibliotecas-que-es/bibliotecas2025.png" />
					</div>
					<div className="col-lg-6 px-0 ">
						<ul>
							{firstFeatures()}
						</ul>
					</div>
					<div className="d-lg-none mx-auto mt-3">
						<Img src="/img/bibliotecas-que-es/bibliotecas2025_res.png"/>
					</div>
				</div>
			</div>
		</div>
	);
};
