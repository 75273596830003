import React from "react";
import { SPONSORS } from "../data/sponsors";
import _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente de patrocinadores en el pie de página.
 *
 * Muestra los patrocinadores en el pie de página en forma de una lista
 * horizontal de logos con enlaces a sus sitios web.
 *
 * @returns {JSX.Element} El JSX del componente de patrocinadores.
 */
export const Sponsors = () => {
		const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
		const texts = useTranslations({ section: "footer", lang: lang });
	const sponsors = [...SPONSORS];
	return (
		<div className="d-flex flex-column  p-0 ">
			<h2 className="section-title d-flex d-lg-none">
				<span className="text">{_.get(texts, "apoyo_de.titulo") || "Con el apoyo de"}</span>
				<div className="divider d-block d-lg-none"></div>
			</h2>
			<div className="d-flex flex-wrap justify-content-center align-items-center">
				{sponsors?.map((sponsor, i) => {
					return (
						<a
							key={i}
							className="m-2 d-flex justify-content-center align-items-center"
							aria-label="sponsor"
							href={sponsor.url}
							rel="noopener noreferrer"
							target="_blank"
						>
							<img
								key={i}
								className="img-fluid sponsor-img"
								src={sponsor?.imgUrl}
								alt={`sponsor-${i}`}
								
							/>
						</a>
					);
				})}
			</div>
		</div>
	);
};
