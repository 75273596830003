import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import { Titles } from "../../components/Titles";
import { TabPanel } from "../../components/shared/tabpanel";
import { Subsections } from "./components/subsections";
import { ProminentClients } from "../../components/prominent-clients";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Este componente tab panel carga la tienda para instituciones
 */
export const PlanInstituciones = () => {
    /* const [urlLang, setURLLang] = useState('es'); */
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
    const textos = useTranslations({ section: "tienda_makemake", lang: lang });

    const history = useHistory();
    const location = useLocation();
    /* const tabPanelIds = {
        citaES: `/tienda/institucional/es/cita`,
        citaEN: `/tienda/institucional/en/cita`,
        demoES: "/tienda/institucional/es/demo",
        demoEN: "/tienda/institucional/en/demo",
        brochureES: "/tienda/institucional/es/brochure",
        brochureEN: "/tienda/institucional/en/brochure",
        contactanosES: "/tienda/institucional/es/contactanos",
        contactanosEN: "/tienda/institucional/en/contactanos",
    };
 */
    const tabPanelIds = {
        cita: `/tienda/institucional/cita`,
        demo: "/tienda/institucional/demo",
    };

    /*define cual es el tab panel (tienda) que se debe mostrar en pantalla.*/
    const [currentTabPanel, setCurrentTabPanel] = useState(tabPanelIds.citaES);

    useEffect(() => {
        /* const langAccordingUrl = location.pathname.includes('/es/') ? "es" : "en";
        setURLLang(langAccordingUrl) */
        /**
            dependiendo de la ruta del navegador si coincide con algún id 
            de los tab panel, entonces muestra el panel respectivo, 
            si no es así mostrará por defecto el de tienda institucional
         */
        if (Object.values(tabPanelIds).includes(location.pathname)) {
            setCurrentTabPanel(location.pathname);
        } else {
            setCurrentTabPanel(tabPanelIds.citaES);
        }
    }, [location]);

    /**
     * cambia la ruta actual, debería llevar a la ruta de una tienda especifica.
     * Si la ruta coincide con el id de algún tab panel se mostrará ese panel automaticamente,
     * de no existir se mostrará por defecto el tab de tienda institucional.
     */
    const tabPanelNavigateTo = (path) => {
        history.push(path);
    };


    return (
        <>
            <Titles
                hexBackground="#FFD103"
                color="onprimary--color"
                title={textos?.tienda?.instituciones?.titulo || 'Contáctanos'}
                darkShapes
                description={textos?.tienda?.instituciones?.subtitulo || 'MakeMake ofrece suscripciones a planes de lectura para colegios y bibliotecas.'}
            />
            <div className="plan-instituciones">
                <div className="container instituciones-content">
                    <div>
                        <div>
                            <TabPanel.Box currentPanelId={currentTabPanel} className="d-lg-flex">
                                <TabPanel.Tabs className={"wrapper-tabs col-lg-4 col-xl-3 pt-lg-5 px-0"} >
                                    <TabPanel.Tab tabPanel={tabPanelIds.cita} className={"mt-5"}>
                                        <button
                                            className={`focusable-red tab ${currentTabPanel === tabPanelIds.cita ? "current" : ""}`}
                                            onClick={() => {
                                                const urlActual =  tabPanelIds.cita;
                                                tabPanelNavigateTo(urlActual)
                                            }}
                                        >
                                            {textos?.tabpanel?.institucional1 || "Agenda una cita"}
                                            {
                                                currentTabPanel === tabPanelIds.cita &&
                                                <Icon icon="iconamoon:arrow-right-2-bold" width={25} className="d-none d-lg-block" />
                                            }
                                        </button>
                                    </TabPanel.Tab>
                                    <TabPanel.Tab tabPanel={tabPanelIds.demo}>
                                        <button
                                            className={`focusable-red tab ${currentTabPanel === tabPanelIds.demo ? "current" : ""}`}
                                            onClick={() => {
                                                const urlActual =  tabPanelIds.demo;
                                                tabPanelNavigateTo(urlActual)
                                            }}
                                        >
                                            {textos?.tabpanel?.institucional2 || "Solicita un demo"}
                                            {
                                                currentTabPanel === tabPanelIds.demo &&
                                                <Icon icon="iconamoon:arrow-right-2-bold" width={25} className="d-none d-lg-block" />
                                            }
                                        </button>
                                    </TabPanel.Tab>
                                    {/* <TabPanel.Tab tabPanel={urlLang === "es" ? tabPanelIds.brochureES : tabPanelIds.brochureEN}>
                                        <button
                                            className={`focusable-red tab ${currentTabPanel === tabPanelIds.brochureES || currentTabPanel === tabPanelIds.brochureEN ? "current" : ""}`}
                                            onClick={() => {
                                                const urlActual = urlLang === "es" ? tabPanelIds.brochureES : tabPanelIds.brochureEN;
                                                tabPanelNavigateTo(urlActual)
                                            }}
                                        >
                                            {textos?.tabpanel?.institucional3 || "Descarga un brochure informativo"}
                                            {
                                                currentTabPanel === tabPanelIds.brochureES || currentTabPanel === tabPanelIds.brochureEN &&
                                                <Icon icon="iconamoon:arrow-right-2-bold" width={25} className="d-none d-lg-block" />
                                            }
                                        </button>
                                    </TabPanel.Tab>
                                    <TabPanel.Tab tabPanel={urlLang === "es" ? tabPanelIds.contactanosES : tabPanelIds.contactanosEN}>
                                        <button
                                            className={`focusable-red tab ${currentTabPanel === tabPanelIds.contactanosES || currentTabPanel === tabPanelIds.contactanosEN ? "current" : ""}`}
                                            onClick={() => {
                                                const urlActual = urlLang === "es" ? tabPanelIds.contactanosES : tabPanelIds.contactanosEN;
                                                tabPanelNavigateTo(urlActual)
                                            }}
                                        >
                                            {textos?.tabpanel?.institucional4 || "Contáctanos"}
                                            {
                                                currentTabPanel === tabPanelIds.contactanosES || currentTabPanel === tabPanelIds.contactanosEN &&
                                                <Icon icon="iconamoon:arrow-right-2-bold" width={25} className="d-none d-lg-block" />
                                            }
                                        </button>
                                    </TabPanel.Tab> */}
                                </TabPanel.Tabs>

                                {/* cita */}
                                <TabPanel.Panel
                                    panelId={tabPanelIds.cita}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Cita />
                                </TabPanel.Panel>

                                {/* demo */}
                                <TabPanel.Panel
                                    panelId={tabPanelIds.demo}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Demo />
                                </TabPanel.Panel>

                                {/* brochure */}
                                {/* <TabPanel.Panel
                                    panelId={tabPanelIds.brochureES}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Brochure urlLang={urlLang}/>
                                </TabPanel.Panel>
                                <TabPanel.Panel
                                    panelId={tabPanelIds.brochureEN}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Brochure urlLang={urlLang}/>
                                </TabPanel.Panel> */}

                                {/* contactanos */}
                                {/* <TabPanel.Panel
                                    panelId={tabPanelIds.contactanosES}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Contactanos urlLang={urlLang}/>
                                </TabPanel.Panel>
                                <TabPanel.Panel
                                    panelId={tabPanelIds.contactanosEN}
                                    className={'col-lg-8 col-xl-9 mt-5 mt-lg-0 instituciones-panel'}
                                >
                                    <Subsections.Contactanos urlLang={urlLang}/>
                                </TabPanel.Panel> */}
                            </TabPanel.Box>
                        </div>

                    </div>
                </div>
                
                <ProminentClients />

            </div>
        </>
    )
}