import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

import { SwiperPhotos } from "./swiper-photos";
import { SwiperComment } from "./swiper-comment";
import { SwiperLibries } from "./swiper-libraries";
import { SwiperLibriesResponsive } from "./swiper-libraries-res";

import useIsMobile from "../../../js/utilities/useIsMobile";

/**
 * Componente de "Experiencia comprobada" para que es bibliotecas
 * @returns {JSX.Element} Componente Experience.
 */
export const Experience = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });

	const isMobile = useIsMobile();

	return (
		<>
			<div className="experience pb-4">
				<div className="container px-lg-0">
					<div className="col-lg-10 mx-auto py-2">
						<h1
							tabIndex={0}
							className="focusable-secondary secondary-font font-weight-bold py-4 onbackground-primary--color"
						>
							{textosBibliotecas?.experiencia?.titulo || "Experiencia comprobada"}
						</h1>
						<div className="row">
							<div className="col-lg-6 order-lg-1">
								<SwiperPhotos />
							</div>
							<div className="col-lg-6 order-lg-0">
								<SwiperComment />
							</div>

						</div>
					</div>
				</div>
			</div>

			<div className="container px-lg-0 py-5">
				<div className="col-lg-10 mx-auto">
					<h2 className="focusable-secondary secondary-font font-weight-bold pb-4 onbackground-primary--color text-center">
						{textosBibliotecas?.experiencia?.subtitulo ||
							"¡Las mejores bibliotecas ya están con makemake!"}
					</h2>

					{
						isMobile ?
							<SwiperLibriesResponsive />
							:
							<SwiperLibries />
					}

				</div>
			</div>
		</>
	);
};
