import React from "react";
import { Plans } from "../../../components/plans";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";

/**
 * Componente de planes para que es principal
 * @returns {JSX.Element} Componente PlansHome.
 */
export const PlansHome = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "planes", lang: lang });

    return (
        <div className="planes-home">
            <h2 className="secondary-font pt-3 pt-lg-5 px-3">{textos?.que_es?.titulo || 'MakeMake se adapta a tu institución'}</h2>
            <div className="container py-3 py-lg-5">
                <Plans buttons />
            </div>
        </div>
    )
}