import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { BackButtonHeader } from "./back-button";
import { useContext } from "react";
import { store } from "../../components/Store";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { Helmet } from "react-helmet";
import { InstitutionSelect } from "./institution-select";

/**
 * Componente de presentación,  que muestra la sección de busqúeda de institución.
 * @returns {JSX.Element} - Sección de búsqueda de instituciones y sedes.
 */
export const InstitutionalSearch = ({}) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const fetching = appState.institutionalAuth?.fetchCompleted === true ? false : true;
	const [librarySelectExpanded, setLibrarySelectExpanded] = useState(false);

	return (
		<div className="institutional-auth">
			<Helmet>
                <title>{textos?.metadata?.title || 'MakeMake - Buscar una institución'}</title>
                <meta name="description" content={textos?.metadata?.description || 'Busca tu institución para ingresar a MakeMake y disfruta tu colección.'} />
            </Helmet>
			<div className="relative">
				<div className="bg-shape"></div>
				<div className="container page-content d-flex flex-column align-items-center">
					<BackButtonHeader customLink={"/login"} />
					<h1 className="d-none d-md-flex onsecondary-title mt-5">
						{textos?.busqueda?.titulo || "Empieza a leer con MakeMake."}
					</h1>
					<h1 className="onsecondary-title mb-2 mb-md-5">
						{textos?.busqueda?.titulo2 ||
							"Busca tu colegio o biblioteca para registrar tu ingreso:"}
					</h1>
					<InstitutionSelect
						onExpand={() => setLibrarySelectExpanded(true)}
						onCollapse={() => setLibrarySelectExpanded(false)}
					/>
					{/* <Link
						className="onsecondary-link mt-5 mx-5 focusable-primary text-center"
						style={{fontSize:'1.2em'}}
						to="/institutional-auth/institution-not-found"
					>
						{textos?.busqueda?.links?.institucion_no_encontrada ||
							"¿No encuentras tu institución?"}
					</Link> */}
					<div className=" pt-1 d-flex flex-column justify-content-center align-items-center mt-2 mt-md-5">
						<p className="onsecondary--color font-weight-bold text-center text-18" >
						{textos?.busqueda?.descargar_app?.sugerencia ||
							"Cónoce más sobre nuestra app"}
							
						</p>
						<div className="d-flex flex-column flex-md-row justify-content-center align-items-center my-2">
							<a
								href="https://apps.apple.com/us/app/makemake-app/id6469707768?platform=iphone"
								className="focusable-red"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Apple app store"
							>
								<img
									className="img-fluid m-2"
									style={{ maxWidth: 200 }}
									src="/img/makemake-app/apple-store-badget.png"
									alt="apple store"
								/>
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.makemake.app"
								className="focusable-red"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Play store"
							>
								<img
									className="img-fluid m-2"
									style={{ maxWidth: 200 }}
									src="/img/makemake-app/google-play-badget.png"
									alt="apple store"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
