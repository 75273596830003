import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Titles } from "../components/Titles";
import { Plans } from "../components/plans";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";
import { ContactDemoForm } from "./componentes/contactDemo";
import { ContactCita } from "./componentes/contactCita";
import { TabPanel } from "../components/shared/tabpanel";
import useIsMobile from "../js/utilities/useIsMobile";

/**
 * Componente que devuelve el contenido de contacto
 * @returns {JSX.Element} Contact
 */
export const Contact = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "contacto", lang: lang });

    const history = useHistory();
    const location = useLocation();
    const sectionRef = useRef();

    const tabPanelIds = {
        cita: `/contacto/cita`,
        demo: "/contacto/demo",
    };

    const [currentTabPanel, setCurrentTabPanel] = useState("");

    const isMobile = useIsMobile();

    useEffect(() => {
        /**
            dependiendo de la ruta del navegador si coincide con algún id 
            de los tab panel, entonces muestra el panel respectivo, 
            si no es así mostrará por defecto la pagina de contacto
         */
        if (Object.values(tabPanelIds).includes(location.pathname)) {
            setCurrentTabPanel(location.pathname);
            if (sectionRef?.current) {
                const element = sectionRef?.current;
                element.scrollIntoView({ behavior: "auto", block: "start" });
            }
        } else {
            setCurrentTabPanel("");
        }
    }, [location]);

    const tabPanelNavigateTo = (path) => {
        history.push(path);
    };


    return (
        <>
            <Titles
                hexBackground="#FFD103"
                color="onprimary--color"
                title={textos?.contacto?.titulo || '¿Eres profe, bibliotecaria, o diriges un colegio?'}
                darkShapes
            />
            <div className="contacto">
                <div className="container">
                    <h2 className="secondary-font text-intro py-5 onbackground-primary--color text-center text-md-left">
                        {textos?.contacto?.descripcion || 'MakeMake ofrece a tu colegio y biblioteca acceso a la mejor colección de libros de América Latina para niños entre los 3 y 15 años'}
                    </h2>


                    <div className="row" >

                        <div className="col-12 order-xl-1 mb-5 " ref={sectionRef}>

                            {/*  <h2 className="text-30 text-center py-4">{textos?.contacto?.hablemos || '¡Hablemos!'}</h2> */}
                            <div className="d-none d-lg-flex  justify-content-center align-items-center mb-5">
                            <Icon
                                icon="ic:round-double-arrow"
                                width={90}
                                className="dial-icon secondary-inverse--color"
                            />
                            </div>

                            <TabPanel.Box currentPanelId={currentTabPanel} className="pb-4">
                                <TabPanel.Tabs className={"d-flex justify-content-center px-sm-3 mb-3"} >
                                    <TabPanel.Tab tabPanel={tabPanelIds.cita} >
                                        <div className="col-6 px-0 mx-sm-2">
                                            <button
                                                className={`btn-contacto ${currentTabPanel === tabPanelIds.cita ? "activeMode" : "inactiveMode"}`}
                                                onClick={() => {
                                                    const urlActual = tabPanelIds.cita;
                                                    tabPanelNavigateTo(urlActual)
                                                }}
                                            >
                                                {isMobile ? textos?.botones?.agendar_r || 'Agenda' : textos?.botones?.agendar || 'Agenda tu cita ya'}
                                                <Icon icon="solar:alt-arrow-right-outline" width="24" height="24" className="icon"/>
                                            </button>
                                        </div>

                                    </TabPanel.Tab>
                                    <TabPanel.Tab tabPanel={tabPanelIds.demo}>
                                        <div className={"col-6 px-0 mx-sm-2"}>
                                            <button
                                                className={`btn-contacto ${currentTabPanel === tabPanelIds.demo ? "activeMode" : "inactiveMode"}`}
                                                onClick={() => {
                                                    const urlActual = tabPanelIds.demo;
                                                    tabPanelNavigateTo(urlActual)
                                                }}
                                            >
                                                {isMobile ? textos?.botones?.informacion_r || 'Información' : textos?.botones?.informacion || 'Pide más información'}
                                                <Icon icon="solar:alt-arrow-right-outline" width="24" height="24" className="icon"/>
                                            </button>
                                        </div>

                                    </TabPanel.Tab>
                                </TabPanel.Tabs>
                                <TabPanel.Panel
                                    panelId={tabPanelIds.cita}
                                    className={'p-4 hablemos'}
                                >
                                    <ContactCita />
                                </TabPanel.Panel>
                                <TabPanel.Panel
                                    panelId={tabPanelIds.demo}
                                    className={'p-4 hablemos'}
                                >
                                    <ContactDemoForm />
                                </TabPanel.Panel>
                            </TabPanel.Box>

                        </div>

                        <div className="col-12 order-xl-0">
                            <Plans />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}