import React from "react";
import {  Img } from "../../components/shared/img";
import { ASSETS_URL } from "../../components/Store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation } from "swiper";
import { memo } from "react";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { useRef } from "react";

/**
 * Componente de colección.
 * @param {Object} props - Props del componente.
 * @param {string} props.url - URL de la imagen de la colección.
 * @param {Function} [props.onClick] - Función de controlador de clic opcional.
 * @param {string} [props.className] - Clases CSS adicionales para el componente.
 * @param {Object} [props.style] - Estilos CSS adicionales para el componente.
 * @param {Object} props.scrollPosition - Posición de desplazamiento de la ventana.
 * @returns {JSX.Element} Elemento JSX que representa la colección.
 */
export const Collection = memo(({ url, onClick, className, style, scrollPosition }) => {
    return (
        <Img
            src={url}
            onClick={() => onClick && onClick()}
            className={className}
            wrapperClassName={className}
            style={style}
            scrollPosition={scrollPosition}
            width={300}
            height={300}  
            defaultImage={'/img/no-imagen2.png'}
        />
    );
});

/**
 * Componente de sección de colecciones.
 * @param {Object} props - Props del componente.
 * @param {Array} props.collections - Colecciones para mostrar.
 * @param {Object} props.scrollPosition - Posición de desplazamiento de la ventana.
 * @param {Object} props.navigationButtons - Referencias a los botones de navegación.
 * @param {number} props.autoPlayDelay - Retraso de reproducción automática de las colecciones.
 * @returns {JSX.Element} Elemento JSX que representa la sección de colecciones.
 */
export const CollectionSection =trackWindowScroll( memo(({ collections, scrollPosition, navigationButtons, autoPlayDelay }) => {
    const swiperRef = useRef(null);
    collections = Array.isArray(collections) ? collections : [];

    const updateNavigation = (swiper)=>{
        swiper.params.navigation.prevEl = navigationButtons?.prevButtonRef?.current
        swiper.params.navigation.nextEl = navigationButtons?.nextButtonRef?.current
        swiper.navigation.destroy()
        swiper.navigation.init()
        swiper.navigation.update()
    }

    return (
        <section>
            <div className="relative">
                <Swiper
                    className="focusable-secondary pb-5"
                    slidesPerView={'1'}
                    spaceBetween={40}
                    ref={swiperRef}
                    // loop
                    breakpoints={{
                        500: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                            slidesPerGroup: 2,
                        },
                        992: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 40,
                        },
                    }}
                    /* autoplay={{
                        delay: autoPlayDelay || 5000,
                        disableOnInteraction: false,
                    }} */
                    mousewheel={{
                        forceToAxis: true,
                    }}
                    navigation={{
                        nextEl:navigationButtons?.nextButtonRef?.current,
                        prevEl:navigationButtons?.prevButtonRef?.current,
                    }}
                    onSwiper={(swiper) => {
                        updateNavigation(swiper);
                    }}
                    modules={[/* Autoplay, */ Navigation, Mousewheel]}
                >
                    {collections.map((collection, i) => {
                        return (
                            <SwiperSlide
                                className="focusable-by-children-secondary "
                                key={`collection${i}`}
                            >
                                <a
                                    key={i}
                                    href={collection?.link || ""}
                                    aria-label={collection?.link}
                                    target={collection?.link?.includes("http") ? "_blank" : "_self"}
                                >
                                    <Collection
                                        key={i}
                                        url={`${ASSETS_URL}${collection?.url}`}
                                        className={'w-100 h-100 p-2 home-collection'}
                                        scrollPosition={scrollPosition}
                                    />
                                </a>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </section>
    );
}));