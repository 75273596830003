import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { Titles } from "../../../components/Titles";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

import { Helmet } from "react-helmet";

/**
 * Componente de titulo principal para que es colegios
 * @returns {JSX.Element} Componente TitlePage.
 */
export const TitlePage = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
	const textoColegios = useTranslations({ section: "que_es_colegios", lang: lang });

	return (
		<>
			<Helmet>
				<title>{textoColegios?.metadata?.title || 'MakeMake - Plan colegios'}</title>
				<meta name="description" content={textoColegios?.metadata?.description || 'MakeMake ofrece en plan colegios un sistema de lectura personalizado que permite configurar salones de clase y cuentas individuales para docentes y estudiantes, con los mejores libros en español para chicos'} />
			</Helmet>
			<Titles
				hexBackground="#82559E"
				color="onsecondary--color"
				title={textoColegios?.miga_de_pan?.colegios || "Colegios"}
			/>
		</>
	);
};
