export const DETAILS_PLANS = {
    "bibliotecas": {
        class: "plan-bibliotecas",
        image: {
            url:"/img/contacto/contacto-plan-bibliotecas.png",
            alt: "Plataforma para bibliotecas en portátil celular y tablet"
        },
        title: {
            default: 'Plan Bibliotecas',
            translationPath: 'bibliotecas.titulo'
        },
        features: [
            {
                type: "text",
                field: "lecturas",
                value: {
                    default: 'Préstamos diarios',
                    translationPath: 'bibliotecas.lecturas'
                }
            },
            {
                type: "text",
                field: "libros",
                value: {
                    default: 'Desde 200 en adelante',
                    translationPath: 'bibliotecas.libros'
                }
            },
            {
                type: "text",
                field: "reportes",
                block: true,
                value: {
                    default: 'Por Institución',
                    translationPath: 'bibliotecas.reportes'
                }
            },
            {
                type: "check",
                field: "guias",
                value: true,
            },
            {
                type: "check",
                field: "clubes_tareas",
                block: true,
                value: false
            },
            {
                type: "check",
                field: "eventos_libros",
                value: true
            },
            {
                type: "check",
                field: "perfil",
                value: false
            },
            {
                type: "text",
                field: "autenticacion",
                value: {
                    default: 'Dirección IP, EZ Proxy, Intranet, Formulario especializado',
                    translationPath: 'bibliotecas.autenticacion'
                }
            }
        ]
    },
    "colegios": {
        class: "plan-colegios",
        image: {
            url: "/img/contacto/contacto-plan-lector.png",
            alt: "Plataforma para colegios en diferentes pantallas"
        },
        title: {
            default: 'Plan Colegios',
            translationPath: 'colegios.titulo'
        },
        features: [
            {
                type: "text",
                field: "lecturas",
                value: {
                    default: 'Ilimitadas',
                    translationPath: 'colegios.lecturas'
                }
            },
            {
                type: "text",
                field: "libros",
                value: {
                    default: 'Desde 20 en adelante',
                    translationPath: 'colegios.libros'
                }
            },
            {
                type: "text",
                field: "reportes",
                block: true,
                value: {
                    default: 'Por alumno, curso, colegio y red de colegios',
                    translationPath: 'colegios.reportes'
                }
            },
            {
                type: "check",
                field: "guias",
                value: true,
            },
            {
                type: "check",
                block: true,
                field: "clubes_tareas",
                value: true
            },
            {
                type: "check",
                field: "eventos_libros",
                value: true,
                detail: {
                    default: '+ 1 Evento virtual personalizado',
                    translationPath: 'colegios.eventos_libros'
                }
            },
            {
                type: "check",
                field: "perfil",
                value: true
            },
            {
                type: "text",
                field: "autenticacion",
                value: {
                    default: 'GSuite, ClassLink, Clever, Microsoft 365',
                    translationPath: 'colegios.autenticacion'
                }
            },
        ]
    }

}