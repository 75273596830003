
import React, { useRef, useEffect } from "react";

import { useQueryParams } from "../../hooks/useQueryParams";
import useIsMobile from "../../js/utilities/useIsMobile";

import { Hero } from "./components/hero"
import { Clients } from "./components/clients"
import { Community } from "./components/community"
import { Newsletter } from "./components/newsletter"
import { MorePlans } from "./components/more-plans"
import { Awards } from "./components/awards"
import { PlansHome } from "./components/plansHome";
import { Action } from "../components/action";


/**
 * Componente principal que funciona como que es?
 * @returns Home
 */
export const Home = () => {
    const params = useQueryParams();
    const isMobile = useIsMobile();

    const refMainPlans = useRef(null);

    const SECCIONES_HOME = [
        {
            id: "MainPlans",
            ref: refMainPlans,
        }
    ];

    useEffect(() => {
        /**
            dependiendo de la ruta del navegador si coincide con alguna url 
            de las secciones, entonces muestra la sección respectiva
        */
        SECCIONES_HOME.map(item => {
            const section = params?.get("section");

            if (section === item.id) {
                const element = item?.ref.current;

                window.scrollTo({
                    top: isMobile ? element?.offsetTop + 100 : element?.offsetTop + 300,
                    behavior: "smooth",
                });
            }
        })
    }, [params]);

    return (<div className="que-es min-vh-100">
        <Hero />
        <Community />
        <PlansHome ref={refMainPlans}/>
        <Clients /> 
        <Action />
        <MorePlans />
        <Newsletter />
        {!isMobile && <Awards />}
    </div>)
}