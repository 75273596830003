import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Mousewheel, Navigation } from "swiper";
import { Img } from "../../../components/shared/img";
import { NavigationButtonsBooks } from "../../../libros/components/navigation-buttons";
import { ALL_INSTITUTIONS_IMAGES } from "../../../shared/data/institutions-data";

/**
 * Componente swiper de colegios en responsive
 * @returns {JSX.Element} Componente SwiperSchoolsResponsive
 */
export const SwiperSchoolsResponsive = () => {
    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const schools =ALL_INSTITUTIONS_IMAGES;

    const updateNavigation = (swiper) => {
        swiper.params.navigation.prevEl = prevButtonRef?.current
        swiper.params.navigation.nextEl = nextButtonRef?.current
        swiper.navigation.destroy()
        swiper.navigation.init()
        swiper.navigation.update()
    }

    return (
        <>
            <Swiper
                className="px-5"
                style={{ overflowX: "hidden" }}
                slidesPerView={"auto"}
                freeMode={true}
                direction="horizontal"
                mousewheel={{
                    forceToAxis: false,
                }}
                navigation={{
                    nextEl: nextButtonRef?.current,
                    prevEl: prevButtonRef?.current,
                }}
                onSwiper={(swiper) => {
                    updateNavigation(swiper);
                }}
                modules={[Navigation, Mousewheel, FreeMode]}
            >
                {schools.map((client, i) => {
                    return (
                        <SwiperSlide
                            key={i}
                            className="d-flex justify-content-center align-items-center mx-2 p-0"
                            style={{
                                minWidth: 200,
                                maxWidth: 200,
                                height: "auto",
                                pointerEvents: "none",
                            }}
                        >
                            <Img
                                key={i}
                                style={{ minWidth: 200, maxWidth: 200, height: "auto" }}
                                src={client?.src}
                                alt="client-image"
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="container my-4">
                <NavigationButtonsBooks nextButtonRef={nextButtonRef} prevButtonRef={prevButtonRef} responsiveButtons={true} />
            </div>
        </>
    )
}