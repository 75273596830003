import { useContext, useEffect } from "react";
import { store } from "../../components/Store";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { InstitutionalAPI } from "../../services/api/institutional";
import { useState } from "react";


/**
 * Hook que se encarga de obtener la lista de instituciones desde la API y
 * guardarla en el estado global. El hook devuelve un objeto con dos propiedades:
 * `data` que contiene la lista de instituciones y `loading` que indica el estado
 * de carga de la lista de instituciones.
 * @returns {{data: Array, loading: boolean}} Un objeto con la lista de
 *          instituciones y su estado de carga.
 */
export const useInstitutionData = () => {
	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const institutions = appState?.institutionalAuth?.institutions;
	const fetchCompleted = appState?.institutionalAuth?.fetchCompleted;
	const [loading, setLoading] = useState(true);



	const fetchInstitutions = async ({ abortSignal }) => {
		setLoading(true);
		await InstitutionalAPI.getAllInstitutions({ abortSignal: abortSignal })
			.then(result => {
				const institutions = Array.isArray(result) ? result.filter(i => i?.sedes?.length > 0) : [];
				storeContext.dispatch({
					type: 'CHANGE',
					path: 'institutionalAuth.institutions',
					value: institutions
				})
				storeContext.dispatch({
					type: 'CHANGE',
					path: 'institutionalAuth.fetchCompleted',
					value: true
				})
			})
			.catch(error => console.log(error))
			.finally(() => {
				setLoading(false);
			})
	}

	useEffect(() => {
		let abortController = new CancelRequestController();
		if (fetchCompleted !== true) {
			fetchInstitutions({ abortSignal: abortController.signal });
		}
		if (fetchCompleted) {
			setLoading(false);
		}
		return () => {
			abortController.abort();
		}
	}, [fetchCompleted])


	return {
		data: Array.isArray(institutions) ? institutions : [],
		loading: loading,
	};
};
