import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

import { Img } from "../../components/shared/img";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Retorna el input de -¿Diriges una editorial y buscas un nuevo espacio ...- en el home
 * @param {object} textLogin contiene los textos de interfaz
 * @returns {JSX.Element} NewStories
 */
export const NewStories = ({textLogin}) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textos = useTranslations({ section: "home", lang: lang });


    return (
        <div className="secondary--bg onsecondary--color">
            <div className="container">
                <div className="row justify-content-center mx-0">
                    <div className="col-lg-8 d-flex justify-content-center align-items-center py-3">
                        <div>
                            <h2 className="secondary-font mb-3">{textLogin?.editorial_titulo || '¿Diriges una editorial y buscas un nuevo espacio para distribuir tus libros?'}</h2>
                            <p>{textLogin?.editorial_parrafo || 'En MakeMake representamos más de 75 editoriales con los mejores libros de América Latina'}</p>
                                <Link 
                                to={'/contacto'}
                                className="btn btn-primary p-2 px-3 d-block col-sm-5 mx-auto my-3 focusable-primary"
                            >
                               {textos?.sin_login?.btn_contacto || 'Contáctanos'}
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-3 d-flex justify-content-center align-items-end">
                        <div>
                            <Img src="/img/visor-mas.png"/>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}