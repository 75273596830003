import React, { createContext, useReducer } from 'react';
import { openDB } from 'idb';
import _ from 'lodash';

const makemakeData = JSON.parse(window.localStorage.getItem('makemakeData'));
const GENERAL_URL_API = process.env.NODE_ENV === 'development' ? 'https://rc.api.makemake.reallabs.com.co' : process.env.REACT_APP_API;
const STREAMER_URL_API = process.env.REACT_APP_API === 'https://api.makemake.com.co' ? 'https://streamer.makemake.com.co' : 'https://streamer.reallabs.com.co';
//const GENERAL_URL_API = process.env.NODE_ENV === 'development' ? 'https://api.makemake.com.co' : process.env.REACT_APP_API;
const ASSETS_URL=process.env.NODE_ENV === 'development' ? 'https://rc.makemake.reallabs.com.co' : '/makemake';
const initialState = {
  idioma: 'es',
  pais: (makemakeData) ? makemakeData.pais : 'CO',
  linkAcceso:(makemakeData)?makemakeData.linkAcceso : null,
  mainDataLoaded: false,
  sesionActiva: (makemakeData) ? makemakeData.sesionActiva : null,
  userType: (makemakeData) ? makemakeData.userType : null,
  username: (makemakeData) ? makemakeData.username : null,
  tipo: (makemakeData) ? makemakeData.tipo : null,
  cantidadLibros: -1,
  datosHeader: (makemakeData) ? makemakeData.datosHeader : null,
  isOnline: undefined,
  descargando: false,
  errorDescargandoLibro:false,
  idLibroDescargando: "",
  tituloDescargando: "",
  progresoDescarga: "0%",
  abortCtrl: null,
  abortSignal: null,
  categoriasMenu: JSON.parse(window.localStorage.getItem('categoriasMenu')) || null,
  fichasLibros:[], // almacenamiento de las fichas que ya se han visto, estoy evita tener que realizar nuevamente la petición para obtener los datos.
  userBooksData:{
    books:undefined,
    fetching:false,
    loading:true,
    loadCompleted:false
  },
  advancedSearch:{
    triggerButtonRef:null,
    visible:false,
    loadingContent:true
  },
  accessibility:{
    triggerButtonRef:null,
    visible:false,
  },
  publicHeaderDropdown:{
    triggerButtonRef:null,
    visible:false,
  },
  generalHeaderDropdown:{
    triggerButtonRef:null,
    visible:false,
  },
  individualHeaderDropdown:{
    triggerButtonRef:null,
    visible:false,
  },
  homeData:{
    default:{
      inStore:false,
      fetching:false,
      loaded:false,
      data:{}
    },
    individual:{
      inStore:false,
      fetching:false,
      loaded:false,
      data:{}
    }

  },
  visor:{
    mobileAppMode: false
  },
  	/**
	 * Objeto que contiene toda la información necesaria para los institutos.
	 */
	institutionalAuth: {
		institutions:[], // listado de las instituciones donde internamente tiene sus sedes (bibliotecas).
    fetchCompleted:false,
		isSelectedLibrary: false,
		selectedLibrary: {
			instituto: {
				nombre: "Instituto de prueba",
				correo: "example@example.com",
				linkTutorial: "https://www.youtube.com",
				tipoAcceso: undefined,
				linkAcceso: "https://institutoprueba.com",
				estadoActivacion: "activo",
				estadoSubscripcion: "vigente",
			},
			nombreSede: "Biblioteca de ejemplo",
			ciudad: "Bogotá",
			pais: "Colombia",
			direccion: "dirección de prueba",
		},
	},
  // lista de todas las editoriales de makemake
  publishers:{
    data:[],
    fetching:false,
    loaded:false
  },
  // Buzón de notificaciones
  notifications:{
    visibleInbox:false,
    loaded:false,
    data:[],
    newNotifications:false,
  },
  // Banner de notificaciones
  notibanner:{
    visible:false,
    loaded:false,
    data:undefined
  },
  contactRequest:{
    visible:false,
  },
};
const librosMaxDescargados = 4;
const diasVencimientoDescarga = 15;

const storeName = 'books';
const db = openDB('MakeMakeBooks', 1, {
  upgrade(db) {
    const store = db.createObjectStore(storeName, { keyPath: 'url' });
    store.createIndex("idLibro", "idLibro", { unique: false });
    store.createIndex("tipo", "tipo", { unique: false });
    store.createIndex("fecha", "fecha", { unique: false });

  },
});

async function getDB(key) {
  return (await db).get(storeName, key);
}

async function setDB(value) {
  (await db).put(storeName, value);
}

async function getAllFromIndexDB(key, keyValue) {
  return (await db).getAllFromIndex(storeName, key, keyValue);
}
async function deleteDB(key) {
  return (await db).delete(storeName, key);
}
async function clearDB() {
  return (await db).clear(storeName);
}
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'CHANGE_VALUE':
        let newState = state;
        //cambiar valor
        newState[`${action.property}`] = action.value;
        return { ...state, ...newState }
      case 'CHANGE':
        const _newState = {...state};
        _.set(_newState, action.path, action.value)
        return _newState;

      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
export { store, StateProvider, GENERAL_URL_API, ASSETS_URL, STREAMER_URL_API, librosMaxDescargados, diasVencimientoDescarga, getDB, setDB, getAllFromIndexDB, deleteDB, clearDB}
