import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { store } from "../../Store";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { Icon } from "@iconify/react";
import useIsMobile from "../../../js/utilities/useIsMobile";

/**
 * Componente que renderiza una sección de enlaces en el pie de página.
 * @param {string} title - El título de la sección.
 * @param {object[]} linksData - Un array de objetos que contienen la información sobre los enlaces a mostrar.
 * @returns {JSX.Element} El componente renderizado.
 */
export const LinksSection = ({title, linksData}) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "footer", lang: lang });
	const globalStateContext = useContext(store);
	const state = globalStateContext?.state;
	const isAuth = state?.sesionActiva === "SI";
	const isMobile = useIsMobile();

	useEffect(() => {
		if (isMobile === false) {
			setExpanded(true);
		} else {
			setExpanded(false);
		}
	}, [isMobile]);

	const [expanded, setExpanded] = useState(false);

	const links = Array.isArray(linksData) ? linksData : [];

	const handleMobileExpandedState = (_expanded) => {
		if (isMobile) {
			setExpanded(_expanded);
		}
	};

	return (
		<div className="w-100">
			{
				isMobile ?
			<button
				className="w-100 section-subtitle d-flex align-items-center justify-content-between  p-2"
				onClick={() => handleMobileExpandedState(!expanded)}
			>
				<span className="">{title}</span>

				<Icon
					className={`dropdown-arrow-transition ${isMobile === false && "d-none"} ${
						expanded ? "expanded-dropdown" : "collapsed-dropdown"
					}`}
					icon="fe:arrow-down"
					width={"1.5em"}
				/>
			</button>
				:
				<p className="w-100 section-subtitle d-flex align-items-center justify-content-between  p-2">{title}</p>

			}
			{expanded ? (
				<ul className="mv-2 footer-links pl-5">
					{links?.map((link, i) => {
						return (
							<li key={i} className="">
								{link.external ? (
									<a
										className="link font-weight-bold focusable-primary"
										key={i}
										href={link?.link || ""}
										target="_blank"
										rel="noopener noreferrer"
									>
										{_.get(texts, link?.text?.translationPath) ||
											link?.text?.default}
									</a>
								) : (
									<Link
										className="link font-weight-bold focusable-primary"
										key={i}
										to={link?.byAuth === true ? link.link(isAuth) : link.link}
									>
										{_.get(texts, link?.text?.translationPath) ||
											link?.text?.default}
									</Link>
								)}
							</li>
						);
					})}
				</ul>
			) : null}
		</div>
	);
};
