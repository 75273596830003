import React, { useContext} from "react";
import { MORE_LINKS } from "../data/links";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { store } from "../../Store";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { LinksSection } from "./links-section";

/**
 * Renders a component with a links list from MORE_LINKS constant.
 * This list of links is located in the footer of the app.
 *
 * @returns {React.ReactElement} The MoreLinks component.
 */
export const MoreLinks = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "footer", lang: lang });
	const globalStateContext = useContext(store);
	const state = globalStateContext?.state;

	return (
		<LinksSection title={_.get(texts, "sobre_makemake.titulo") || 'Más sobre MakeMake'} linksData={MORE_LINKS}/>
	);
};
