export const AWARDS = [
	{
		imgUrl: "/img/awards/cross-media.png",
		url: "/",
	},
	{
		imgUrl: "/img/awards/lapiz-acero.png",
		url: "/",
	},
	{
		imgUrl: "/img/awards/tech.png",
		url: "/",
	},
];
