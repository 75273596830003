import React from "react";
import * as _ from "lodash";
import useIsMobile from "../../../js/utilities/useIsMobile";
import { Img } from "../../shared/img";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente que devuelve las caracteristicas de un plan
 * @returns {JSX.Element} Plans
 */
export const FeaturesBox = ({ data }) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "planes", lang: lang });
    const isMobile = useIsMobile();

    return (
        <div className={`plan ${data?.class}`}>
            <div className={`plan-titulo ${data?.class}`}>
                <h4>{_.get(textos, data?.title?.translationPath) || data?.title?.default || "N/A"} </h4>
            </div>
            {isMobile &&
                <div> <Img src={data?.image?.url} alt={data?.image?.alt || "sin descripción"} /> </div>

            }

            <ul className="p-3">
                {data?.features.map((item, index) => {
                    return (
                        <li key={`${data?.title?.default}-item-${index}`} className={`${item?.block === true ? "bloque-estadistico" : ""}`}>
                            {item?.type === "text" &&
                                <p className="text-left text-md-center onbackground-primary--color">
                                    <span className="field">
                                        {textos?.tabla?.[item?.field] || 'N/A'}:
                                    </span>
                                    {_.get(textos, item?.value?.translationPath) || item?.value?.default || "N/A"}
                                </p>
                            }

                            {item?.type === "check" &&
                                <p className="text-left text-md-center onbackground-primary--color">
                                    {item?.value === true ?
                                        <Icon className={`check-one ${item?.detail ? "mr-2" : "mr-2 mr-xl-0"} `} icon="icon-park-solid:check-one" width="25" height="25" />
                                        :
                                        <Icon className="close-solid mr-2 mr-xl-0" icon="zondicons:close-solid" width="25" height="25" />
                                    }

                                    {item?.detail ?
                                        <span>{_.get(textos, item?.detail?.translationPath) || item?.detail?.default || "N/A"}</span>
                                        :
                                        <span className="feature">{textos?.tabla?.[item?.field] || 'N/A'}</span>
                                    }


                                </p>
                            }
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}