import React from "react";
import { Link } from "react-router-dom";
import { useTranslations } from "../../../hooks/use-translations";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

import { Helmet } from "react-helmet";

export const Hero = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const hero = textos?.hero_dos;

	return (
		<div className="hero p-3 primary--bg d-md-flex justify-content-around primary-inverse--bordered">
			<Helmet>
				<title>{textos?.metadata?.title || 'MakeMake - Biblioteca Digital'}</title>
				<meta name="description" content={textos?.metadata?.description || 'Con la Biblioteca Digital MakeMake accede a una cuidadosa selección de libros digitales de +80 editoriales de América Latina y el mundo de gran reconocimiento. Diseñada para expandir los servicios bibliotecarios, impactar la lectura en colegios y promover el libro en familia.'} />
			</Helmet>
			<div className="d-none d-lg-flex justify-content-center align-items-center">
				<img src="/img/que-es/home/hero/purplehead.png" alt="imagen" />
			</div>

			<div className="d-flex d-lg-none justify-content-center align-items-center">
				<img src="/img/que-es/home/hero/personaje-logo.png" alt="imagen" />
			</div>
			<div className="d-flex flex-column justify-content-center align-items-center text-center px-md-4 main-content-hero">
				<img src="/img/logos/logo-mkmk.png" alt="logo makemake" className="my-3 d-none d-lg-block" style={{ width: 190 }} />
				<h1
					className="secondary-font mb-3 onprimary--color"
					style={{ lineHeight: "1.3em", fontWeight: "600" }}
					dangerouslySetInnerHTML={{ __html: hero?.titulo || "Lee los mejores libros <br/>para niños y jóvenes<br/> en tu biblioteca y colegio" }}
				></h1>
				<div className="w-100 row align-items-stretch">
					<div className="col-md-6 ">
						<Link className="secondary-button-red btn-hero my-2 my-lg-3 focusable-red" to={"/contacto"}>
							{hero?.botones?.hablemos || "¡Hablemos!"}
						</Link>
					</div>
					<div className="col-md-6 ">
						<Link className="secondary-button-red btn-hero my-2 my-lg-3 focusable-red" to={"/libros"}>
							{hero?.botones?.coleccion || "Conoce la colección"}
						</Link>
					</div>
				</div>

			</div>
			<div className="d-none d-lg-flex flex-column justify-content-center align-items-center text-center">
				<img className="mb-4" src="/img/que-es/home/hero/blueman.png" alt="imagen" />
				<img src="/img/que-es/home/hero/matilda.png" alt="imagen" />
			</div>
		</div>
	);
};
