import React from "react";
import { PRODUCTS_LINKS } from "../data/links";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { LinksSection } from "./links-section";

/**
 * Renders a component with a links list from PRODUCTS_LINKS constant.
 * This list of links is located in the footer of the app.
 *
 * @returns {React.ReactElement} The ProductsLinks component.
 */

export const ProductsLinks = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "footer", lang: lang });
	const links = [...PRODUCTS_LINKS];
	return (
		<LinksSection
			title={_.get(texts, "conoce_productos.titulo") || "Conoce nuestros productos"}
			linksData={links}
		/>
	);
};
