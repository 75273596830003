
export const SPONSORS = [
   {
        imgUrl:'/img/apoyoDe/innpulsa-logo-header.svg',
        url:'https://www.innpulsacolombia.com/'
    }, 
   {
        imgUrl:'/img/apoyoDe/logo-colombia-productiva.svg',
        url:'https://www.colombiaproductiva.com/'
    }, 
//    {
//         imgUrl:'/img/apoyoDe/logo-economia-naranja.png',
//         url:'/'
//     }, 
] 