import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { SelectedInstitution } from "./selected-institution";
import { useContext, useState } from "react";
import { store } from "../../components/Store";
import { Icon } from "@iconify/react";
import { BackButtonHeader } from "./back-button";
import { CredentialsAuthForm } from "./credentials-form";
import { toast } from "react-toastify";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
// import { toast } from "react-toastify";

/**
 * Componente que carga la información del instituto que se seelccionó en el paso de búsqueda de institutos, se encarga de autenticar el usuario con base a los distintos tipos de autenticación.
 * @returns {JSX.Element} Componente de autenticación institucional.
 */
export const InstitutionalAuth = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const history = useHistory();
	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const selectedLibrary = appState?.institutionalAuth?.selectedLibrary;
	const isSelectedLibrary = appState?.institutionalAuth?.isSelectedLibrary;
	const accessType = selectedLibrary?.instituto?.tipoAcceso;
	const accessLink = selectedLibrary?.instituto?.linkAcceso;
	const [loading, setLoading] = useState(false);
	const [showExternalAuthWarning, setShowExternalAuthWarning] = useState(false);

	const removeSelectedInstitution = () => {
		history.replace("/institutional-auth/search");
	};

	/**
	 * Función que maneja los diferentes tipos de autenticación ( excepto para el tipo de acceso 'contraseña')
	 */
	const handleAuth = async () => {
		const _accessType = accessType?.toLowerCase();
		if (loading) return;
		setLoading(true);
		if (_accessType === null || _accessType === undefined || _accessType?.trim()?.length <= 0) {
			toast.error(
				textos?.iniciar_sesion?.acceso?.notificacion?.error_invalido ||
					`El tipo de acceso es invalido.`
			);
			setLoading(false);
			return;
		}
		if (
			// Esta función no debe ser usada por el tipo de acceso de 'contraseña'
			_accessType !== "contraseña" 
		) {
			setShowExternalAuthWarning(true);
			let link = accessLink;
			window.open(link, "_blank");
		} else {
			toast.error(
				textos?.iniciar_sesion?.acceso?.notificacion?.error_no_disponible ||
					`Lo sentimos, por ahora tu tipo de acceso a MakeMake no está disponible desde esta sección.`
			);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isSelectedLibrary !== true) {
			history.replace("/institutional-auth/search");
		}
	}, [isSelectedLibrary]);

	return (
		<div className="institutional-auth">
			<div className="bg-shape"></div>
			<div className="container page-content w-100 h-100 d-flex flex-column align-items-center ">
				<BackButtonHeader />
				<h1 className="onsecondary-title my-4 text-center">
					{textos?.iniciar_sesion?.titulo || "Iniciar sesión"}
				</h1>
				<div className="m-2">
					<img
						className="inst-auth-avatar"
						src={"/img/avatares/avatar31.png"}
						width={30}
						height={30}
						alt={"avatar"}
					/>
				</div>
				<div className="d-flex my-4 justify-content-center w-100">
					<SelectedInstitution
						institutionData={{
							name: selectedLibrary?.nombreSede,
							city: selectedLibrary?.ciudad,
							country: selectedLibrary?.pais,
						}}
						removable={true}
						onRemove={() => {
							removeSelectedInstitution();
						}}
					/>
				</div>
				{showExternalAuthWarning && (
					<p
						className="onsecondary--bg secondary--color p-2"
						style={{ maxWidth: 600, fontWeight: 700, borderRadius: ".5rem" }}
					>
						{textos?.iniciar_sesion?.autenticacion_externa?.instruccion ||
							`Hemos abierto en una nueva pestaña el sitio web donde debes
						iniciar sesión. Una vez hayas accedido, serás redirigido a MakeMake.`}
					</p>
				)}
				{selectedLibrary?.instituto?.estadoSubscripcion !== "vigente" && (
					<div className="d-flex flex-column container" style={{ maxWidth: 600 }}>
						<div className="row d-flex flex-column justify-content-center">
							<p className="error-alert">
								{textos?.iniciar_sesion?.suscripcion_caducada?.notificacion ||
									"¡Lo sentimos! La suscripción de tu institución ha vencido."}
							</p>
							<p className="onsecondary--color text-center py-2">
								{textos?.iniciar_sesion?.suscripcion_caducada?.comunicacion ||
									"Comunícate con ventas para renovar el periodo de la suscripción o escribenos a"}{" "}
								<a
									className="onsecondary-link"
									href="mailto:contacto@makemake.com.co"
								>
									contacto@makemake.com.co
								</a>
							</p>
						</div>
					</div>
				)}
				{selectedLibrary?.instituto?.estadoSubscripcion === "vigente" && (
					<>
						{selectedLibrary?.instituto?.tipoAcceso?.toLowerCase() === "contraseña" ? (
							<>
								<CredentialsAuthForm />
							</>
						) : (
							<>
								<button
									className="primary-button my-4  px-5 w-100"
									style={{ maxWidth: 250 }}
									onClick={() => handleAuth()}
									disabled={loading}
								>
									{loading ? (
										<Icon
											icon="fa:spinner"
											width={30}
											className={`spinner-rotation`}
										/>
									) : (
										textos?.iniciar_sesion?.autenticacion_externa
											?.boton_entrar || "Entrar"
									)}
								</button>
							</>
						)}
						<Link
							className="onsecondary-link pb-3 focusable-primary text-center"
							to="/institutional-auth/contact/form"
						>
							{textos?.iniciar_sesion?.links?.no_puedo_ingresar ||
								"¡Ayuda! no puedo ingresar"}
						</Link>
					</>
				)}
			</div>
			
		</div>
	);
};
