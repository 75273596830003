import { useDownloadedBooksManager } from "./use-downloaded-books-manager";
import { useContext, useMemo } from "react";
import { store, librosMaxDescargados, setDB, GENERAL_URL_API } from "../../components/Store";
import { VisorAPI } from "../../services/api/visor";

/**
 * Hook para descargar un libro desde Visor API y guardar los datos en indexedDB,
 * incluyendo la portada y metadatos del libro.
 *
 * @param {Object} props
 * @param {Function} props.onSuccess - Función a ejecutar cuando el libro se descarga exitosamente.
 * @param {Function} props.onError - Función a ejecutar cuando ocurre un error al descargar el libro.
 * @param {Object} props.book - Libro que se va a descargar.
 * @returns {Object} Un objeto con una propiedad `download` que es una función que
 * inicia el proceso de descarga del libro.
 */
export const useBookDownloader = ({ onSuccess,onError, book }) => {
	const { dispatch, state } = useContext(store);
	const downloadedBooksManager = useDownloadedBooksManager();
	const downloadedBooks = downloadedBooksManager?.books;
	const downloadedBooksLength = downloadedBooks?.length;
	const metadata = useMemo(() => book?.metadata, [book.metadata]);
	const bookId = book?.id;
	/**
	 * Función que define si el libro puede ser descargado
	 * @param {Object} book
	 */
	const downloadManager = async () => {
		if (!book?.id) return;
		if (downloadedBooksLength < librosMaxDescargados) {
			dispatch({
				type: "CHANGE_VALUE",
				property: "errorDescargandoLibro",
				value: false,
			});
			dispatch({ type: "CHANGE_VALUE", property: "descargando", value: true });
			dispatch({
				type: "CHANGE_VALUE",
				property: "idLibroDescargando",
				value: book?.idLibro,
			});
			dispatch({
				type: "CHANGE_VALUE",
				property: "tituloDescargando",
				value: metadata.titulo,
			});
			dispatch({
				type: "CHANGE_VALUE",
				property: "progresoDescarga",
				value: "0%",
			});
			const visorBook = await getBookFromVisorAPI(bookId);
			downloadBook(visorBook);
		}
	};

	/**
	 * Función que retorna los datos del libro con todos sus archivos
	 * @param {string} bookdId
	 * @returns {Promise<Libro>}
	 */
	const getBookFromVisorAPI = async (bookdId) => {
		return await VisorAPI.getBookData({ abortSignal: null, data: { bookId: bookdId } });
	};

	/**
	 * Función que descarga el libro y guarda la infomación en el globalState
	 * @param {Object} book
	 */
	const downloadBook = async (book) => {
		book.Archivos = book.Archivos.map((file) => {
			return "/makemake" + file;
		});
		/* console.log('book to download', book) */

		let contador = 0;
		let totalArchivos = Array.isArray(book.Archivos) ? book.Archivos.length : 0;

		// Guardar los assets del libro

		Promise.all(
			book?.Archivos.map((url, i) => {
				return fetch(url, { signal: state.abortSignal, mode: "cors" }).then((resp) => {
					return resp
						.clone()
						.blob()
						.then(async (result) => {
							let data = {
								url: url,
								idLibro: book?.idLibro,
								tipo: "contenido",
								fecha: new Date(),
								blob: result,
							};
							if (url.endsWith("index.html")) {
								let strippedUrl = url.substring(0, url.length - 10);
								await setDB({ ...data, url: strippedUrl });
							}
							if (url.endsWith("index.htm")) {
								let strippedUrl = url.substring(0, url.length - 9);
								await setDB({ ...data, url: strippedUrl });
							}
							if (url.endsWith("index.xhtml")) {
								let strippedUrl = url.substring(0, url.length - 9);
								await setDB({ ...data, url: strippedUrl });
							}

							return setDB(data).then(() => {
								contador++;
								let porcentaje = Math.round((100 * contador) / totalArchivos);
								dispatch({
									type: "CHANGE_VALUE",
									property: "progresoDescarga",
									value: porcentaje + "%",
								});
							});
						});
				});
			})
		)
			.then((results) => {
				// guardar metadatos y portada  del libro para visualizar en la sección de descargados.
				let metadatosEntry = {
					url: `${GENERAL_URL_API}/assets/visor?idLibro=${book?.idLibro}`,
					idLibro: book?.idLibro,
					tipo: "metadatos",
					fecha: new Date(),
					username: state.username,
					metadatos: book,
				};
				setDB(metadatosEntry)
					.then(async (r) => {
						let portadaUrl = `/makemake${book.portada}`;
						await fetch(portadaUrl, { signal: state.abortSignal })
							.then(function (portadaResponse) {
								let respClone = portadaResponse.clone();
								respClone.blob().then((blob) => {
									let data = {
										url: `/makemake${book.portada}`,
										idLibro: book?.idLibro,
										tipo: "portada",
										fecha: new Date(),
										blob: blob,
									};
									setDB(data).then((r) => {
										/* console.log('Book downloaded successfully!'); */
										onSuccess && onSuccess();
										dispatch({
											type: "CHANGE_VALUE",
											property: "errorDescargandoLibro",
											value: false,
										});
									});
								});
							})
							.catch((error) => console.log("Error downloading book cover: ", error));
					})
					.catch((error) => console.log(error));
			})
			.catch((error) => {
				dispatch({
					type: "CHANGE_VALUE",
					property: "errorDescargandoLibro",
					value: true,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "progresoDescarga",
					value: "0%",
				});
				onError && onError(error);
				console.log("Book downloading error: ", error);
			});
	};

	return {
		download: downloadManager,
	};
};
