import React, { useState, useEffect } from "react";
import { CommunityTitle } from "../../../components/community/community-title";
import { CommunityMonthSelector } from "../../../components/community/month-selector";
import { MAKEMAKE_LIVE_MONTHS } from "../../data/makemake-live";
import moment from "moment";
import { PastEvents } from "./components/past-events";
import { Events } from "./components/events";
import { useEvents } from "../../hooks/use-events";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { useTranslations } from "../../../hooks/use-translations";
import _ from "lodash";

export const MakeMakeLive = React.forwardRef((props, ref) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "comunidad_club", lang: lang });
	const [months, setMonths] = useState([]);
	const [defaultMonth, setDefaultMonth] = useState(null);

	const [currentMonth, setCurrentMonth] = useState(defaultMonth);
	const monthsEvents = useEvents({ month: currentMonth });
	const loadingEvents = monthsEvents?.loading;
	const events = monthsEvents.events;
	const allEvents = monthsEvents.allEvents;

	useEffect(() => {
		if (Array.isArray(allEvents)) {
			const _liveMonths = [...MAKEMAKE_LIVE_MONTHS].map((month) => {
				return {
					...month,
					name: _.get(texts, month?.name?.translationPath) || month?.name?.default,
					empty: !allEvents
						.map((e) => moment(e?.fecha).format("YYYY-MM"))
						.includes(moment(month?.date).format("YYYY-MM")),
				};
			});
			setDefaultMonth(
				_liveMonths.find(
					(month) =>
						moment().isSame(month?.date, "month") &&
						moment().isSame(month?.date, "year")
				)?.id || _liveMonths[0]?.id
			);
			setMonths(_liveMonths);
		}
	}, [allEvents]);

	return (
		<div className="makemake-live w-100 my-5">
			<div className="container">
				<div ref={ref} style={{ scrollMarginTop: 80 }}>
					<CommunityTitle.H1
						title={_.get(texts, "makemake_en_vivo.titulo") || `MakeMake en vivo`}
					/>
				</div>
				<CommunityMonthSelector
					months={months}
					defaultMonthId={defaultMonth || months[0]?.id}
					onSelect={(month) => {
						setCurrentMonth(month);
					}}
				/>
			</div>
			<Events loading={loadingEvents} events={events} lang={lang} />
			<PastEvents />
			<div className="container  my-5 d-flex justify-content-center align-items-center">
				<a
					className="focusable-red btn btn-primary p-2 px-3"
					href="https://www.youtube.com/channel/UCAGRlTJB3fUDRfkVRPVNdzQ"
					target="_blank"
					rel="noopener noreferrer"
				>
					{_.get(texts, "makemake_en_vivo.destacados.boton.explorar_canal") ||
						`Explora nuestro canal de Youtube`}
				</a>
			</div>
		</div>
	);
});
