import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard } from "swiper";

import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente swiper de comentarios/experiencia
 * @returns {JSX.Element} Componente SwiperComment
 */
export const SwiperComment = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textosBibliotecas = useTranslations({ section: "que_es_biblioteca", lang: lang });

    /**
         * Esta función crea un arreglo con varios SwiperSlide. Cada uno de estos contiene un comentario
         * @returns {Array<HTMLElement>} arreglo de SwiperSlide
         */
    const crearSlide = () => {
        let items = [];
        const comentarios = textosBibliotecas?.comentarios || [];

        Object.keys(comentarios).map((item, index) => {
            if (comentarios[item].comentario !== "null") {
                items.push(
                    <SwiperSlide
                        className={`swiper-slide focusable-secondary comments`}
                        key={`slide-${index}`}
                        modules={[Autoplay, Keyboard]}
                        style={{ fontSize: "0.9em" }}
                    >
                        <div
                            tabIndex={0}
                            className={
                                "focusable-secondary  experience-libraries py-4 mb-5 mt-3 mx-auto"
                            }
                        >
                            <p className="d-block col-11 mx-auto text-center">
                                {comentarios[item].comentario}
                            </p>
                        </div>
                        <p
                            tabIndex={0}
                            className="focusable-secondary onbackground-primary--color col-lg-10 mx-auto text-center"
                        >
                            <span className="font-weight-bold">{comentarios[item].nombre} </span>{" "}
                            {comentarios[item].cargo !== "null" && comentarios[item].cargo}{" "}
                        </p>
                        <p
                            tabIndex={0}
                            className="focusable-secondary onbackground-primary--color col-lg-10 mx-auto text-center font-weight-bold"
                        >
                            {" "}
                            {comentarios[item].lugar}
                        </p>
                    </SwiperSlide>
                );
            }
        });
        return items;
    };

    return (
        <Swiper
            autoplay={{
                delay: 3000,
            }}
            breakpoints={{
                526: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                992: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                1400: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
            }}
            className="swiper-editoriales"
            loop={true}
            modules={[Autoplay, Keyboard]}
            preloadImages={true}
            slidesPerView={1}
            style={{ height: 400 }}
        >
            {crearSlide()}
        </Swiper>
    )
}