import React from "react";
import { BaseModal } from "../../components/modals/base-modal";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

/**
 * Muestra un modal de error de autenticación de la página de login.
 * @param {object} props - Propiedades del componente
 * @param {boolean} props.visible - Indica si el modal es visible o no.
 * @param {function} props.setVisible - Funci n para controlar la visibilidad del modal.
 * @returns {JSX.Element} - Elemento JSX que representa el modal.
 */
export const AuthErrorPopup = ({ visible, setVisible }) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "login", lang: lang });
	const close = () => {
		setVisible && setVisible(false);
	};

	return (
		<BaseModal
			visible={visible}
			setVisible={setVisible}
			backdropClose={true}
			overlayClassName={"auth-error-modal-overlay"}
			windowClassName={"auth-error-modal-window"}
		>
			<div className="w-100 flex-grow-1 d-flex flex-column p-3">
				<div className="w-100 d-flex justify-content-end">
					<button className="focusable-red  close-button" onClick={close}>
						<Icon width={30} icon="fa:close" />
					</button>
				</div>
				<h1
					className="mb-3 text-32 font-weight-bold secondary-font text-center red-inverse--color"
					style={{ letterSpacing: ".1em" }}
				>
					{texts?.login?.auth_error_popup?.titulo || "Usuario o contraseña incorrectos"}
				</h1>
				<p className="text-24 onbackground-primary--color text-center">
					{texts?.login?.auth_error_popup?.sugerencia ||
						"Intenta de nuevo o inicia sesión con la cuenta de tu institución"}{" "}
					<Link className="font-weight-bold" to="/institutional-auth/search">
						{texts?.login?.auth_error_popup?.sugerencia_link || "acá"}
					</Link>
				</p>
				<p className="mt-4 text-24 onbackground-primary--color text-center">
					{texts?.login?.auth_error_popup?.cta ||
						"¿Quieres MakeMake en tu colegio o biblioteca?"}{" "}
					<Link className="font-weight-bold" to="/contacto">
						{texts?.login?.auth_error_popup?.cta_link || "¡Hablemos!"}
					</Link>
				</p>
			</div>
		</BaseModal>
	);
};
