import React, { useContext } from "react";
import { store } from "../Store.js";
import { ClientFooter } from "./components/client-footer.js";
import { SocialMedia } from "./components/social-media.js";
import { Logos } from "./components/logos.js";
import { MoreLinks } from "./components/more-links.js";
import { ContactList } from "./components/contact.js";
import { Sponsors } from "./components/sponsors.js";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country.js";
import { useTranslations } from "../../hooks/use-translations.js";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants.js";
import { Awards } from "./components/awards.js";
import { Link } from "react-router-dom";
import { DownloadAppSection } from "./components/download_app.js";
import { ProductsLinks } from "./components/products-links.js";
import _ from "lodash";

/**
 * Componente de pie de página.
 * @returns {JSX.Element} El elemento del pie de página.
 */
const Footer = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "footer", lang: lang });
	const globalStateContext = useContext(store);
	const state = globalStateContext?.state;
	const isAuth = state?.sesionActiva !== null && state?.sesionActiva === "SI";
	const footerData = state?.datosHeader || {};

	const adminSignInLink = () => {
		return (
				<Link
					to={"/admin/login"}
					className="d-flex mt-2 mb-1 mt-lg-3 mb-lg-0 align-items-center justify-content-center  text-center p-2 third-inverse--bg onsecondary-inverse--color font-weight-bold text-uppercase"
					style={{ borderRadius: ".5rem", fontSize: ".8em"}}
				>
					{lang === "es" ? "Iniciar sesión como administrador" : "Admin Login"}
				</Link>
		);
	};
	return (
		<div className="">
			{isAuth && <ClientFooter clientData={footerData} />}
			<div className="w-100" style={{ backgroundColor: "#000000" }}>
				<footer className="footer footer-container">
					<div className="section1">
						<div className="p-0 p-lg-3">
							<div className="pr-3">
								<div className="divider d-none d-lg-block"></div>
							</div>
							<h2 className="section-title">
								<span className="text">{_.get(texts, "representantes.titulo") || "Representaciones comerciales"}</span>
								<div className="divider d-block d-lg-none"></div>
							</h2>
							<ContactList />
						</div>
					</div>
					<div className="section2">
						<div className="w-100 d-flex d-lg-none flex-column justify-content-center align-items-center">
							<SocialMedia />
							<div className="col-12 d-flex justify-content-center align-items-center p-0">{adminSignInLink()}</div>
							<DownloadAppSection />
							<div className="px-3 w-100">
								<div className="divider my-3"></div>
							</div>
							<ProductsLinks />
							<MoreLinks />
						</div>
						<div className="row p-3 m-0 d-none d-lg-flex">
							<div className="col-12 d-flex justify-content-end align-items-center p-0">{adminSignInLink()}</div>
							<div className="col-6">
								<ProductsLinks />
							</div>
							<div className="col-6">
								<DownloadAppSection />
							</div>
							<div className="col-6">
								<MoreLinks />
							</div>
							<div className="col-6">
								<SocialMedia />
							</div>
						</div>
					</div>
					<div className="section3">
						<div className="divider d-none d-lg-block"></div>
						<div className="d-flex flex-column justify-content-center align-items-center flex-lg-row justify-content-lg-between">
							<div className="w-100 d-flex flex-column">
								<h2 className="section-title">
									<span className="text">{_.get(texts, "premios.titulo") || "Premios"}</span>
									<div className="divider d-block d-lg-none"></div>
								</h2>
								<div className="row row-md-none p-0 m-0 align-items-center">
									<div className="col-12 col-lg-auto p-0">
										<Awards />
									</div>
									<div className="col-12 col-lg-auto p-0">
										<Sponsors />
									</div>
								</div>
							</div>
							<Logos />
						</div>
						{/* espaciado de 100px al final de todo para dispositivo movil */}
						<div className="w-100 d-block d-lg-none" style={{ height: 100 }}></div>
					</div>
				</footer>
			</div>
		</div>
	);
};

export default Footer;
