import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import MicroModal from "micromodal";
import ReCAPTCHA from "react-google-recaptcha";

import departamentos from "./Departamentos";
import { DatosFormInstitutoAPI } from "../services/api/LoginInstitucional";
import { CancelRequestController } from "../util/cancel-request-controller";
import ModalAlertas from "../components/ModalAlertas";
import PropTypes from "prop-types";
import { useQueryParams } from "../hooks/useQueryParams";
import getUrlParam from "../js/utilities/getUrl";

/**
 * Este componente se encarga de renderizar el formulario de acceso institucional de tipo Form. Su función principal es validar los campos y redireccionar el inicio de sesión. Este hace uso del componente ModalAlertas.
 */
const Form = (props) => {
  const { instituto, traducciones } = props;

  const { institucional, login } = traducciones;
  const { form } = institucional;

  const [preloader, setPreloader] = useState(false);
  const [notificacionNombre, setNotificacionNombre] = useState(false);
  const [notificacionCedula, setNotificacionCedula] = useState(false);
  const [notificacionDepartamento, setNotificacionDepartamento] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [captchaValido, setCaptchaValido] = useState(null);
  const [mostrarMensajeCaptcha, setMostrarMensajeCaptcha] = useState(false);
  const [isDevelopment, setIsdevelopment] = useState(false);
  const location = useLocation();
  const params = useQueryParams();
  const inst = getUrlParam('inst').toString();
  const redirect = params.get('redirectto');
  const institutoConDocumento = "comfacauca";

  const refForm = useRef(null);

  const nombreRef = useRef(null);
  const cedulaRef = useRef(null);
  const departamentoRef = useRef(null);
  const captcha = useRef(null);

  useEffect(()=>{
    const origin = window?.location?.origin;
    const development = origin === 'https://makemake.com.co' ?   false : true;
    setIsdevelopment(development)
  },[])

  /**
   * Esta función se encarga de validar los campos del formulario y enviar los datos para luego redireccionar.
   * @param {Event} e 
   */
  const enviarDatosAcceso = (e) => {
    e.preventDefault();

    nombreRef.current.addEventListener("invalid", () => { setNotificacionNombre(true) });
    cedulaRef.current.addEventListener("invalid", () => { setNotificacionCedula(true) });
    departamentoRef.current.addEventListener("invalid", () => { setNotificacionDepartamento(true) });

    if (refForm.current.reportValidity()) {
      if (isDevelopment === false) {
        if (captchaValido === true) {
          enviarDatos()
        } else {
          setMostrarMensajeCaptcha(true)
        }
      } else {
        enviarDatos()
      }

    }
  }

  const enviarDatos = async () => {
    setPreloader(true)
    setMostrarMensajeCaptcha(false)
    let abortController = new CancelRequestController();

    let tipoIngreso = '';
    if (location.pathname === '/institucional/formulario' && params.get('access') === 'app') {
      tipoIngreso = 'app';
    }
    const propiedades = {
      inst: instituto,
      nombre: nombreRef.current.value,
      cedula: cedulaRef.current.value,
      departamento: departamentoRef.current.value,
      tipoIngreso: tipoIngreso,
      redirect
    }

    await DatosFormInstitutoAPI.enviarDatos({
      abortSignal: abortController.signal,
      data: propiedades
    }).then(respuesta => {
      const error = respuesta.status;

      if (error === 0) {
        setPreloader(false)
        if (mostrarModal) {
          MicroModal.show('modal-form-institucional', {
            awaitCloseAnimation: true
          });
        } else {
          setMostrarModal(true)
        }
        MicroModal.show('modal-form-institucional', {
          awaitCloseAnimation: true
        });
      } else {
        setPreloader(true)
        window.location.href = window.location.origin + respuesta.info;
      }

    }).catch(error => console.log(error));

    return () => {
      abortController.abort();
    }
  }

  /**
   * Esta función cambia el estado de setCaptchaValido en caso de que el captcha sea correcto
   */
  const onChange = () => {
    if (captcha.current.getValue()) {
      setCaptchaValido(true)
    }
  }

  return (
    <>
      <div className="col-lg-9 mx-auto pt-3 pb-5">
        <h2 className="mt-3 mb-4 text-center pb-3">{form?.titulo || 'Ingresa tus datos'}</h2>

        <form
          className="row mx-0 my-3 align-items-end"
          ref={refForm}
        >
          {/* nombre y apellido */}
          <div className="col-lg-6 mb-3 mb-lg-0">
            <p className="secondary-item">
              <strong>{form?.nombre?.label || 'Nombre y apellido*:'}</strong>
            </p>
            <p className="onbackground-third--color mm-redimir-terms">
              {form?.nombre?.descripcion || 'Escríbelos como aparecen en tu identificación'}
            </p>
            <label htmlFor="nombreYapellido" className="sr-only" aria-hidden={true}>
              {form?.nombre?.label || 'Nombre y apellido*:'}
            </label>
            <input
              type="text"
              ref={nombreRef}
              className="form-control focusable-secondary"
              id="nombreYapellido"
              placeholder={form?.nombre?.placeholder || "Escribe tu nombre y apellido aquí"}
              required
              onChange={() => setNotificacionNombre(false)}
            />
            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionNombre ? form?.nombre?.alert || 'Debes escribir tu nombre y apellido' : ''}
            </span>
          </div>

          {/* cédula */}
          <div className="col-lg-6 mb-3 mb-lg-0">
            <p className="secondary-item">
              <strong>
                {inst === institutoConDocumento ?
                  form?.cedula?.label_doc || 'Documento de identificación*:' 
                  : 
                  form?.cedula?.label || 'Número de cédula*:'
                }
              </strong>
            </p>
            <label htmlFor="cedula" className="sr-only" aria-hidden={true}>
              {inst !== institutoConDocumento ?
                form?.cedula?.label_doc || 'Documento de identificación*:' 
                : 
                form?.cedula?.label || 'Número de cédula*:'
              }
            </label>
            <input
              type="number"
              ref={cedulaRef}
              className="form-control focusable-secondary"
              id="cedula"
              placeholder={
                inst === institutoConDocumento ? 
                  form?.cedula?.placeholder_doc || "Escribe tu número de identificación" 
                  : 
                  form?.cedula?.placeholder || "Escribe tu número de cédula"
              }
              required
              onChange={() => setNotificacionCedula(false)}
            />
            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionCedula ? 
                inst === institutoConDocumento ? 
                  form?.cedula?.alert_doc || "Debes escribir tu número de identificación" 
                  :
                  form?.cedula?.alert || 'Debes escribir tu número de cédula' 
                : ''
              }
            </span>
          </div>

          {/* departamento */}
          <div className="col-lg-6 mb-3 mb-lg-0 mt-lg-4">
            <p className="secondary-item">
              <strong>
              {
                inst === institutoConDocumento ? 
                  form?.departamento?.label_doc || 'Departamento* (Departamento de expedición de documento):' 
                  : 
                  form?.departamento?.label || 'Departamento* (Departamento de expedición de cédula):'
              }</strong>
            </p>
            <label htmlFor="departamento" className="sr-only" aria-hidden={true}>
              { inst === institutoConDocumento ? 
                form?.departamento?.label_doc || 'Departamento* (Departamento de expedición de documento):' 
                : 
                form?.departamento?.label || 'Departamento* (Departamento de expedición de cédula):'
              }
            </label>
            <select
              className="form-control focusable-secondary"
              id="departamento"
              required
              ref={departamentoRef}
              onChange={() => setNotificacionDepartamento(false)}
            >
              <option hidden value="">
                {form?.departamento?.placeholder || 'Escoge tu departamento'}
              </option>
              {departamentos.map(item => {
                return (
                  <option key={item} value={item}>{item}</option>
                )
              })}
            </select>
            <span role={"alert"} aria-live="assertive" className="notificacion-form">
              {notificacionDepartamento ? form?.departamento?.alert || 'Por favor, escoge un departamento' : ''}
            </span>
          </div>

          <p className="my-5 my-lg-0 p-2 p-lg-5 text-center mm-redimir-terms">
            {form?.terminos?.ingresar || 'Al ingresar tus datos estás aceptando'}
            <Link
              className="links-tyc secondary-item focusable-red"
              to="/terminos-y-condiciones"
            >
              {" "}{form?.terminos?.term_condiciones || 'Términos y condiciones'}
            </Link>
            {" "}{form?.terminos?.y || 'y'} {" "}
            <Link
              className="links-tyc secondary-item focusable-red"
              to="/politica-de-privacidad"
            >
              {form?.terminos?.politica || 'Política de privacidad'}
            </Link>
          </p>

          {isDevelopment === false &&
            <div className="w-100 d-flex justify-content-center">
              <ReCAPTCHA
                ref={captcha}
                sitekey="6LfaebskAAAAAF4jRB9WbC3pOOgmAaPZnOf-kn5-"
                onChange={onChange}
              />
            </div>
          }
          {mostrarMensajeCaptcha &&
            <span role={"alert"} aria-live="assertive" className="notificacion-form d-block w-100 mt-3 text-center">{form?.captcha || 'Por favor acepta el captcha'}</span>
          }

          <button
            className={`btn col-6 col-lg-4 d-block mx-auto focusable-red mt-5 ${preloader ? 'btn-disabled' : 'btn-primary'}`}
            type="submit"
            onClick={(e) => enviarDatosAcceso(e)}
            disabled={preloader ? true : false}
          >
            {preloader ?
              <i className="fas fa-spinner fa-pulse"></i>
              :
              login?.botones?.enviar || "Enviar"
            }
          </button>

        </form>
      </div>

      {mostrarModal &&
        <ModalAlertas
          id={"modal-form-institucional"}
          alertType={form?.modal?.titulo || 'Alerta'}
          message={form?.modal?.mensaje || 'Error de acceso'}
          description={
            inst === institutoConDocumento ? 
              form?.modal?.descripcion_doc ||'El nombre ingresado no coincide con el del documento' 
              : 
              form?.modal?.descripcion || 'El nombre ingresado no coincide con el de la cédula'
          }
          boton={login?.botones?.aceptar || "Aceptar"} />
      }
    </>
  )
}

export default Form;

Form.propTypes = {
  /**
   * String del nombre del instituto. Este valor se usa para los datos requeridos del api assets/formulario/loginFormulario
   */
  instituto: PropTypes.string.isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  traducciones: PropTypes.object.isRequired,
}