import React from "react";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { useTranslations } from "../../../hooks/use-translations";


/**
 * Component that renders the social media icons
 * @returns {ReactElement} Component that renders the social media icons
 */
export const SocialMedia = () => {
  const iconSize = 30;
  const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
  const texts = useTranslations({ section: "footer", lang: lang });
  return (
    <div>
      <h3 className="section-subtitle d-none d-lg-block">{_.get(texts, "canales.titulo") || "Canales"}</h3>
      <section className="d-flex align-items-center justify-content-center justify-content-lg-start p-0 m-0">
    <ul className="row footer-icons justify-content-center justify-content-xl-start m-0 mt-2">
      {/* <li>
        <a className="focusable-secondary mr-3" aria-label="App Store link" href="https://apps.apple.com/us/app/makemake-app/id6469707768?platform=iphone" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button appstore" icon="fluent:app-store-24-filled" width={iconSize}/>
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Play Store link" href="https://play.google.com/store/apps/details?id=com.makemake.app&pli=1" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button playstore" icon="ion:logo-google-playstore" width={iconSize} />
        </a>
      </li> */}
      <li>
        <a className="focusable-secondary mr-3" aria-label="email link" href="mailto:contacto@makemake.com.co" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button email" icon="material-symbols-light:mail" width={iconSize +5} />
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Youtube link" href="https://chat.whatsapp.com/BFdd39DjpHPJYN5BxmUzWh" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button whastapp" icon="ic:baseline-whatsapp" width={iconSize} />
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Instagram link" href="https://www.instagram.com/makemakelibros" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button instagram" icon="mdi:instagram" width={iconSize} />
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Facebook link" href="https://www.facebook.com/Bookmakemake/" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button facebook" icon="ic:baseline-facebook" width={iconSize} />
        </a>
      </li>
      <li>
        <a className="focusable-secondary mr-3" aria-label="Youtube link" href="https://www.youtube.com/channel/UCAGRlTJB3fUDRfkVRPVNdzQ" target="_blank" rel="noopener noreferrer" >
          <Icon className="social-media-button youtube" icon="bi:youtube" width={iconSize} />
        </a>
      </li>
    </ul>
  </section>
    </div>
 )
}