import React, { useRef, useState, useContext, useEffect, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Icon } from "@iconify/react";
import MicroModal from "micromodal";

import postAjax from "../js/utilities/postAjax";
import getAjax from "../js/utilities/getAjax";
import getUrlParam from "../js/utilities/getUrl";

import { GENERAL_URL_API, store } from "../components/Store";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import { useQueryParams } from "../hooks/useQueryParams";
import ModalAlertas from "../components/ModalAlertas";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";
import { Helmet } from "react-helmet";
import Hotjar from "@hotjar/browser";
import { BasicTooltip } from "../components/header/basic-tooltip";
import { AuthErrorPopup } from "./components/auth-error-popup";

/**
 * Este se encarga de renderizar la pantalla de login. Sus principales funciones son validar la información de formulario de Inicio de sesión y actualizar la información de la sesión activa en el localStorage.
 * @returns Login
 */
export const Login = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const texts = useTranslations({ section: "login", lang: lang });

	const globalState = useContext(store);
	const { dispatch, state } = globalState;

	const [messageLogin, setMessageLogin] = useState("");
	const [sendingLogin, setSendingLogin] = useState(false);
	const [message, setMessage] = useState("");
	const [description, setDescription] = useState("");
	const [showAuthErrorPopup, setShowAuthErrorPopup] = useState(false);

	const loginForm = useRef(null);
	const userName = useRef(null);
	const password = useRef(null);

	const history = useHistory();
	const params = useQueryParams();
	const subscriptionParam = useMemo(() => params.get("subscriptionT"), [params]);

	const institutionTooltipRef = useRef();
	const loginTooltipRef = useRef();

	useEffect(() => {
		window.scroll({ top: 0 });
	}, []);

	useEffect(() => {
		enviarUrl();
	}, [state?.sesionActiva]);

	useEffect(() => {
		if (subscriptionParam && subscriptionParam === "failed") {
			showExpiredSuscription();
		}
	}, [subscriptionParam]);

	useEffect(() => {
		let ezproxy = "";
		if (typeof getUrlParam("ezproxy") !== "undefined") {
			ezproxy = decodeURIComponent(getUrlParam("ezproxy")).replace(/\+/g, " ");
			if (ezproxy !== "") {
				postAjax(`${GENERAL_URL_API}/assets/ezLogin`, { ip: ezproxy }, (response) => {
					if (response.includes("Exito")) {
						getAjax(`${GENERAL_URL_API}/assets/datosHeader`, {}, (response) => {
							let makemakeData = JSON.parse(response).makemakeData;
							let categoriasMenu = JSON.parse(response).categoriasMenu;

							window.localStorage.setItem(
								"makemakeData",
								JSON.stringify(makemakeData)
							);
							window.localStorage.setItem(
								"categoriasMenu",
								JSON.stringify(categoriasMenu)
							);
							dispatch({
								type: "CHANGE_VALUE",
								property: "pais",
								value: makemakeData.pais,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "sesionActiva",
								value: makemakeData.sesionActiva,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "userType",
								value: makemakeData.userType,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "username",
								value: makemakeData.username,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "datosHeader",
								value: makemakeData.datosHeader,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "categoriasMenu",
								value: categoriasMenu,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "tipo",
								value: makemakeData.tipo,
							});
							enviarUrl();
						});
					}
				});
			}
		}
	}, []);

	const enviarUrl = () => {
		const redirectUrl = params.get("redirectto");
		const bookUrl = params.get("bookT");
		if (state?.sesionActiva === "SI") {
			if (redirectUrl) {
				history.push(redirectUrl);
			} else if (params.has("bookT")) {
				history.push(bookUrl);
			} else {
				history.push("/home");
				window.location.reload(true);
			}
		}
	};

	const showExpiredSuscription = () => {
		try {
			MicroModal.show("modal-alertas-autenticacion", {
				awaitCloseAnimation: true,
			});
		} catch (error) {
			console.log("modal alertas error", error);
		}

		setMessage(texts?.mensaje_alerta?.caducado?.mensaje || "Tu suscripción ha caducado");
		setDescription(
			texts?.mensaje_alerta?.caducado?.descripcion ||
				"Comunícate con ventas para renovar el período de la suscripción o escríbenos a contacto@makemake.com.co"
		);
	};

	const iniciarSesion = (e) => {
		if (loginForm.current.checkValidity()) {
			e.preventDefault();
			setSendingLogin(true);
			postAjax(
				`${GENERAL_URL_API}/assets/login`,
				{ username: userName.current.value, password: password.current.value },
				(response) => {
					if (response.includes("Exito")) {
						getAjax(`${GENERAL_URL_API}/assets/datosHeader`, {}, (response) => {
							let makemakeData = JSON.parse(response).makemakeData;
							let categoriasMenu = JSON.parse(response).categoriasMenu;

							window.localStorage.setItem(
								"makemakeData",
								JSON.stringify(makemakeData)
							);
							window.localStorage.setItem(
								"categoriasMenu",
								JSON.stringify(categoriasMenu)
							);
							dispatch({
								type: "CHANGE_VALUE",
								property: "pais",
								value: makemakeData.pais,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "sesionActiva",
								value: makemakeData.sesionActiva,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "userType",
								value: makemakeData.userType,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "username",
								value: makemakeData.username,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "datosHeader",
								value: makemakeData.datosHeader,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "categoriasMenu",
								value: categoriasMenu,
							});
							dispatch({
								type: "CHANGE_VALUE",
								property: "tipo",
								value: makemakeData?.tipo,
							});
							setSendingLogin(false);
						});
						enviarUrl();
						//history.push("/home");
						//Esto recarga el sitio
						//window.location.reload(true);
					} else {
						setSendingLogin(false);
						if (response.includes("Error, usuario o contraseña")) {
							setShowAuthErrorPopup(true);
							Hotjar.event("error_user_password");
							setMessageLogin(
								texts?.mensaje_alerta?.login ||
									"Datos incorrectos. Intenta ingresar con tu institución"
							);
						} else if (response.includes("Error, concurrencias copadas")) {
							Hotjar.event("error_concurrences");
							try {
								MicroModal.show("modal-alertas-autenticacion", {
									awaitCloseAnimation: true,
								});
							} catch (error) {
								console.log("modal alertas error", error);
							}
							setMessage(
								texts?.mensaje_alerta?.limite?.mensaje ||
									"Has superado el límite de concurrencias"
							);
							setDescription(
								texts?.mensaje_alerta?.limite?.descripcion ||
									"En este momento tienes más usuarios conectados de los que admite tu suscripción. Comunícate con ventas para aumentar la capacidad de usuarios simultáneos. Escríbenos a contacto@makemake.com.co"
							);
						} else if (response.includes("Error, suscripcion caducada")) {
							Hotjar.event("error_subscription_expired");
							showExpiredSuscription();
						} else {
							console.error(response);
						}
					}
				}
			);
		}
	};

	return (
		<div className="login">
			<Helmet>
				<title>{texts?.metadata?.title || "MakeMake - Iniciar sesión"}</title>
				<meta
					name="description"
					content={
						texts?.metadata?.description ||
						"Empieza a leer en MakeMake. Inicia sesión o busca tu intitución."
					}
				/>
			</Helmet>
			<div className="bg-shape"></div>
			<div className="wrapper-login">
				<div className="container content-login pb-3 position-relative">
					<h1 className="secondary-font onsecondary--color mb-3">
						{texts?.titulos?.iniciar || "Iniciar sesión"}
					</h1>

					<div className="row">
						<div className="col-12 col-lg-4 px-4 py-2 p-md-2 p-lg-2 p-xl-3">
							<div className="login-card d-flex flex-column">
								<Icon
									aria-hidden="false"
									aria-label="ayuda - iniciar sesión con instituto"
									className="help-icon"
									ref={institutionTooltipRef}
									icon="mingcute:question-fill"
									width="24"
									height="24"
								/>
								<BasicTooltip
									targetRef={institutionTooltipRef}
									textContent={
										texts?.login?.tooltip?.institucional || "Si tu colegio o biblioteca ya tiene MakeMake puedes leer gratis. Busca tu institución en la lista y sigue los pasos para ingresar con el usuario y clave que te dio tu institución."
									}
								/>

								<div className="flex-grow-1 d-flex flex-column align-items-center justify-content-around my-3 my-lg-0">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="80"
										height="80"
										viewBox="0 0 24 24"
										className="d-none d-md-flex"
									>
										<path
											fill="currentColor"
											d="M2 19h20v3H2zM12 2L2 6v2h20V6zm5 8h3v7h-3zm-6.5 0h3v7h-3zM4 10h3v7H4z"
										/>
									</svg>
									<p className="description text-center">
										{texts?.login?.login_institucional?.descripcion || "Inicia sesión con tu cuenta institucional"}
										
									</p>
									<Link
										to="/institutional-auth/search"
										className="primary-button-var2 focusable-red"
									>
										{texts?.login?.botones?.buscar_institucion || "Busca tu institución"}
									</Link>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-4 px-4 py-2 p-md-2 p-lg-2 p-xl-3">
							<div className="login-card d-flex flex-column">
								<Icon
									aria-hidden="false"
									aria-label="ayuda - iniciar sesión con usuario"
									className="help-icon"
									ref={loginTooltipRef}
									icon="mingcute:question-fill"
									width="24"
									height="24"
								/>
								<BasicTooltip
									targetRef={loginTooltipRef}
									textContent={
										texts?.login?.tooltip?.cuenta_personal || "Usa esta opción únicamente si tu usuario y contraseña fueron proporcionados por el equipo de MakeMake."
									}
								/>

								<div className="flex-grow-1 d-flex flex-column align-items-center justify-content-around my-3 my-lg-0">
									{/* <svg
										width="75"
										height="70"
										viewBox="0 0 65 60"
                                        className="mt-2"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M32.5 36C50.44 36 65 42.28 65 50V60H0V50C0 42.28 14.56 36 32.5 36ZM60.6667 50C60.6667 44.48 48.0567 40 32.5 40C16.9433 40 4.33333 44.48 4.33333 50V56H60.6667V50ZM32.5 0C36.5225 0 40.3802 1.475 43.2245 4.10051C46.0688 6.72602 47.6667 10.287 47.6667 14C47.6667 17.713 46.0688 21.274 43.2245 23.8995C40.3802 26.525 36.5225 28 32.5 28C28.4776 28 24.6199 26.525 21.7755 23.8995C18.9312 21.274 17.3333 17.713 17.3333 14C17.3333 10.287 18.9312 6.72602 21.7755 4.10051C24.6199 1.475 28.4776 0 32.5 0ZM32.5 4C29.6268 4 26.8713 5.05357 24.8397 6.92893C22.808 8.8043 21.6667 11.3478 21.6667 14C21.6667 16.6522 22.808 19.1957 24.8397 21.0711C26.8713 22.9464 29.6268 24 32.5 24C35.3732 24 38.1287 22.9464 40.1603 21.0711C42.192 19.1957 43.3333 16.6522 43.3333 14C43.3333 11.3478 42.192 8.8043 40.1603 6.92893C38.1287 5.05357 35.3732 4 32.5 4Z"
											fill="currentColor"
										/>
									</svg>

									<p
										className="description text-center mt-3 mt-lg-5"
										style={{ maxWidth: 240 }}
									>
										Accede con tu usuario y contraseña
									</p> */}
									<form
										ref={loginForm}
										className="w-100 d-flex flex-column justify-content-center align-items-center px-3 "
										id="loginctfId"
										name="loginctf"
										method="post"
									>
										<fieldset className="w-100 ">
											<label
												htmlFor="inputUser"
												className="mb-2 onbackground--primary font-weight-bold"
											>
												{texts?.input_usuario?.usuario?.texto ||
													"Nombre de usuario"}
											</label>
											<input
												ref={userName}
												id="inputUser"
												onClick={() => {
													setMessageLogin("");
												}}
												autoComplete="username"
												type="text"
												className="form-control focusable-red mb-2"
												placeholder={
													texts?.input_usuario?.usuario
														?.placeholder ||
													"Escribe tu nombre de usuario"
												}
												name="user"
												required
											/>
										</fieldset>

										<fieldset className="w-100 mt-3">
											<label
												htmlFor="inputPassword"
												className="mb-2 onbackground--primary font-weight-bold"
											>
												{texts?.input_usuario?.contrasenia?.texto ||
													"Contraseña:"}
											</label>
											<input
												ref={password}
												autoComplete="off"
												type="password"
												className="form-control focusable-red mb-2"
												id="inputPassword"
												placeholder={
													texts?.input_usuario?.contrasenia
														?.placeholder || "Escribe tu contraseña"
												}
												name="password"
												required
											/>
										</fieldset>

										
											<button
												disabled={sendingLogin}
												onClick={(e) => iniciarSesion(e)}
												className="primary-button-var2 focusable-red mt-3"
											>
												{(() => {
													if (sendingLogin) {
														return (
															<i className="fas fa-spinner fa-pulse"></i>
														);
													} else {
														return (
															texts?.login?.botones
																?.iniciar_sesion || "Iniciar sesión"
														);
													}
												})()}
											</button>
										
									</form>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-4 px-4 py-2 p-md-2 p-lg-2 p-xl-3">
							<div className="login-card">
								<div
									className="d-flex flex-column flex-grow-1 justify-content-around align-items-center my-3 my-lg-0"
								>
									<svg
										width="96"
										height="78"
										viewBox="0 0 66 48"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M3.90295 25.877H28.903C30.0075 25.877 30.903 26.7724 30.903 27.877V46.908H1.90295V27.877C1.90295 26.7724 2.79839 25.877 3.90295 25.877Z"
											stroke="currentColor"
											stroke-width="2"
										/>
										<path
											d="M1.90295 27.877L16.903 39.877L30.903 27.877"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M35.9029 30.6377L42.6451 1L64.0971 5.88006L57.3548 35.5178L35.9029 30.6377Z"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M46.7909 10.877L56.7909 12.877"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M45.2909 15.877L55.2909 17.877"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M44.2909 21.877L54.2909 23.877"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>

									<p className="description text-center">
										{texts?.login?.contacto?.descripcion || "Contáctanos para poderte ayudar"}
									</p>
									<div>
										<a
											href={"mailto:comercial@makemake.com.co"}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center text-break link mb-2 focusable-red"
										>
											<Icon
												icon="ic:baseline-email"
												width={22}
												className="mr-2"
											/>{" "}
											comercial@makemake.com.co
										</a>
										<a
											href={"https://www.youtube.com/watch?v=KGX4P3GZIIk&t=45s"}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center text-break link focusable-red"
										>
										<Icon
											icon="si:youtube-fill"
											width={22}
											className="mr-2"
										/>{" "}
											{texts?.login?.contacto?.video_ayuda || "¿Cómo acceder a MakeMake?"}
										</a>
										{/* <a
											href={"https://wa.link/aozzbk"}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center text-break link focusable-red"
										>
											<Icon
												icon="dashicons:whatsapp"
												width={22}
												className="mr-2"
											/>{" "}
											+57 350 2367037
										</a> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="my-4 pt-1 d-flex flex-column justify-content-center align-items-center">
						<p className="onsecondary--color font-weight-bold text-center text-24" >
							{texts?.login?.descargar_app?.titulo || "Descarga la aplicación"}
						</p>
						<div className="d-flex flex-column flex-md-row justify-content-center align-items-center my-2">
							<a
								href="https://apps.apple.com/us/app/makemake-app/id6469707768?platform=iphone"
								className="focusable-red"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Apple app store"
							>
								<img
									className="img-fluid m-2"
									style={{ maxWidth: 200 }}
									src="/img/makemake-app/apple-store-badget.png"
									alt="apple store"
								/>
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.makemake.app"
								className="focusable-red"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Play store"
							>
								<img
									className="img-fluid m-2"
									style={{ maxWidth: 200 }}
									src="/img/makemake-app/google-play-badget.png"
									alt="apple store"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>

            <AuthErrorPopup visible={showAuthErrorPopup} setVisible={setShowAuthErrorPopup}/>
			<ModalAlertas
				id={"modal-alertas-autenticacion"}
				message={message}
				description={description}
			/>
		</div>
	);
};
