import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { DATA_AWARDS } from "../data/awards";
import { Img } from "../../../components/shared/img";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import * as _ from "lodash";

/**
 * Componente de "Premios y reconocimientos" para que es principal
 * @returns {JSX.Element} Componente Awards.
 */
export const Awards = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const awards = [...DATA_AWARDS];

	return (
		<div className="awards surface--bg py-4 onbackground-primary--color">
			<div className="container p-3">
				<h1 className="text-center onprimary--color secondary-font">
					{textos?.premios?.titulo || "Premios a la excelencia de MakeMake"}
					
				</h1>

				<div className="row">
					{awards.map((item, index) => {
						return (
							<div className="col-12 col-sm-6 col-md-3" key={`award${index}`}>
								<div
									className="d-flex flex-column justify-content-center align-items-center"
									style={{ height: 180 }}
								>
									<Img
										className="img-fluid"
										src={item?.img}
										alt={item?.alt}
									/>
								</div>
								<p
									className="text-center onbackground-primary--color font-secondary font-weight-bold"
									style={{ fontSize: "0.9em" }}
								>
									{_.get(textos?.premios , item?.year?.translationPath) ||
										item?.year?.default ||
										"N/A"}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
