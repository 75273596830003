
import axios from "axios"
import { GENERAL_URL_API } from "../../components/Store";


/**
 * Este servicio devuelve las todas las editoriales 
 * @param {{abortSignal:AbortSignal, data:{}}} param0 
 * @returns {Promise<{
    "descripcion": String,
    "idEditoriales": String,
    "nombreEditorial":String,
    "linkEditorial": String,
    "logo": String,
}>} retorna un objeto con las editoriales
 */
const obtenerTodas = async ({abortSignal=null, data})=>{
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const response = await axios({
          method: 'GET',
          url: 'assets/editoriales',
          signal: abortSignal || null,
        })
        const datos = response.data;
        if (datos === 'Error'){
          return new Error(datos);
        }
        return  datos?.editoriales || [];
    
      } catch (error) {
        return Promise.reject(error);
      }
}

/**
 * Este servicio devuelve las editoriales filtradas para la sección de colección
 * @param {{abortSignal:AbortSignal, dataFiltro:[]}} dataFiltro - arreglo con las editoriales a filtrar
 * @returns {Promise<{
    "descripcion": String,
    "idEditoriales": String,
    "nombreEditorial":String,
    "linkEditorial": String,
    "logo": String,
}>} retorna un objeto con las editoriales
 */
const obtenerEditorialesColeccion = async ({ abortSignal = null, dataFiltro }) => {
  try {
    axios.defaults.baseURL = GENERAL_URL_API;
    axios.defaults.withCredentials = true;

    const response = await axios({
      method: 'GET',
      url: 'assets/editoriales',
      signal: abortSignal || null,
    })
    const datos = response.data;
    if (datos === 'Error') {
      return new Error(datos);
    } else {
      

      const filtra = (array, filtro) => {
        return array.filter(elem => filtro.includes(elem?.nombreEditorial));
      }

      return filtra(datos?.editoriales, dataFiltro) || []
    }

  } catch (error) {
    return Promise.reject(error);
  }
}

export const EditorialesAPI = {
  obtenerTodas,
  obtenerEditorialesColeccion
}