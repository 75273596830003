import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { useTranslations } from "../../../hooks/use-translations";
import _ from "lodash";

/**
 * Componente que renderiza la sección con los links de descarga de la app de MakeMake
 * en el footer de la página.
 * @returns {React.Component} Componente
 */
export const DownloadAppSection = () => {
		const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
		const texts = useTranslations({ section: "footer", lang: lang });
	return (
		<div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start justify-lg-content-start">
			<h3 className="section-subtitle text-center text-lg-left p-2">{_.get(texts, "descargar_apps.titulo") || "Descarga nuestras apps"}</h3>
			<div className="d-flex flex-column flex-md-row flex-lg-column align-items-center justify-content-center">
			<a
				href="https://apps.apple.com/us/app/makemake-app/id6469707768?platform=iphone"
				className="focusable-red"
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Apple app store"
			>
				<img
					className="img-fluid mx-2"
					style={{ maxWidth: 150 }}
					src="/img/makemake-app/apple-store-badget.png"
					alt="apple store"
				/>
			</a>
			<a
				href="https://play.google.com/store/apps/details?id=com.makemake.app"
				className="focusable-red"
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Play store"
			>
				<img
					className="img-fluid m-2"
					style={{ maxWidth: 150 }}
					src="/img/makemake-app/google-play-badget.png"
					alt="apple store"
				/>
			</a>
			<a
				href="https://makemake.app"
				className="focusable-red makemake-app-website-link row pr-3 py-2 mt-lg-0 mx-2 mb-lg-2 flex-nowrap align-items-center secondary-font font-weight-bold"
				target="_blank"
				rel="noopener noreferrer"
				aria-label="MakeMake app website"
			>
				<div className=" p-0 col">
				<img
					className=" mx-2 logo"
					src="/img/logos/logo192.png"
					alt="makemake app logo"
				/>
				</div>
				
				<span className=" p-0 col-9 text-center">
				{_.get(texts, "links.app_website") || "Más información"}
					<br />
				{_.get(texts, "links.app_website2") || "App MakeMake"}
					
				</span>
			</a>
			</div>
			
		</div>
	);
};
