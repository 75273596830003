import { useEffect } from "react";
import Hotjar from "@hotjar/browser";

/**
 * Hook que permite inicializar la conexión con hotjar y pasa los datos del usuario
 * @param {string} activeSession - indica si la sesión esta activa 
 * @param {string} institution - nombre de la institucion 
 * @param {string} type - tipo de cuenta
 * @param {string} userType - tipo de usuario
 * @param {string} userName - nombre de usuario
 */
export const useHotjar = ({ activeSession, institution, type, userType, userName }) => {
    useEffect(() => {
        //indica el ID del sitio asignado por hotjar
        const siteId = window.location.origin === "https://nf.app.makemake.reallabs.com.co" ||
        window.location.origin.includes("localhost")  ? 5286609 : 3689730;
        const hotjarVersion = 6;
        Hotjar.init(siteId, hotjarVersion);

        if (activeSession === "SI") {
            //evento que indica cuando la sesión esta ACTIVA
            Hotjar.event("active_session");

            //Crea los filtros de búsqueda en hotjar por institución y nombre de usuario
            Hotjar.identify(institution, {
                institution: institution ? institution : "null",
                type: type ? type : "null",
                userType: userType ? userType : "null",
                userName: userName ? userName : "null"
            })
        } else {
            //evento que indica cuando  la sesión esta INACTIVA
            Hotjar.event("inactive_session");
        }
    }, [activeSession])



}
