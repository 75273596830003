import React from "react";
import { Link } from "react-router-dom";
import { Img } from "./shared/img";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";

/**
 * Retorna la franja de FAQs y Contáctanos
 * @returns {JSX.Element} Faqs
 */
export const CallToAction = ({ text }) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "home", lang: lang });
    const faqsTexts = textos?.faqs;

    return (
        <div className="p-3 py-4 red--bg onred--color">
            <div className="container">
                <div className="d-md-flex justify-content-center">
                    <div
                        className="col-12 col-md-7 col-lg d-flex flex-column justify-content-center align-items-center"
                        style={{ maxWidth: 600 }}
                    >
                        <div>
                            <h1
                                className="text-center text-md-left secondary-font w-100 text-24"
                            >
                                {text ? text : "N/A"}
                            </h1>

                            <div className="py-3">
                                <Link
                                    className="inverse-primary-button py-2 px-3 my-4 mr-md-3 focusable-red d-block d-md-inline"
                                    to="/contacto"
                                >{faqsTexts?.contactanos || 'Contáctanos'}</Link>
                                {/* <Link
                                    className="inverse-primary-button py-2 px-3 focusable-red d-block d-md-inline"
                                    to="/ayuda"
                                >{faqsTexts?.fqs || 'FAQs'}</Link> */}
                            </div>
                        </div>

                    </div>
                    <div className="col d-none d-md-flex">
                        <Img
                            className="img-fluid accesible-image"
                            src="/img/que-es/home/demo/demo-running.png"
                            alt="newsletter-speaker"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}