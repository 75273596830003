
export const BOOKS_PREVIEW_SEARCH = [
    {
        type: 'gratis',
        value: 'gratis',
        text: {
            default: 'Libros gratuitos',
            translationPath: 'categorias.libros_gratuitos'
        }
    },
    {
        type: 'genero',
        value: 'Interactivo',
        text: {
            default: 'Interactivos',
            translationPath: 'categorias.interactivos'
        }
    },
    {
        type: 'genero',
        value: 'Cuento',
        text: {
            default: 'Cuentos',
            translationPath: 'categorias.cuentos'
        }
    },
    {
        type: 'genero',
        value: 'Cómic',
        text: {
            default: 'Cómics',
            translationPath: 'categorias.comic'
        }
    },
    {
        type: 'genero',
        value: 'Poesía',
        text: {
            default: 'Poesía',
            translationPath: 'categorias.poesia'
        }
    },
    {
        type: 'genero',
        value: 'Novela',
        text: {
            default: 'Novelas',
            translationPath: 'categorias.novelas'
        }
    },    
    {
        type: 'tema',
        value:  'Artes y manualidades',
        text: {
            default: 'Arte',
            translationPath: 'categorias.arte'
        }
    },
    {
        type: 'tema',
        value: 'Canción y poesía',
        text: {
            default: 'Música',
            translationPath: 'categorias.musica'
        }
    },
    {
        type: 'tema',
        value: 'Ciencias',
        text: {
            default: 'Ciencias',
            translationPath: 'categorias.ciencias'
        }
    },
    {
        type: 'sch',
        value: 'Aventuras',
        text: {
            default: 'Aventuras',
            translationPath: 'categorias.aventuras'
        }
    },
    {
        type: 'sch',
        value: 'Mitos',
        text: {
            default: 'Mitos',
            translationPath: 'categorias.mitos'
        }
    }
]
