import { useContext } from "react";
import { store } from "../../Store";

/**
 * Hook personalizado para gestionar la visibilidad del dropdown de lenguaje
 * 
 * Este hook proporciona funciones para mostrar, ocultar y verificar la visibilidad del  dropdown
 * 
 * @returns {{show: Function, hide: Function, isVisible: boolean}} Objeto con las siguientes propiedades:
 * - show: Función para mostrar
 * - hide: Función para ocultar
 * - isVisible: Booleano que indica si está visible el dropdown de lenguaje.
 */

export const useLanguageVisibility = () => {
    const globalState = useContext(store);
    const isVisible = globalState?.state?.language?.visibleInbox;

    const show = () => {
        globalState.dispatch({
            type: "CHANGE",
            path: "language.visibleInbox",
            value: true,
        });
    }

    const hide = () => {
        globalState.dispatch({
            type: "CHANGE",
            path: "language.visibleInbox",
            value: false,
        });
    }
    return { show, hide, isVisible }
}