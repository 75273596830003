import React from "react";

import { TitlePage } from "./components/title-page";
import { Introduction } from "./components/introduction";
import { MakeMakeMedia } from "../components/media";
import { Experience } from "./components/experience";
import { Action } from "../components/action";

/**
 * Este componente no recibe props. Este renderiza la pantalla de "¿qué es bibliotecas?". Para esto hace uso del componente TitlePage, Introduction, Training, Discover, Features, MakeMakeMedia, Experience, PodemosAyudar y Img. 
 * @returns {JSXElement} QueEsBibliotecas
 */

const QueEsBibliotecas = () => {

  return (
    <div className="que-es-bibliotecas">
      <div className="container-fluid px-0 fuente-semibold margin-top-header">
        <TitlePage />
        <Introduction />
        <Action/>
        <Experience />
        <MakeMakeMedia background={'primary--bg'} />
      </div>
    </div>
  )
}
export default QueEsBibliotecas;