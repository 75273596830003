import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Img } from "../../../components/shared/img";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

export const DemoRequest = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const demoRequestTexts = textos?.agendar_demo;

	const history = useHistory();
	const [email, setEmail] = useState("");

	const handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		history.push({
			pathname: "/contacto/demo",
			search: `?email=${encodeURIComponent(email)}`,
		});
	};
	return (
		<div className="p-3 py-4 red--bg onred--color">
			<div className="container">
				<div className="d-md-flex justify-content-center">
					<div
						className="col-12 col-md-7 col-lg d-flex d-md-block flex-column justify-content-center align-items-center"
						style={{ maxWidth: 600 }}
					>
						<h2
							className="text-left secondary-font w-100"
							style={{ fontSize: "1.3em" }}
						>
							{demoRequestTexts?.titulo || "Agenda tu demo ahora"}
						</h2>
						<form
							className="m-0 p-0 my-2 row align-items-center"
							onSubmit={(e) => handleSubmit(e)}
						>
							<input
								className="form-control col-12 col-md focusable-secondary"
								onChange={(e) => setEmail(e?.target?.value)}
								placeholder={
									demoRequestTexts?.input?.placeholder || "Correo electrónico"
								}
								type="email"
								required
							/>
							<div className="d-flex d-md-block col m-0 p-0 justify-content-end">
								<button className="btn btn-primary p-2 px-3 mx-0 my-3 m-md-3 focusable-primary">
									{demoRequestTexts?.boton || "Agendar"}
								</button>
							</div>
							<p
								className="py-3 text-left font-weight-light"
								style={{ fontSize: "0.9em" }}
							>
								{textos?.global?.terminos_condiciones_politica1 ||
									"Al ingresar tus datos estás aceptando"}{" "}
								<Link
									className="focusable-secondary onred--color font-weight-bold"
									to="/terminos-y-condiciones"
								>
									{textos?.global?.terminos_condiciones_politica2 ||
										"Términos y condiciones"}
								</Link>{" "}
								{textos?.global?.terminos_condiciones_politica3 || "y"}{" "}
								<Link
									className="focusable-secondary onred--color font-weight-bold"
									to="/politica-de-privacidad"
								>
									{textos?.global?.terminos_condiciones_politica4 ||
										"Política de privacidad"}
								</Link>
							</p>
						</form>
					</div>
					<div className="col d-flex">
						<Img
							className="img-fluid accesible-image"
							src="/img/que-es/home/demo/demo-running.png"
							alt="newsletter-speaker"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
