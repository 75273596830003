import { useContext, useEffect, useState } from "react";
import { store } from "../../components/Store";
import { CancelRequestController } from "../../util/cancel-request-controller";
import { EditorialesAPI } from "../../services/api/Editoriales";

/**
 * Hook que permite obtener las editoriales para la pagina de libros
 * @returns {JSX.Element} usePublishersBooksData
 */
export const usePublishersBooksData = () => {

    const [loading, setLoading] = useState(true);
    const [publishers, setPublishers] = useState([]);
    const stateContext = useContext(store);
    const publishersDataFromState = stateContext?.state?.publishers;
    const loadedInState = publishersDataFromState?.loaded;

    const fetchData = async ({ signal }) => {
        setLoading(true);
        const filtro = ["Editorial Amanuta", "Amanuense", "Babel Libros", "Cidcli", "Ediciones El Naranjo", "Del Naranjo", "Libros del Zorro Rojo", "Bang & Mamut comics", "Zig-Zag", "Mákina Editorial", "Magisterio", "Rey Naranjo Editores", "Vista Higher Learning", "Lee & Low Books",  "Arte Público Press","Iamiqué", "Pequeño editor", "Cataplum libros"];

        await EditorialesAPI.obtenerEditorialesColeccion({ abortSignal: signal, dataFiltro: filtro })
            .then(result => {
                setPublishers(result);
                stateContext.dispatch({
                    type: 'CHANGE',
                    path: 'publishers',
                    value: {
                        data: result,
                        loaded: true,
                        fetching: false
                    }
                })
            })
            .catch(error => console.log(error))
            .finally(() => {
                setLoading(false);
            })
    }

    const handleDataFromState = () => {
        setLoading(false);
        setPublishers(publishersDataFromState?.data);
    }

    useEffect(() => {
        const cancelRequestController = new CancelRequestController();
        handleDataFromState();
        fetchData({ signal: cancelRequestController.signal });

        return () => {
            cancelRequestController.abort();
        }
    }, [loadedInState])

    return {
        loading,
        publishers
    }
}