export const MORE_LINKS = [

  {
		text: {
			default: "Política de soporte",
			translationPath: "links.politica_soporte",
		},
		link: "/politica-de-soporte",
	},
	{
		text: {
			default: "Open Standard for EdTech Integrations",
			translationPath: "links.edtechstandard",
		},
		link: "/edtech-open-standard",
	},
	{
		text: {
			default: "Collection development policy",
			translationPath: "links.collection_development_policy",
		},
		link: "/collection-development-policy",
	},
	{
		text: {
			default: "Políticas de accesibilidad y privacidad de datos",
			translationPath: "links.privacidad",
		},
		link: "/politica-de-privacidad",
	},
	{
		text: {
			default: "Términos y condiciones",
			translationPath: "links.terminos_condiciones",
		},
		link: "/terminos-y-condiciones",
	},
];

export const PRODUCTS_LINKS = [
	{
		text: {
			default: "Bibliotecas públicas",
			translationPath: "links.bibliotecas",
		},
		link: "/que-es/bibliotecas",
	},
	{
		text: {
			default: "Colegios",
			translationPath: "links.colegios",
		},
		link: "/que-es/colegios",
	},
	{
		text: {
			default: "Hogares y familias",
			translationPath: "links.plan_casa",
		},
		link: "/tienda/plan-casa",
	},

];
