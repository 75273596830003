import React from "react";
import { Link } from "react-router-dom";
import { DETAILS_PLANS } from "./data/details-plans";
import { FeaturesBox } from "./components/featuresBox";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Componente que devuelve la tabla de planes
 * @returns {JSX.Element} Plans
 */
export const Plans = ({buttons}) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "planes", lang: lang });
    const libraryData = DETAILS_PLANS?.bibliotecas;
    const schoolsData = DETAILS_PLANS?.colegios;

    return (
        <div className="planes row align-items-stretch mb-3 mb-xl-0">
            <div className="col-4 d-none d-xl-block order-lg-0">
                <div>
                    <div className="plan-titulo"> </div>
                    <ul className="p-3 plan-caracteristicas onbackground-primary--color">
                        <li>{textos?.tabla?.lecturas || 'Lecturas'}</li>
                        <li>{textos?.tabla?.libros || 'Libros'}</li>
                        <li className="bloque-estadistico justify-content-end">
                            {textos?.tabla?.reportes || 'Reportes estadísticos'}
                        </li>
                        <li>{textos?.tabla?.guias || 'Guías de lectura'}</li>
                        <li className="bloque-estadistico justify-content-end">
                            {textos?.tabla?.clubes_tareas || 'Clubes de lectura y asignación de tareas'}
                        </li>
                        <li>{textos?.tabla?.eventos_libros || 'Eventos en vivo y libros del mes'}</li>
                        <li>{textos?.tabla?.eventos_perfil || 'Perfil personalizado'}</li>
                        <li>{textos?.tabla?.autenticacion || 'Autenticación'}</li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-6 col-xl-4 mb-4 order-lg-1">
                <FeaturesBox data={libraryData} />
            </div>
            {buttons &&
                <div className="col-lg-6 col-xl-4 mb-4 mb-lg-0 ml-auto order-lg-3">
                    <Link to={'/contacto'} className="btn-cotizacion library">{textos?.botones?.cotizacion || '¡Pídenos una cotización!'}</Link>
                </div>
            }
            <div className="col-lg-6 col-xl-4 mb-4 order-lg-2">
                <FeaturesBox data={schoolsData} />
            </div>
            {buttons &&
                <div className="col-lg-6 col-xl-4 mb-4 mb-lg-0 order-lg-4">
                    <Link to={'/contacto'} className="btn-cotizacion school">{textos?.botones?.cotizacion || '¡Pídenos una cotización!'}</Link>
                </div>
            }
        </div>
    )
}