  import React from "react";

import { MakeMakeMedia } from "../components/media";
import { TitlePage } from "./components/title-page";
import { Introduction } from "./components/introduction";
import { Experience } from "./components/experience";
import { Action } from "../components/action";

/**
 * Este componente no recibe props. Este renderiza la pantalla de "¿qué es colegios?". Para esto hace uso del componente MakeMakeMedia, TitlePage, Introduction, Training, Discover, Features, Experience, PodemosAyudar y Img. 
 * @returns {JSXElement} QueEsColegios
 */
const QueEsColegios = () => {
  return (
    <div className="que-es-colegios">
      <div className="container-fluid px-0 fuente-semibold margin-top-header">

        <TitlePage />
        <Introduction />
        <Action/>
        <Experience/>
        <MakeMakeMedia background={'primary--bg'} />
        
      </div>
    </div>
  )
}
export default QueEsColegios;