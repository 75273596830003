import React, { useState, useEffect, useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

import { AccesibilidadContext } from "../../../contexts/AccesibilidadContext";

export const AppointmentDemo = ({backButton}) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
    const textos = useTranslations({ section: "cita", lang: lang });
    const accebilidadState = useContext(AccesibilidadContext);

    const [cita, setCita] = useState("");

    const [calendlyRef, setCalendlyRef] = useState(null);
    const [loading, setLoading] = useState(true);

    const [alturaCalendario, setAlturaCalendario] = useState("1000px");
    const [backgroundCalendar, setBackgroundCalendar] = useState("#FFFFFF");
    const [primaryColor, setPrimaryColor] = useState("#82559E");
    const [textColor, setTextColor] = useState("#4d5055");

    useCalendlyEventListener({
        onEventTypeViewed: () => {
            setAlturaCalendario("700px");
        },
        onDateAndTimeSelected: () => {
            setAlturaCalendario("1500px");
        },

        onEventScheduled: (e) => {
            setAlturaCalendario("700px");
        },

        onProfilePageViewed: () => console.log("onProfilePageViewed----------------------"),
    });

    useEffect(() => {
        const isLoadingCalendly = calendlyRef?.state?.isLoading;
        setLoading(isLoadingCalendly);
    }, [calendlyRef?.state?.isLoading]);

    useEffect(() => {
        if (accebilidadState?.temaActual !== "clasico") {
            setBackgroundCalendar("#000000");
            setPrimaryColor("#ffff00");
            setTextColor("#ffff00");
        } else {
            setBackgroundCalendar("#FFFFFF");
            setPrimaryColor("#82559E");
            setTextColor("#4d5055");
        }
    }, [accebilidadState]);

    return (
        <div >



            {cita === "" &&
                <div className="mx-2 mx-xl-0 ">
                    <button 
                    onClick={()=> backButton()}
                        className="d-flex aling-items-center justify-content-center onsecondary--color ml-xl-3 pb-3">
                        <Icon icon="mi:arrow-left" width="25" height="25" className="icon mr-1" />
                        {textos?.cita?.volver || "Volver"}
                    </button>
                    <button
                        className={`d-flex justify-content-center align-items-center btn-cita `}
                        onClick={() => {
                            setCita('es');
                        }}
                    >
                        <div className="icon-cita i-cita-es"> </div>
                        {textos?.cita?.btn_espanol || "Cita con Andrea en español"}
                    </button>
                    <button
                        className={`d-flex justify-content-center align-items-center btn-cita `}
                        onClick={() => {
                            setCita('en');
                        }}
                    >
                        <div className="icon-cita i-cita-en"> </div>
                        {textos?.cita?.btn_ingles || "Cita con Catalina en inglés"}
                    </button>
                </div>
            }

            {(cita === "es" || cita === "en") && (
                <button
                    className={`d-flex justify-content-center align-items-center secondary-font onsecondary--color text-bold mb-4 ml-3`}
                    onClick={() => {
                        setCita('');
                    }}
                >
                    <Icon icon="solar:alt-arrow-left-outline" width="24" height="24" />
                    {textos?.cita?.pasos?.idioma_cita || "Elige un agente y el idioma"}
                </button>
            )}

            {cita === "es" && (

                <div className="col-11 mx-auto content-cita">

                    <div className="title-cita d-flex justify-content-center align-items-center">
                        <div className="icon-cita i-cita-es"> </div>
                        {textos?.cita?.btn_espanol || "Cita con Andrea en español"}
                    </div>

                    <p className="d-none d-md-block secondary-font secondary-inverse--color text-center"> Elige el día y la hora que prefieras para tu cita con Andrea</p>

                    {loading && (
                        <div
                            style={{ minHeight: '300px' }}
                            className=" d-flex  justify-content-center align-items-center flex-column">
                            <p className="mb-3 secondary-inverse--color">{textos?.cita?.cargando || 'Cargando agenda disponible'}</p>
                            <Icon
                                className={"spinner-rotation secondary-inverse--color"}
                                icon="icomoon-free:spinner9"
                                width={60}
                            />

                        </div>
                    )}
                    <InlineWidget
                        url="https://calendly.com/comunicacion-62/30min"
                        styles={{
                            height: loading ? 0 : alturaCalendario,
                            width: '100%'
                        }}
                        ref={(el) => setCalendlyRef(el)}
                        pageSettings={{
                            backgroundColor: backgroundCalendar,
                            hideEventTypeDetails: true,
                            hideLandingPageDetails: true,
                            primaryColor: primaryColor,
                            textColor: textColor,
                        }}
                    />
                </div>
            )}

            {cita === "en" && (

                <div className="col-11 mx-auto content-cita">
                    <div className="title-cita d-flex justify-content-center align-items-center">
                        <div className="icon-cita i-cita-en"> </div>
                        {textos?.cita?.btn_ingles || "Cita con Catalina en inglés"}
                    </div>
                    <p className="d-none d-md-block secondary-font secondary-inverse--color text-center ">Choose the date and time for your appointment with Catalina</p>
                    {loading && (
                        <div
                            style={{ minHeight: '300px' }}
                            className=" d-flex  justify-content-center align-items-center flex-column">
                            <p className="mb-3 secondary-inverse--color">{textos?.cita?.cargando || 'Cargando agenda disponible'}</p>
                            <Icon
                                className={"spinner-rotation secondary-inverse--color"}
                                icon="icomoon-free:spinner9"
                                width={60}
                            />

                        </div>
                    )}

                    <InlineWidget
                        ref={(el) => setCalendlyRef(el)}
                        url="https://calendly.com/libros_makemake/30min"
                        styles={{
                            height: loading ? 0 : alturaCalendario,
                        }}
                        pageSettings={{
                            backgroundColor: backgroundCalendar,
                            hideEventTypeDetails: true,
                            hideLandingPageDetails: true,
                            primaryColor: primaryColor,
                            textColor: textColor,
                        }}
                    />
                </div>
            )}

        </div>
    )
}