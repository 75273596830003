import React from "react";
import { CallToAction } from "../../components/callToAction";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { useTranslations } from "../../hooks/use-translations";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";

/**
 * Componente de call to action para que es principal
 * @returns {JSX.Element} Componente Action.
 */
export const Action = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "que_es", lang: lang });
    return (
        <>
            <CallToAction text={textos?.calltoaction?.descripcion || "¿Quieres conocer cómo MakeMake ayuda a tu institución a leer más y mejor?"} />
        </>
    )
}