import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SkeletonLibrosHome from "../../skeletons/SkeletonHome/SkeletonHomeGeneral/SkeletonLibrosHome";

export const SkeletonBooks = () => {
    return (
        <SkeletonTheme color="#d8d3d3" highlightColor="#e8e8e8">
            <div className={`titlePage`} style={{ backgroundColor: "#6666CC" }}>
                <div className="container">
                    <div className="col-lg-5 col-xl-6">
                        <Skeleton height={65} maxWidth={435} />
                    </div>
                </div>
            </div>

            <section className="container my-5">
                <Skeleton height={100} maxWidth={800} />
                <div className="row px-0 my-5 ">
                    <div className="col-12  d-flex flex-wrap justify-content-center align-items-center focusable-primary">
                        <div className="d-flex flex-column align-items-center">
                            <Skeleton height={35} width={100} count={7} className="mx-4 mb-4 mb-lg-0 px-0" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="highlight-container py-4 home-row-yellow ">
                    <h3 className="pl-3 pl-lg-5 mb-4">
                        <Skeleton height={50} width={300} />
                    </h3>

                    <SkeletonLibrosHome seccion="main-covers" />
                </div>
            </section>

            <section className="d-flex col-md-10 mx-auto  mt-5 mt-lg-0 pb-3 py-lg-5 flex-wrap contenedor-caracteristicas justify-content-around">
                <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0 align-items-center">
                    <Skeleton height={100} width={100} />
                </div>
                <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0 align-items-center">
                    <Skeleton height={100} width={100} />
                </div>
                <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0 align-items-center">
                    <Skeleton height={100} width={100} />
                </div>
                <div className="col-5 col-lg-3 d-flex flex-column mb-4 mb-lg-0 px-0 align-items-center">
                    <Skeleton height={100} width={100} />
                </div>
            </section>

            <div className="row mx-0 justify-content-end home-row-purple py-5">

                <h3 className="col-6 pl-3 pl-lg-5 mb-4">
                    <Skeleton height={50} width={300} />
                </h3>
            </div>

            <section>
                <div className="highlight-container py-4 home-row-yellow ">
                    <h3 className="pl-3 pl-lg-5 mb-4">
                        <Skeleton height={50} width={300} />
                    </h3>

                    <SkeletonLibrosHome seccion="main-covers" />
                </div>
            </section>

            <div className="p-3 py-4 red--bg onred--color">
                <div className="container">
                    <div className="row">
                        <div className="col-8">

                            <div className="col px-0 py-3">
                                <Skeleton height={100} maxWidth={400} />
                            </div>


                            <div className="py-3">
                                <Skeleton height={65} width={100} />
                                <Skeleton height={65} width={100} className="mx-3" />
                            </div>

                        </div>
                        <div className="col-4">
                            <Skeleton height={200} maxWidth={400} />
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme >
    )
}