import React from "react";
import { Link } from "react-router-dom";
import { Img } from "../../../components/shared/img";

/**
 * Componente que renderiza el banner de solicitud de Demo en la entrada.
 * Este aparece cuando no hay una cuenta activa
 * @param {object} textosBlog contiene  los textos de interfaz
 * @returns {JSX.Element} BannerDemo
 */
export const BannerDemo = ({textosBlog}) => {
    return (
        <div className="BannerDemo">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 py-4 d-flex align-items-center">
                        <div>
                            <p className="secondary-font text-18 mb-2" style={{fontWeight: 600}}>{textosBlog?.demo?.text1 || 'Con la Biblioteca Digital MakeMake'} </p>
                            <h2 
                                className="secondary-font text-line-h-20 text-32 mb-4 "
                                dangerouslySetInnerHTML={{
                                    __html: textosBlog.demo.text2 ? textosBlog.demo.text2 : "Fomenta la lectura en tu <br/> colegio y en tu biblioteca",
                                }}
                            ></h2>
                            <Link 
                                to="/contacto/demo"
                                className="btn-blog-demo mt-5 focusable-secondary"
                            >{textosBlog?.demo?.boton || 'Agenda un demo'}</Link>
                        </div>

                    </div>
                    <div className="col-lg-6 d-none d-lg-flex justify-content-end">
                        <div className="img-demo-blog">
                            <Img src="/img/blog/demo.png" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}