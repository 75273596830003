import { LECTURA_DATA } from "./lectura-data";

/**
 * Objeto que contiene los planes ofertados en PLAN CASA - LEER EN MKMK
 */
export const PLANES_DATA = {
	estatico: [
		{
			id: "plan-personalizado",
			personalizado: true,
			className: "red-plan",
			imgUrl: {
				normal: "/img/tienda-makemake/plan-personalizado-thumb-2.png",
				thumb: "/img/tienda-makemake/plan-personalizado-thumb.png",
			},
			sectionUrl: {
				pathname: "/tienda/institucional",
				search: "",
			},
			nombre: {
				default: "plan personalizado",
				translationPath: "individual.planes_mensual.plan_personalizado.nombre",
			},
			descripcion: {
				default: "¿Ninguno de nuestros planes se ajusta a lo que buscas?",
				translationPath: "individual.planes_mensual.plan_personalizado.descripcion",
			},
			descripcion2: {
				default: "Personaliza tu plan de acuerdo a tus necesidades",
				translationPath: "individual.planes_mensual.plan_personalizado.descripcion_2",
			},
			detalles: [
				{
					default: "Cantidad de libros a tu elección",
					translationPath: "individual.planes_mensual.plan_personalizado.detalle_1",
					html: true,
				},
				{
					default: "Tiempo de acceso personalizado",
					translationPath: "individual.planes_mensual.plan_personalizado.detalle_2",
					html: true,
				},
			],
			epayco: {},
		},
	],
	mensual: [],
	trimestral: [
		{
			id: "mi-coleccion-trimestral",
			className: "mi-coleccion-plan",
			imgUrl: {
				normal: "/img/tienda-makemake/mi-coleccion-thumb-2.png",
				thumb: "/img/tienda-makemake/mi-coleccion-thumb.png",
			},
			sectionUrl: {
				pathname: "/tienda/plan-casa/mi-coleccion",
				search: "?tipo=trimestral",
			},
			nombre: {
				default: "Mi colección",
				translationPath: "individual.planes_trimestral.mi_coleccion.nombre",
			},
			precio: {
				usd: "50",
				cop: "199.000",
			},
			descuento: {
				activado: false,
				usd: {
					cantidad: "10%",
					precio: "31.49",
				},
				cop: {
					cantidad: "10%",
					precio: "133.650",
				},
			},
			descripcion: {
				default: "Crea tu colección con tus libros favoritos",
				translationPath: "individual.planes_trimestral.mi_coleccion.descripcion",
			},
			descripcionFicha: {
				default: "Elige los libros que quieras y lee a tu ritmo",
				translationPath: "individual.planes_trimestral.mi_coleccion.descripcion_ficha",
			},
			detalles: [
				{
					default: "30 libros elegidos por ti",
					translationPath: "individual.planes_trimestral.mi_coleccion.detalle_1",
					html: true,
				},
				{
					default: "3 mes de acceso ilimitado",
					translationPath: "individual.planes_trimestral.mi_coleccion.detalle_2",
					html: true,
				},
			],
			/* extraInformation: {
				activado: true,
				titulo: {
					default: "Temporada Julio - Agosto 2024",
					translationPath: "individual.planes_trimestral.mi_coleccion.extra_titulo",
				},
				detalles: [
					{
						default: "Incluye 2 sesiones de Club de lectura para niños entre los 8 y 10 años con taller de co-creación.",
						translationPath: "individual.planes_trimestral.mi_coleccion.extra_incluye",
					}
				]
			}, */
			idealPara: [
				{
					default: "Familias amantes de la lectura",
					translationPath: "individual.planes_mensual.mi_coleccion.ideal_para_1",
				},
				{
					default: "Familias con programas de educación en casa (homeschooling) ",
					translationPath: "individual.planes_mensual.mi_coleccion.ideal_para_2",
				},
				{
					default: "Psicólogos o terapeutas del lenguaje",
					translationPath: "individual.planes_mensual.mi_coleccion.ideal_para_3",
				},
				{
					default: "Docentes de español y lengua castellana",
					translationPath: "individual.planes_mensual.mi_coleccion.ideal_para_4",
				},
				{
					default: "Promotores de lectura independientes",
					translationPath: "individual.planes_mensual.mi_coleccion.ideal_para_5",
				},
				{
					default: "Personas que buscan aprender o están aprendiendo español ",
					translationPath: "individual.planes_mensual.mi_coleccion.ideal_para_6",
				},
			],
			preguntasFrecuentes: [
				{
					pregunta: {
						default: "¿Cómo funciona “Mi colección”?",
						translationPath: "individual.planes_trimestral.mi_coleccion.pregunta_1",
					},
					respuesta: {
						default:
							"El plan “Mi colección” te permite seleccionar 30 libros del catálogo de la Biblioteca Digital MakeMake. Los libros seleccionados no son intercambiables dentro del periodo de suscripción.",
						translationPath: "individual.planes_trimestral.mi_coleccion.respuesta_1",
					},
				},
				{
					pregunta: {
						default: "¿Qué pasa después de que realizo la compra?",
						translationPath: "individual.planes_trimestral.mi_coleccion.pregunta_2",
					},
					respuesta: {
						default:
							"Una vez realizada la compra, el equipo de MakeMake se comunicará contigo para brindarte la información de acceso e instrucciones para la selección de libros.",
						translationPath: "individual.planes_trimestral.mi_coleccion.respuesta_2",
					},
				},
				{
					pregunta: {
						default: "¿Cómo elijo los libros?",
						translationPath: "individual.planes_trimestral.mi_coleccion.pregunta_3",
					},
					respuesta: {
						default:
							"Al momento de ingresar por primera vez a la Biblioteca Digital MakeMake, lo primero que verás es el panel de administración y múltiples pestañas en la parte superior. En la sección Mi colección (segunda pestaña del panel) verás los títulos disponibles para la selección. Una vez hayas finalizado la selección, el equipo de MakeMake habilitará tu cuenta, y a partir de ese momento corren los 30 meses de tu suscripción. ",
						translationPath: "individual.planes_trimestral.mi_coleccion.respuesta_3",
					},
				},
				{
					pregunta: {
						default: "¿Y si quiero leer más después de los 3 meses?",
						translationPath: "individual.planes_trimestral.mi_coleccion.pregunta_4",
					},
					respuesta: {
						default: `
                        Si la suscripción está a punto de finalizar o ya ha finalizado, puedes realizar nuevamente el proceso de compra en la tienda de MakeMake. El equipo de MakeMake activará de nuevo el usuario y la opción de selección de libros para tu nuevo ciclo de suscripción.
                        <br/>
                        Si deseas continuar con los libros de la suscripción anterior notificar al correo contacto@makemake.com.co
                        <br/>
                        Es posible que algunos títulos no estén disponibles nuevamente para selección por motivos de derechos de distribución.

                        `,
						translationPath: "individual.planes_trimestral.mi_coleccion.respuesta_4",
						html: true,
					},
				},
				{
					pregunta: {
						default: "¿Por qué no existe una opción de renovación automática? ",
						translationPath: "individual.planes_trimestral.mi_coleccion.pregunta_5",
					},
					respuesta: {
						default: `¡Porque estamos trabajando en ella! Esperala pronto. Gracias por tu paciencia.`,
						translationPath: "individual.planes_trimestral.mi_coleccion.respuesta_5",
					},
				},
				{
					pregunta: {
						default: "¿Cómo funciona el Club de lectura? ",
						translationPath: "individual.planes_trimestral.mi_coleccion.pregunta_6",
					},
					respuesta: {
						default: `El club contará con 2 de sesiones virtuales los sábados 10 y 24 del mes de agosto de 2024 a las 10.00 a.m. (hora Colombia) y esta diseñado para niños de 8 a 10 años.`,
						translationPath: "individual.planes_trimestral.mi_coleccion.respuesta_6",
					},
				},
			],
			epayco: {
				nombre: "Mi colección",
				descripcion: "Mi colección - Crea tu colección con tus libros favoritos",
				precio: {
					usd: 50,
					cop: 199000,
				},
				descuento: {
					activado: false,
					usd: {
						cantidad: "10%",
						precio: 31.49,
					},
					cop: {
						cantidad: "10%",
						precio: 133650,
					},
				},
			},
		},
	],
	semestral: [
		{
			id: "libro-favorito",
			className: "libro-favorito-plan",
			imgUrl: {
				normal: "/img/tienda-makemake/libro-favorito-thumb-2.png",
				thumb: "/img/tienda-makemake/libro-favorito-thumb.png",
			},
			sectionUrl: {
				pathname: "/tienda/plan-casa/libro-favorito",
				search: "?tipo=semestral",
			},
			nombre: {
				default: "Mi libro favorito",
				translationPath: "individual.planes_semestral.libro_favorito.nombre",
			},
			precio: {
				usd: "8",
				cop: "31.500",
			},
			descuento: {
				activado: false,
				usd: {
					cantidad: "10%",
					precio: "5.39",
				},
				cop: {
					cantidad: "10%",
					precio: "25.200",
				},
			},
			descripcion: {
				default: "Elige tu libro favorito y disfrutalo por 6 meses",
				translationPath: "individual.planes_semestral.libro_favorito.descripcion",
			},
			descripcionFicha: {
				default: "¿Encontraste en MakeMake el libro que buscabas? ¡Cómpralo ya!",
				translationPath: "individual.planes_semestral.libro_favorito.descripcion_ficha",
			},
			detalles: [
				{
					default: "1 libro elegido por ti",
					translationPath: "individual.planes_semestral.libro_favorito.detalle_1",
					html: true,
				},
				{
					default: "6 meses de acceso ilimitado",
					translationPath: "individual.planes_semestral.libro_favorito.detalle_2",
					html: true,
				},
			],
			idealPara: [
				{
					default: "Estudiantes que necesiten cumplir un requisito escolar",
					translationPath: "individual.planes_semestral.libro_favorito.ideal_para_1",
				},
				{
					default:
						"Padres o madres de familia que busquen un libro en específico para su hijo/a",
					translationPath: "individual.planes_semestral.libro_favorito.ideal_para_2",
				},
				{
					default: "Promotores y/o estudiantes que busquen un libro específico",
					translationPath: "individual.planes_semestral.libro_favorito.ideal_para_3",
				},
			],

			preguntasFrecuentes: [
				{
					pregunta: {
						default: "¿Cómo funciona “Mi libro favorito”?",
						translationPath: "individual.planes_semestral.libro_favorito.pregunta_1",
					},
					respuesta: {
						default:
							"El plan “Mi libro favorito” te permite seleccionar 1 libro del catálogo de la Biblioteca Digital MakeMake. Los libros seleccionados no son intercambiables dentro del periodo de suscripción.",
						translationPath: "individual.planes_semestral.libro_favorito.respuesta_1",
					},
				},
				{
					pregunta: {
						default: "¿Qué pasa después de que realizo la compra?",
						translationPath: "individual.planes_semestral.libro_favorito.pregunta_2",
					},
					respuesta: {
						default:
							"Una vez realizada la compra, el equipo MakeMake se comunicará contigo para brindarte la información de acceso e instrucciones para la selección de libros.",
						translationPath: "individual.planes_semestral.libro_favorito.respuesta_2",
					},
				},
				{
					pregunta: {
						default: "¿Cómo elijo el libro?",
						translationPath: "individual.planes_semestral.libro_favorito.pregunta_3",
					},
					respuesta: {
						default:
							"Al momento de ingresar por primera vez a la Biblioteca Digital MakeMake, lo primero que verás es el panel de administración y múltiples pestañas en la parte superior. En la sección Mi colección (segunda pestaña del panel) verás los títulos disponibles para la selección. Una vez hayas finalizado la selección, el equipo de MakeMake habilitará tu cuenta, y a partir de ese momento corren los 6 meses de tu suscripción. ",
						translationPath: "individual.planes_semestral.libro_favorito.respuesta_3",
					},
				},
				{
					pregunta: {
						default: "¿Y si quiero leer más después de los 6 meses?",
						translationPath: "individual.planes_semestral.libro_favorito.pregunta_4",
					},
					respuesta: {
						default: `
                        Si la suscripción está a punto de finalizar o ya ha finalizado, puedes realizar nuevamente el proceso de compra en la tienda. El equipo de MakeMake activará de nuevo el usuario y la opción de selección de libros para tu nuevo ciclo de suscripción.
                        <br/>
                        Si se desea continuar con los libros de la suscripción anterior notificar al correo contacto@makemake.com.co
                        <br/>
                        Es posible que algunos títulos no estén disponibles nuevamente para selección por motivos de derechos de distribución.
                        `,
						translationPath: "individual.planes_semestral.libro_favorito.respuesta_4",
						html: true,
					},
				},
			],
			epayco: {
				nombre: "Libro favorito",
				descripcion:
					"Libro favorito - Elige tu libro favorito y disfrutalo por 6 meses",
				precio: {
					usd: 8,
					cop: 31500,
				},
				descuento: {
					activado: false,
					usd: {
						cantidad: "10%",
						precio: 5.39,
					},
					cop: {
						cantidad: "10%",
						precio: 25200,
					},
				},
			},
		},
		{
			id: "lecturas-makemake",
			className: "lecturas-makemake-plan",
			imgUrl: {
				normal: "/img/tienda-makemake/lectura-makemake-thumb-2.png",
				thumb: "/img/tienda-makemake/lectura-makemake-thumb.png",
			},
			sectionUrl: {
				pathname: "/tienda/plan-casa/lecturas-makemake",
				search: "?tipo=semestral",
			},
			nombre: {
				default: "Plan de lecturas MakeMake",
				translationPath: "individual.planes_semestral.lecturas_makemake.nombre",
			},
			precio: {
				usd: "27.99",
				cop: "126.500",
			},
			descuento: {
				activado: false,
				usd: {
					cantidad: "10%",
					precio: "25.19",
				},
				cop: {
					cantidad: "10%",
					precio: "113.850",
				},
			},
			descripcion: {
				default: "Un plan de lectura temático con guías especializadas",
				translationPath: "individual.planes_semestral.lecturas_makemake.descripcion",
			},
			detalles: [
				{
					default:
						"20 libros especialmente seleccionados para lectores iniciales e intermedios",
					translationPath: "individual.planes_semestral.lecturas_makemake.detalle_1",
					html: true,
				},
				{
					default:
						"20 actividades descargables con preguntas de comprensión crítica y manualidades",
					translationPath: "individual.planes_semestral.lecturas_makemake.detalle_2",
					html: true,
				},
				{
					default: "6 meses de acceso ilimitado para un usuario",
					translationPath: "individual.planes_semestral.lecturas_makemake.detalle_3",
					html: true,
				},
			],
			idealPara: [
				{
					default: "Familias amantes de la lectura ",
					translationPath: "individual.planes_semestral.lecturas_makemake.ideal_para_1",
				},
				{
					default: "Familias con programas de educación en casa (homeschooling)",
					translationPath: "individual.planes_mensual.mi_coleccion.ideal_para_2",
				},
				{
					default: "Psicólogos o terapeutas del lenguaje",
					translationPath: "individual.planes_semestral.lecturas_makemake.ideal_para_3",
				},
				{
					default: "Docentes de español y lengua castellana",
					translationPath: "individual.planes_semestral.lecturas_makemake.ideal_para_4",
				},
				{
					default: "Promotores de lectura independientes",
					translationPath: "individual.planes_semestral.lecturas_makemake.ideal_para_5",
				},
				{
					default: "Personas que buscan aprender o están aprendiendo español ",
					translationPath: "individual.planes_semestral.lecturas_makemake.ideal_para_6",
				},
			],
			preguntasFrecuentes: [
				{
					pregunta: {
						default: "¿Qué es un plan de lectura MakeMake?",
						translationPath: "individual.planes_semestral.lecturas_makemake.pregunta_1",
					},
					respuesta: {
						default:
							"Es una estrategia de educación lectora basada en 10 temas relacionados (cada uno con 2 libros recomendados) y un conjunto de guías detalladas para trabajar la lectura crítica y las conexiones intertextuales. Las guías también incluyen actividades manuales de creación o de redacción para fomentar la expresión así como una webgrafía para promover la sana investigación de recursos de calidad en la web. ",
						translationPath:
							"individual.planes_semestral.lecturas_makemake.respuesta_1",
					},
				},
				{
					pregunta: {
						default: "¿Qué son las actividades descargables?",
						translationPath: "individual.planes_semestral.lecturas_makemake.pregunta_2",
					},
					respuesta: {
						default:
							"Cada uno de los libros pertenecientes al plan cuenta una actividad descargable en formato PDF. Estas actividades incluyen una sección para trabajar la lectura crítica con preguntas para antes, durante y después de la lectura, información contextual y vocabulario. Las guías también incluyen actividades manuales de creación o de redacción relacionadas para fomentar la expresión así como bibliografía y webgrafía para promover la sana investigación de recursos de calidad en la web. ",
						translationPath:
							"individual.planes_semestral.lecturas_makemake.respuesta_2",
					},
				},
				{
					pregunta: {
						default: "¿Qué pasa cuando hago una compra?",
						translationPath: "individual.planes_semestral.lecturas_makemake.pregunta_3",
					},
					respuesta: {
						default:
							"Una vez realizada la compra, el equipo MakeMake se comunicará contigo para brindarte la información de acceso e instrucciones para el acceso a tu plan de lectura.",
						translationPath:
							"individual.planes_semestral.lecturas_makemake.respuesta_3",
					},
				},
			],
			planesLectura: LECTURA_DATA,
			epayco: {
				nombre: "Lecturas MakeMake",
				descripcion: "Lecturas MakeMake",
				precio: {
					usd: 27.99,
					cop: 126500,
				},
				descuento: {
					activado: false,
					usd: {
						cantidad: "10%",
						precio: 25.19,
					},
					cop: {
						cantidad: "10%",
						precio: 113850,
					},
				},
			},
		},
	],
};
