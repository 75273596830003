import React from "react";
import { MORE_PLANS } from "../data/more-plans";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { Img } from "../../../components/shared/img";

export const MorePlans = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const texts = textos?.planes_alternativos;

	const plans = [...MORE_PLANS];
	return (
		<div className="primary--bg onprimary--color py-5">
			<div className="container" style={{ maxWidth: 1400, margin: "auto" }}>
				<h1 className="secondary-font text-center">
					{texts?.titulo || "MakeMakte te ofrece más opciones "}
				</h1>

				<div className="col-md-10 mx-auto mt-3 mt-lg-5 d-sm-flex justify-content-center p-0 m-0 h-100 align-items-stretch">
					<div className="col-md-6 mb-4 mb-sm-0">
						<Link to="/tienda/plan-casa" className="other-plan-card d-block d-lg-flex pl-casa">

							<div className="col-lg-5 d-flex flex-column justify-content-center align-items-center flex-lg-row justify-lg-content-end order-lg-1 relative mb-4 mb-lg-0">
								<div className="">
									<Img src="/img/que-es/home/more-plans/plan-casa.png" />
								</div>

								<h4 className="d-lg-none px-5 px-sm-4 py-1">Plan familiar</h4>
							</div>
							<div className="col-lg-7 d-flex flex-column justify-content-center aling-items-center order-lg-0">
								<h4 className="mb-3 d-none d-lg-block">Plan familiar</h4>
								<p className="text-center text-lg-left">Lee en casa con tus hijos los libros que tú elijas</p>
							</div>
						</Link>
					</div>
					<div className="col-md-6">
						<Link to="/tienda/plan-casa/aprender" className="other-plan-card d-block d-lg-flex pl-academia ">

							<div className="col-lg-5 d-flex flex-column justify-content-center align-items-center flex-lg-row justify-lg-content-end order-lg-1 relative mb-4 mb-lg-0 ">
								<div className="">
									<Img src="/img/que-es/home/more-plans/plan-academia.png" />
								</div>

								<h4 className="d-lg-none px-5 py-1">Academia</h4>
							</div>
							<div className="col-lg-7 d-flex flex-column justify-content-center aling-items-center order-lg-0">
								<h4 className="mb-3  d-none d-lg-block">Academia</h4>
								<p className="text-center text-lg-left">Cursos especializados en lectura para niños y en pantallas</p>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};